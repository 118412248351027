import { getFavorites, setFavorite as _setFavorite, hasFavorite as _hasFavorite} from 'api/Favorites';
import { setFavoriteToCourse } from "modules/Vitrine/Vitrine.ducks.js";
import { setFavoriteToCourse as setFavoriteToCourseDetails } from "modules/CourseDetails/CourseDetails.ducks.js";
import { setFavoriteToCourse as setFavoriteToCourseSearch } from "modules/Search/Search.ducks"; 

// Actions
const FETCH_FAVORITES_START = 'sesi/favorites/FETCH_FAVORITES_START';
const FETCH_FAVORITES_SUCCESS = 'sesi/favorites/FETCH_FAVORITES_SUCCESS';
const FETCH_FAVORITES_FAILURE = 'sesi/favorites/FETCH_FAVORITES_FAILURE';
const FAVORITES_RESET = 'sesi/favorites/FAVORITES_RESET';
const SET_FAVORITE_START = 'sesi/favorites/SET_FAVORITE_START';
const SET_FAVORITE_SUCCESS = 'sesi/favorites/SET_FAVORITE_SUCCESS';
const SET_FAVORITE_FAILURE = 'sesi/favorites/SET_FAVORITE_FAILURE';
const HAS_FAVORITE_START = 'sesi/favorites/HAS_FAVORITE_START';
const HAS_FAVORITE_SUCCESS = 'sesi/favorites/HAS_FAVORITE_SUCCESS';
const HAS_FAVORITE_FAILURE = 'sesi/favorites/HAS_FAVORITE_FAILURE';

// Reducer
const initialState = {
  favorites: [],
  isLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_FAVORITES_START:
      return { ...state, isLoading: true };
    case FETCH_FAVORITES_SUCCESS:
      return { ...state, favorites: action.payload, isLoading: false };
    case FETCH_FAVORITES_FAILURE:
      return { ...state, ...action.payload };
    case SET_FAVORITE_START:
      return { ...state, isLoading: true };
    case SET_FAVORITE_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case SET_FAVORITE_FAILURE:
      return { ...state, ...action.payload };
    case FAVORITES_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// side effects, only as applicable
export const fetchFavorites = () => {
  return async dispatch => {
    dispatch(fetchFavoritesStart());

    try {
      const response = await getFavorites();
      dispatch(fetchFavoritesSuccess(response));
    } catch (error) {
      dispatch(fetchFavoritesFailure(error));
    }
  };
};

export const setFavorite = (sku,email,value) => {
  return async dispatch => {
    dispatch(setFavoriteStart());

    try {
      const response = await _setFavorite(sku,email,value);
      dispatch(setFavoriteToCourse(sku, value));
      dispatch(setFavoriteToCourseDetails(sku, value));
      dispatch(setFavoriteToCourseSearch(sku, value));
      dispatch(setFavoriteSuccess(response));
    } catch (error) {
      dispatch(setFavoriteFailure(error));
    }
  };
};

export const hasFavorite = (sku, email) => {
  return async (dispatch) => {
    dispatch(hasFavoriteStart());
    try {
      const response = await _hasFavorite(sku,email);
      return response;
    } catch (error) {
      dispatch(hasFavoriteFailure(error));
    }
  };
};

// Action Creators
export function fetchFavoritesStart() {
  return { type: FETCH_FAVORITES_START };
}

export function fetchFavoritesSuccess(data) {
  return { type: FETCH_FAVORITES_SUCCESS, payload: data };
}

export function fetchFavoritesFailure(data) {
  return { type: FETCH_FAVORITES_FAILURE, payload: data };
}

export function setFavoriteStart() {
  return { type: SET_FAVORITE_START };
}

export function setFavoriteSuccess(data) {
  return { type: SET_FAVORITE_SUCCESS, payload: data };
}

export function setFavoriteFailure(data) {
  return { type: SET_FAVORITE_FAILURE, payload: data };
}

export function hasFavoriteStart() {
  return { type: HAS_FAVORITE_START };
}

export function hasFavoriteSuccess(data) {
  return { type: HAS_FAVORITE_SUCCESS, payload: data };
}

export function hasFavoriteFailure(data) {
  return { type: HAS_FAVORITE_FAILURE, payload: data };
}

export function reset() {
  return { type: FAVORITES_RESET };
}