import React, { useState, useEffect } from 'react';
import Input from 'components/Input';
import Button from 'components/ButtonPrimary';
import Radio from '@material-ui/core/Radio';
import createStyles from './NewPasswordForm.styles';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: 'rgba(0, 0, 0, 0.65)'
  }
});

const NewPasswordForm = props => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const visibilityIcon = isPasswordVisible ? <Visibility /> : <VisibilityOff />;

  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className,
    loginErrors,
    success,
    serverError
  } = props;

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(success);
  }, [success]);
  

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        placeholder='*******'
        label='Nova senha'
        type={isPasswordVisible ? 'text' : 'password'}
        name='newPassword'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.newPassword}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        helperText="A senha deve conter no mínimo oito caracteres alfanuméricos."
        error={touched.newPassword ? errors.newPassword : null}
        endIcon={{
          onClick: () => setIsPasswordVisible(!isPasswordVisible),
          icon: visibilityIcon,
          className: classes.emailIcon
        }}
      />
      <Input
        placeholder='*******'
        label='Repita a nova senha'
        type={isPasswordVisible ? 'text' : 'password'}
        name='passwordConfirmation'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.passwordConfirmation}
        error={touched.passwordConfirmation ? errors.passwordConfirmation : null}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        helperText="A senha deve ser exatamente igual à senha acima."
        endIcon={{
          onClick: () => setIsPasswordVisible(!isPasswordVisible),
          icon: visibilityIcon,
          className: classes.emailIcon
        }}
      />
      {serverError ? <p style={{ color: "red"}}>{ serverError }</p> : ""} 
     
      <Button lowercase isLoading={isLoading} type='submit' width='100%'>
        Salvar alterações
      </Button>
      <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert style={{ height: '100%', width: 200, flexGrow: 0 }} onClose={handleClose} severity="success">
          Senha registrada com sucesso!
        </Alert>
      </Snackbar>
    </form>
  );
};

export default NewPasswordForm;

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <RadioButtonCheckedRoundedIcon style={{ color: '#32559D' }} />
      }
      icon={<RadioButtonUncheckedRoundedIcon />}
      {...props}
    />
  );
}