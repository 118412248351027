import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';
import { getCategoryMock } from 'mock/Category';
import { getCategoriesMock } from 'mock/Categories';

export const getCategory = async (category) => {
  try {
    const response = await request.get('/categories/' + category);
    return response.data;

    // return new Promise(resolve =>
    //   setTimeout(function() {
    //     const result = getCategoryMock();

    //     resolve(result);
    //   }, 1000)
    // );
  } catch (error) {
    //TODO
  }
};

export const getCategories = async (category) => {
  try {
    // const response = await request.get('/categories/' + category);
    // return response.data;

    return new Promise(resolve =>
      setTimeout(function() {
        const result = getCategoriesMock();

        resolve(result);
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
};



