import Footer from './Footer.jsx';
import { connect } from 'react-redux';
import { registerNewsletter } from 'modules/Registration/Registration.ducks.js';

const mapStateToProps = (state) => {
  return {
    newsletterIsLoading: state.userRegistration.newsletterIsLoading,
    newsletterSuccess: state.userRegistration.newsletterSuccess,
  };
};

const mapDispatchToProps = {
  registerNewsletter
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
