import courseImage from 'assets/images/courseImage.jpg';
import bannerCategory from 'assets/images/mock/bannerCategory.png';

export const getCategoryMock = () => {
  return {
    banner: {
        id: 1,
        image: bannerCategory
      },
    // cities: [
    //   {
    //     name: 'Vitória'
    //   },
    //   {
    //     name: 'Cariacica',
    //   },
    //   {
    //     name: 'Serra',
    //   },
    //   {
    //     name: 'Linhares',
    //   },
    //   {
    //     name: 'Colatina',
    //   },
    //   {
    //     name: 'Cachoeira de Itapemirim',
    //   },
    //   {
    //     name: 'Aracruz',
    //   }
    // ],
    cities: [
      {
        id: 1,
        name: 'Vitória',
        subtitle: 'Unidade',
        link: '',
        products: [
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 25,
            priceWithDiscount: 75,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '/teste',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: 'Aliquam justo sapien.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          }
        ]
      },
      {
        id: 2,
        name: 'Cariacica',
        subtitle: 'Unidade',
        products: [
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 25,
            priceWithDiscount: 75,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '/teste',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: 'Aliquam justo sapien.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          }
        ]
      },
      {
        id: 3,
        name: 'Serra',
        subtitle: 'Unidade',
        products: [
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 25,
            priceWithDiscount: 75,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '/teste',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: 'Aliquam justo sapien.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          }
        ]
      }
    ]
  };
};
