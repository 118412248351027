import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flexGrow: 1
  },
  input: ({ props }) => ({
    padding: props.isOpen ? '.1em 1rem' : '.65rem 0',
    opacity: props.isOpen ? '1' : '0',
    paddingRight: '.1rem',
    transition: 'all 300ms ease-in-out',
    outline: 'none',
  }),
  inputIcon: {
    fontSize: '3rem',
    color: theme.colors.blueCompany
  },
  formSearch: {
    width: '80%',
    margin: 'auto',
    '@media (max-width: 980px)': {
      width: '100%'
    }
  },
  searchButton: ({ props }) => ({
    position: 'absolute',
    top: '0',
    right: '.1rem',
    padding: '0.3rem 0.3rem 0.3rem 0.3rem',
    display: props.isOpen ? 'none' : 'block',
    opacity: props.isOpen ? '0' : '1',
    transition: 'all 300ms ease-in-out'
  }),
  icon: {
    fontSize: '3.5rem',
    color: theme.colors.white
  }
}));

export default createStyles;
