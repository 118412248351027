import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  input: {
    borderColor: theme.colors.background.bg45,
    height: '5rem',
    marginBottom: '0rem'
  },
  form: {
    marginTop: '0rem'
  },
  errorsText: {
    margin: '-1rem 0 2rem 0'
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '@media(max-width:550px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  voucherBtn: {
    height: '40px'
  },
  inputWrapper: {
    flexGrow: 1,
    marginRight: '1rem',
    '@media(max-width:550px)': {
      width: '100%',
      marginBottom: '1rem',
      marginRight: '0rem'
    }
  },
  buttonWrapper: {
    flexBasis: '35%',
    paddingLeft: '1rem',
    boxSizing: 'border-box',
    '@media(max-width:550px)': {
      paddingLeft: '0'
    }
  }
}));

export default createStyles;
