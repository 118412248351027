import React, { useRef } from 'react';
import { Formik } from 'formik';
import AddEditAddressFormPJ from './AddEditAddressFormPJ';
import { addEditAddressFormValidator } from './AddEditAddressFormPJ.validator';

const AddEditAddressFormWrapper = props => {
  const formikRef = useRef();

  const {
    isLoading,
    handleCloseModal,
    handleNext,
    formData,
    isEdit,
  } = props;
  
  const initialValues = {
    name: formData ? formData.name : '', 
    neighbourhood: formData ? formData.neighbourhood : '', 
    cep: formData ? formData.cep : '', 
    address: formData ? formData.street : '', 
    state: formData ? formData.state : '', 
    number: formData ? formData.number : '', 
    city: formData ? formData.city : '', 
    complement:  formData ? formData.complement : '', 
    deliveryAddress:  formData ? formData.deliveryAddress : true,  
    chargeAddress:  formData ? formData.chargeAddress : false,
    cpfCnpj: '',
    buttonTitle: props.buttonTitle
  };

  const handleSubmit = async (formValues, actions) => {
      formValues.isCompany = true
      formValues.name = formValues.firstName + " " + formValues.lastName
      // formValues.cpf = formValues.cnpj
      handleNext({address: formValues});
      handleCloseModal();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={addEditAddressFormValidator}
      innerRef={formikRef}
    >
      {propsFormik => (
        <AddEditAddressFormPJ
          {...propsFormik}
          isLoading={isLoading}
          isEdit={isEdit}
          formikRef={formikRef}
          handleSubmit={handleSubmit}
          user={props.user}
        />
      )}
    </Formik>
  );
};

export default AddEditAddressFormWrapper;
