import ChangeEmailModal from './ChangeEmailModal.jsx';
import { connect } from 'react-redux';
import { handleEmailChange } from 'modules/Registration/Registration.ducks.js';

const mapStateToProps = state => {
  return {
    isEditing: state.userRegistration.isEditing,
    errors: state.userRegistration.errors
  };
};

const mapDispatchToProps = {
  handleEmailChange
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailModal);

