import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PlaceIcon from '@material-ui/icons/Place';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ButtonPrimary from 'components/ButtonPrimary';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import createStyles from './CourseCard.styles';
import RegisterInterest from './RegisterInterest';
import moment from 'moment';

const CourseCard = (props) => {
  const classes = createStyles(props);

  /* Possível workaround para resolver o problema de clicks não detectáveis em componentes filhos do react-draggable (<Draggable>{...}</Draggable>)
   ** Aqui usamos o touch event para detectar ação do usuário e o isDragging para saber se a ação é um click ou arrastar */
  const [isDragging, setIsDragging] = useState(false);

  const [open, setOpen] = React.useState(false);

  const {
    image,
    areaTag,
    title,
    place,
    duration,
    modalidade,
    courseClasses,
    price,
    parcels,
    link,
    discount,
    priceWithDiscount,
    sku,
    user,
    setFavorite,
    isAuthenticated,
    isModal,
    favorite,
    urlNacional,
    startDate,
    qtyClasses,
    descriptionPlan
  } = props;
  function addThousandDot(str) {
    let newStr = str;
    if (str.length > 6) {
      newStr = str.slice(0, -6) + '.' + str.slice(-6);
    }
    return newStr;
  }
  const hasDiscount = discount && discount > 0;
  const formattedPrice = price && addThousandDot(price.toFixed(2).toString().replace('.', ','));
  const formattedPriceWithDiscount = priceWithDiscount && addThousandDot(priceWithDiscount.toFixed(2).toString().replace('.', ','));
  const formattedTitle = title && title.length > 60 ? title.slice(0, 60) + '...' : title;
  


  const handelPriceParcel = () =>{
    if(priceWithDiscount && parcels){
      return addThousandDot((priceWithDiscount / parcels).toFixed(2).toString().replace('.', ','));
    }else if (price && parcels){
      return addThousandDot((price / parcels).toFixed(2).toString().replace('.', ','));
    }
  }
  let priceParcel = handelPriceParcel();
  const handleFavoriteIconClick = (value) => {
    if (isAuthenticated) {
      setFavorite(sku, user.email, value);
    } else {
      props.showLoginBox();
    }
  };

  const openInterest = () => {
    setOpen(true);
  };

  const closeInterest = () => {
    setOpen(false);
  };

  const goTo = (link) => {
    window.open(link, '_self');
  };

  const handleTouchEnd = (callback) => {
    // se usuário esta arrastando, não considera click no botão
    // caso contrário executa a função de click passada pelo button
    if (isDragging) {
      return;
    } else {
      callback();
    }
  };

  const imageComponent = <>
    <div onClick={(e) => e.preventDefault()} className={classes.areaTagContainer}>
      {favorite === true ? (
        <FavoriteIcon onClick={() => handleFavoriteIconClick(false)} className={classes.areaTagIcon} />
      ) : (
        <FavoriteBorderIcon onClick={() => handleFavoriteIconClick(true)} className={classes.areaTagIcon} />
      )}

      <Typography className={classes.areaTagText}>{areaTag}</Typography>
    </div>

    {
      (discount && discount > 0 && (
        <div onClick={(e) => e.preventDefault()} className={classes.tagDiscount}>
          <Typography className={classes.areaTagText}>{`${discount}% OFF!`}</Typography>
        </div>
      )) || ''
    }

    <img alt='Imagem do curso' className={classes.image} src={image} />
    {
      urlNacional &&
      <div className={classes.nacionalContainer}>
        <Typography className={classes.nacionalText}>Oferta Nacional</Typography>
      </div>
    }
  </>


  const getLink = (urlNacional, child) => {
    if (urlNacional) {
      return <a href={urlNacional} target="_blank" className={classes.imageContainer}>
        {child}
      </a>
    } else {
      return <Link to={link} onClick={(event) => { urlNacional && event.preventDefault(); urlNacional && window.open(urlNacional, '_blank'); }} className={classes.imageContainer}>
        {child}
      </Link>
    }
  }


  return (
    <div className={classes.root}>
      {getLink(urlNacional, imageComponent)}
      <div className={classes.infoContainer}>
        {urlNacional ?
          <>
            <a href={urlNacional} target="_blank" className={classes.title}>{formattedTitle}</a>
          </>
          :
          <>
            <Link to={link} onClick={(event) => { urlNacional && event.preventDefault(); urlNacional && window.open(urlNacional, '_blank'); }} className={classes.title}>
              {formattedTitle}
            </Link>
          </>
        }

        <div className={classes.lowerInfo}>
          <ul className={classes.detailsList}>
            <li className={classes.detail}>
              <PlaceIcon fontSize='small' />
              <Typography>{place}</Typography>
            </li>
            <li className={classes.detail}>
              <EditIcon fontSize='small' />
              <Typography>
                {modalidade}
              </Typography>
            </li>
            <li className={classes.detail}>
              <QueryBuilderIcon fontSize='small' />
              <Typography>
                {duration} horas
              </Typography>
            </li>
            <li className={classes.detail}>
              <EventIcon fontSize='small' />
              <Typography>{courseClasses && courseClasses === true ? qtyClasses && qtyClasses > 1 ? "Escolha sua turma" :  `Início em ${moment(startDate).format('DD/MM/yyyy')}` : 'Sem data prevista'}</Typography>
            </li>
          </ul>
          {!isModal &&
            (courseClasses && courseClasses === true ? (
              <React.Fragment>
                <div className={classes.priceContainer}>
                  <div>
                    {formattedPrice === 0 ? (
                      <>
                        <Typography color='textSecondary' className={classes.price}>
                          Gratuito
                        </Typography>
                        <Typography className={classes.price} />
                        <Typography color='textSecondary' className={classes.paymentConditions} variant='body2' />
                      </>
                    ) : (
                      <>
                        <Typography color='textSecondary' className={classes.originalPrice}>
                          {hasDiscount && formattedPrice ? `R$${formattedPrice}` : ''}
                        </Typography>
                        <Typography className={classes.price}>
                          {parcels && priceParcel && `em até ${parcels.toString()}x de R$${priceParcel}`}
                        </Typography>

                        <Typography color='textSecondary' className={classes.paymentConditions} variant='body2'>
                          {hasDiscount && formattedPriceWithDiscount
                            ? `R$${formattedPriceWithDiscount}`
                            : formattedPrice && `R$${formattedPrice}`}
                        </Typography>

                        <Typography color='textSecondary' className={classes.paymentConditions} variant='body2'>
                          {descriptionPlan}
                        </Typography>

                      </>
                    )}
                  </div>
                </div>
                <ButtonPrimary
                  onTouchStart={() => setIsDragging(false)}
                  onTouchMove={() => setIsDragging(true)}
                  onTouchEnd={() => handleTouchEnd(() => (window.location.href = urlNacional ? urlNacional : link))}
                  link={urlNacional ? urlNacional : link}
                  buttonText={!isAuthenticated || (isAuthenticated && user.cpf) ? 'Matricule-se' : 'Comprar'}
                  width='100%'
                  asLink={!urlNacional}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography color='textSecondary' variant='subtitle1' className={classes.noClassesAvailable}>
                  Vagas esgotadas
                </Typography>
                <ButtonPrimary
                  onTouchStart={() => setIsDragging(false)}
                  onTouchMove={() => setIsDragging(true)}
                  onTouchEnd={() => handleTouchEnd(openInterest)}
                  buttonText='Cadastrar interesse'
                  width='100%'
                  variant='outlined'
                  onClick={openInterest}
                />
              </React.Fragment>
            ))}
        </div>
      </div>
      <Modal
        open={open}
        onClose={closeInterest}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        className={classes.modal}
      >
        {<RegisterInterest course={props} closeInterest={closeInterest} />}
      </Modal>
    </div >
  );
};

CourseCard.propTypes = {};

export default CourseCard;
