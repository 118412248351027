import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RegisterInterestForm from './Form/RegisterInterestForm.formik';
import Button from 'components/ButtonPrimary';
import createStyles from './RegisterInterest.styles';
import CourseCard from '../CourseCard';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { toUrlFriendly } from 'shared/util/textTransformation';

const RegisterInterest = (props) => {
  const classes = createStyles();
  const {
    register,
    isLoading,
    handleClose,
    errors,
    afterRegister,
    course,
    place,
    closeInterest,
    success,
    registerinterestIn
  } = props;
  const [interestCompleted, setInterestCompleted] = React.useState(false);
  const FormBox = () => {
    return (
        <CardContent className={classes.cardContent}>
          <Typography
            variant='h3'
            align='center'
            className={classes.title}
          >
            Cadastro de interesse
        </Typography>
          <Typography
            variant='body2'
            align='left'
          >
            Você será informado pelo e-mail cadastrado quando abrir uma nova turma para este curso.
        </Typography>
          <RegisterInterestForm
            isLoading={isLoading}
            success={success}
            errors={errors}
            register={register}
            course={course}
            setInterestCompleted={setInterestCompleted}
            afterRegister={afterRegister}
            closeInterest={closeInterest}
            registerinterestIn={registerinterestIn}
          />
        </CardContent>
    );
  };

  const MessageBox = () => {
    return (
        <CardContent className={classes.cardContent}>
          <CheckCircleIcon className={classes.confirmIcon}/>
          <Typography
            variant='h3'
            align='center'
            className={classes.title}
          >
            Cadastro realizado!
        </Typography>
          <Typography
            className={classes.messageText}
            variant='body1'
            align='left'
          >
            Você será informado pelo e-mail cadastrado quando abrir uma nova turma para este curso.
          </Typography>
          <Button lowercase width='100%' onClick={handleClose}>
            Entendi
        </Button>
        </CardContent>
    );
  };

  return (
    <Card
      onClick={(e) => e.stopPropagation()}
      className={`${classes.rootRegisterBox}`}
    >
      <div className={classes.formBox}>
        <div className={classes.courseInfo}>
          <CourseCard
            sku={course.sku}
            id={course.id}
            image={course.image}
            areaTag={course.areaTag}
            title={course.title}
            place={course.place ? course.place : place }
            duration={course.duration}
            courseClasses={course.hasOpenChairs}
            modalidade={course.modalidade ? course.modalidade : course.presencialDistancia}
            price={course.price}
            parcels={course.parcels}
            link={`/curso/${toUrlFriendly(course.title)}/${course.sku}`}
            favorite={course.favorite}
            discount={course.discount}
            priceWithDiscount={course.priceWithDiscount}
            isModal
          />
        </div>
        {!interestCompleted ? <FormBox /> : <MessageBox />}
      </div>
      
    </Card>
  );
};

RegisterInterest.propTypes = {
  handleClose: PropTypes.func,
  handleLoginClick: PropTypes.func,
  register: PropTypes.func,
  elevation: PropTypes.number
};

export default RegisterInterest;
