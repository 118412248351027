import React, { useEffect } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import createStyles from './MyPaymentHistory.styles';
import MainContainer from 'components/MainContainer';
import CustomTitle from 'components/CustomTitle';
import MyPaymentCard from 'components/MyPaymentCard'
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';

const MyPaymentHistory = (props) => {
  const {
    isLoading,
    myPayments, 
    fetchMyPayments
  } = props;

  const classes = createStyles(props);
  var myPayments1 = [
    {
      coupon_code: "string",
      created_at: "string",
      discount_amount: 0,
      grand_total: 0,
      increment_id: "string",
      state: "string",
      status: "string",
      store_currency_code: "string",
      updated_at: "string",
      items: [
        {
          additional_data: "string",
          created_at: "string",
          description: "string",
          discount_amount: 0,
          name: "string",
          price: 0,
          product_type: "string",
          qty_ordered: 0,
          row_total: 0,
          sku: "string",
          updated_at: "string"
        },
        {
          additional_data: "string",
          created_at: "string",
          description: "string",
          discount_amount: 0,
          name: "Float",
          price: 0,
          product_type: "string",
          qty_ordered: 0,
          row_total: 0,
          sku: "string",
          updated_at: "string"
        }
      ],
      billing_address: {
        address_type: "string",
        city: "string",
        company: "string",
        country_id: "string",
        email: "string",
        fax: "string",
        firstname: "string",
        lastname: "string",
        middlename: "string",
        postcode: "string",
        prefix: "string",
        region: "string",
        region_code: "string",
        street: [
          "string"
        ],
        suffix: "string",
        telephone: "string"
      },
      extension_attributes: {
        payment_additional_info: [
          {
            key: "string",
            value: "string"
          }
        ]
      }
    }, {
      coupon_code: "string",
      created_at: "string",
      discount_amount: 0,
      grand_total: 0,
      increment_id: "string",
      state: "string",
      status: "string",
      store_currency_code: "string",
      updated_at: "string",
      items: [
        {
          additional_data: "string",
          created_at: "string",
          description: "string",
          discount_amount: 0,
          name: "string",
          price: 0,
          product_type: "string",
          qty_ordered: 0,
          row_total: 0,
          sku: "string",
          updated_at: "string"
        },
        {
          additional_data: "string",
          created_at: "string",
          description: "string",
          discount_amount: 0,
          name: "Float",
          price: 0,
          product_type: "string",
          qty_ordered: 0,
          row_total: 0,
          sku: "string",
          updated_at: "string"
        }
      ],
      billing_address: {
        address_type: "string",
        city: "string",
        company: "string",
        country_id: "string",
        email: "string",
        fax: "string",
        firstname: "string",
        lastname: "string",
        middlename: "string",
        postcode: "string",
        prefix: "string",
        region: "string",
        region_code: "string",
        street: [
          "string"
        ],
        suffix: "string",
        telephone: "string"
      },
      extension_attributes: {
        payment_additional_info: [
          {
            key: "string",
            value: "string"
          }
        ]
      }
    }, {
      coupon_code: "string",
      created_at: "string",
      discount_amount: 0,
      grand_total: 0,
      increment_id: "string",
      state: "string",
      status: "string",
      store_currency_code: "string",
      updated_at: "string",
      items: [
        {
          additional_data: "string",
          created_at: "string",
          description: "string",
          discount_amount: 0,
          name: "string",
          price: 0,
          product_type: "string",
          qty_ordered: 0,
          row_total: 0,
          sku: "string",
          updated_at: "string"
        },
        {
          additional_data: "string",
          created_at: "string",
          description: "string",
          discount_amount: 0,
          name: "Float",
          price: 0,
          product_type: "string",
          qty_ordered: 0,
          row_total: 0,
          sku: "string",
          updated_at: "string"
        }
      ],
      billing_address: {
        address_type: "string",
        city: "string",
        company: "string",
        country_id: "string",
        email: "string",
        fax: "string",
        firstname: "string",
        lastname: "string",
        middlename: "string",
        postcode: "string",
        prefix: "string",
        region: "string",
        region_code: "string",
        street: [
          "string"
        ],
        suffix: "string",
        telephone: "string"
      },
      extension_attributes: {
        payment_additional_info: [
          {
            key: "string",
            value: "string"
          }
        ]
      }
    }, {
      coupon_code: "string",
      created_at: "string",
      discount_amount: 0,
      grand_total: 0,
      increment_id: "string",
      state: "string",
      status: "string",
      store_currency_code: "string",
      updated_at: "string",
      items: [
        {
          additional_data: "string",
          created_at: "string",
          description: "string",
          discount_amount: 0,
          name: "string",
          price: 0,
          product_type: "string",
          qty_ordered: 0,
          row_total: 0,
          sku: "string",
          updated_at: "string"
        },
        {
          additional_data: "string",
          created_at: "string",
          description: "string",
          discount_amount: 0,

          name: "Float",
          price: 0,
          product_type: "string",
          qty_ordered: 0,
          row_total: 0,
          sku: "string",
          updated_at: "string"
        }
      ],
      billing_address: {
        address_type: "string",
        city: "string",
        company: "string",
        country_id: "string",
        email: "string",
        fax: "string",
        firstname: "string",
        lastname: "string",
        middlename: "string",
        postcode: "string",
        prefix: "string",
        region: "string",
        region_code: "string",
        street: [
          "string"
        ],
        suffix: "string",
        telephone: "string"
      },
      extension_attributes: {
        payment_additional_info: [
          {
            key: "string",
            value: "string"
          }
        ]
      }
    },
  ];

  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: 'Meus pedidos', to: '' }
  ];

  useEffect(() => {
    fetchMyPayments();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbs} />
      {isLoading ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
        <MainContainer>
          <CustomTitle
            className={classes.title}
            title='Meus Pedidos'
            subtitle='SENAI'
          />
          <div className={classes.container}>
            {myPayments &&
              myPayments.map((payment) => {
                return (
                  <div className={classes.course}>
                    <MyPaymentCard
                      key={payment.incrementId}
                      id={payment.incrementId}
                      couponCode={payment.coupon_code}
                      discount={payment.discount_amount}
                      total={payment.grandTotal}
                      items={payment.items}
                      address={payment.billing_address}
                      date={payment.updatedAt}
                      createdAt={payment.createdAt}
                      paymentMethod={payment.payment.method}
                      status={payment.status}
                      discountAmount={payment.discountAmount}
                    />
                  </div>
                );
              })}
          </div>
        </MainContainer>
      )}
    </React.Fragment>
  );
};

export default MyPaymentHistory;
