import React from 'react';
import createStyles from './ConfirmationPF.styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography } from '@material-ui/core';
import Button from 'components/ButtonPrimary';

const ConfirmationPF = (props) => {
  const classes = createStyles();
  const { billet, getBilletForPrint, user, isLoading } = props;

  const goTo = (link) => {
    window.open(link, '_self')
  }

  const handleSubmit = async (formValues, actions) => {
    try {
      const objectRequest = {
        cpf: user.cpf,
        orderId: billet.orderId
      }
      await getBilletForPrint(objectRequest)
    } catch (err) {
      // if (err instanceof InvalidCredentialsError) {

      // }
    }
  };

  return (
    <>
      <div style={{ width: '75%', margin: 'auto' }}>
        <header className={classes.header}>
          <CheckCircleIcon fontSize='large' />
          <Typography>
            Solicitação de pré-matrícula recebida com sucesso!
          </Typography>
        </header>

        <div className={classes.confirmationContent}>
          <Typography>
            A matrícula só será efetivada após a confirmação do pagamento e
            próximos passos enviados por e-mail.
          </Typography>
        </div>
        {billet ?
          <div className={classes.btn}>
            <Button buttonText="Download Boleto" isLoading={isLoading} onClick={() => handleSubmit()} ></Button>
          </div> : <> </>}


        {/* <Link to="/">Voltar a vitrine</Link> */}
      </div>
    </>
  );
};

export default ConfirmationPF;
