import React, { useEffect, useState, useRef, createRef } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import createStyles from './FAQ.styles';
import MainContainer from 'components/MainContainer';
import { Container, Typography } from '@material-ui/core';
import theme from 'App.theme.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SelectSearch from 'components/SelectSearch';
import LoadingContainer from 'shared/util/LoadingContainer/LoadingContainer';
import login_faq from 'assets/images/login_faq.png';

const getDiscountPolitics = () => {
  return <>
    <p><b>Desconto por forma de pagamento</b></p>
    <p>Pagamento à vista</p>
    <ul>
      <li>Desconto concedido aos alunos que efetuarem o pagamento anual antecipado das mensalidades escolares, com pagamento único em dinheiro ou cartã</li>
    </ul>
    <p><b>Desconto por perfil de público</b></p>
    <ul>
      <li>Trabalhador da Indústria – 10%</li>
      <li>Trabalhador da Indústria Associada – 20%</li>
      <li>Trabalhador da Indústria Arrecadação Direta – 20%</li>
      <li>Trabalhador dos sindicatos patronais filiados a Findes – 20%</li>
      <li>Trabalhador do Sistema Indústria e seus dependentes diretos – 30%</li>
      <li>Egressos do Senai/ES ou da Rede Sesi/ES – 10%</li>
    </ul>
    <p><b>Regras Gerais</b></p>
    <ul>
      <li>Os descontos não são cumulativos;</li>
      <li>Os descontos são aplicáveis aos serviços educacionais do Senai/ES;</li>
      <li>A comprovação do Perfil de Público ocorrerá no ato da matrícula, via portal, por meio
de autodeclaração;</li>
      <li>Considera-se “Indústria” (em todas as categorias) todas as empresas contribuintes do
Sistema Indústria ou Indústrias optantes pelo Simples Nacional;</li>
      <li>Considera-se egresso do Senai/ES, para efeito do desconto, alunos atuais ou formados
nos cursos de Aprendizagem, Qualificação, Técnico e Pós-Técnico;</li>
      <li>Considera-se egresso da Rede Sesi/ES, alunos atuais ou formados do Ensino Médio;</li>
      <li>A manutenção do desconto é condicionada ao pagamento da parcela mensal até a
data de seu vencimento.</li>
    </ul>
  </>
}
//adicionar campo order
const FAQcontentMock = [
  {
    topic: 'Geral',
    questions: [
      {
        question: '1.   Esqueci minha senha, e agora?',
        answer: 'Clique em “Esqueceu a senha?” e siga as instruções da tela:',
        image: login_faq
      },
      {
        question: '2.   Não consigo fazer a compra do curso no site. Como faço para corrigir meu cadastro?',
        answer: 'Entre em contato com nosso Serviço de Atendimento ao Cliente através do telefone 0800 102 0880, Whatsapp, Chat ou Fale Conosco. O atendimento telefônico é de segunda a sexta-feira, das 7 às 19 horas, exceto feriados.',
      },
      {
        question: '3.   O que devo fazer quando tiver dúvidas sobre os cursos, reclamações ou sugestões?',
        answer: 'Entre em contato com nosso Serviço de Atendimento ao Cliente através do telefone 0800 102 0880, Whatsapp, Chat ou Fale Conosco. O atendimento telefônico é de segunda a sexta-feira, das 7 às 19 horas, exceto feriados.',
      },
      {
        question: '4.   Preciso finalizar minha inscrição/matrícula em uma unidade. Como proceder?',
        answer: 'Entre em contato com a unidade de interesse por telefone.Se não conseguir contato, ligue para o nosso Serviço de Atendimento ao Cliente através do telefone 0800 102 0880, Whatsapp, Chat ou Fale Conosco. O atendimento telefônico é de segunda a sexta-feira, das 7 às 19 horas, exceto feriados.',
      },
      {
        question: '5.   Após concluir minha inscrição e pagamento por meio do site, qual procedimento devo seguir?',
        answer: 'Após a identificação do pagamento, você receberá por e-mail um link de acesso ao Portal do Aluno. Lembramos que a confirmação do pagamento levará até 24 horas para pagamentos com cartão de crédito e até 5 dias úteis para pagamentos com boleto.',
      },
      {
        question: '6.   Quais são as formas de pagamento?',
        answer: 'O pagamento pode ser feito através de cartão de crédito ou boleto bancário.',
      },
      {
        question: '7.   Cartão recusado na compra, o que devo fazer?',
        answer: 'Há  muitos  motivos  que  podem  causar  a  recusa  de  seu  cartão  de  crédito.  Os principais são: - Dados do cartão digitados incorretamente. - Cartão de crédito expirado.  -  Limite  de  crédito  disponível  inferior  ao  valor  do  curso  que  está tentando comprar. Em toda compra, é fundamental que você possua disponível no limite o valor integral do curso para que a compra seja aprovada. - A bandeira de seu cartão de crédito não é aceita por nosso sistema. Se o seu cartão não se enquadrar  em  nenhum  dos  motivos  listados  anteriormente  e  mesmo  assim continuar  sendo  recusado,  entre  em  contato  com  a  operadora  do  cartão  de crédito  para  saber  o  motivo  pelo  qual  a  compra  não  está  sendo  aprovada  ou utilize um novo cartão para realizar o pagamento.',
      },
      {
        question: '8.   Realizei uma compra, mas decidi cancelá-la como faço?',
        answer: 'A  solicitação  de  cancelamento  deve  ser  feita  través  do  SAC  ou  da  Unidade Operacional responsável pelo curso.Entre  em  contato  com  nosso  Serviço  de  Atendimento  ao  Cliente  através  do telefone  0800  102  0880,  Whatsapp,  Chat  ou  Fale  Conosco.  O  atendimento telefônico é de segunda a sexta-feira, das 7 às 19 horas, exceto feriados.',
      },
      {
        question: '9. Dúvidas sobre outros assuntos?',
        answer: 'Entre  em  contato  com  nosso  Serviço  de  Atendimento  ao  Cliente  através  do telefone  0800  102  0880,  Whatsapp,  Chat  ou  Fale  Conosco.  O  atendimento telefônico é de segunda a sexta-feira, das 7 às 19 horas, exceto feriados.',
      },
      {
        question: '10. Quais são as políticas de desconto?',
        html: getDiscountPolitics()
      }
    ]
  },
]

const StyledMenuItem = withStyles(theme => ({
  root: {
    whiteSpace: 'unset',
    borderRight: '1px solid rgba(0, 0, 0, 0.08)',
    '&:focus': {
      borderBottom: '2px solid #32559D',
      borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
      borderTop: '1px solid rgba(0, 0, 0, 0.08)',
      borderRight: 'none'
    }
  },
  'selected': {
    borderBottom: '2px solid #32559D',
    borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    borderRight: 'none',
    backgroundColor: 'white !important'
  }
}))(MenuItem);

const FAQ = props => {

  const { faq: FAQcontent, isLoading, fetchQuestions } = props;

  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedAnswer, setExpandedAnswer] = useState('');
  const selectedTopic = FAQcontent[selectedTopicIndex];
  const elementsRef = useRef((selectedTopic && !isLoading) ? selectedTopic.questions.map(() => createRef()) : null);
  const [showButton, setShowButton] = useState([])


  useEffect(() => {
    const updateWidth = () => {
      if (elementsRef.current) {
        setShowButton(elementsRef.current.map(item => item.current?.offsetHeight && item.current?.offsetHeight > 40))
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    elementsRef.current = elementsRef.current?.slice(0, selectedTopic?.questions.length);
  }, [selectedTopic?.questions]);

  useEffect(() => {
    fetchQuestions()
  }, []);

  const classes = createStyles(isMobile);
  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: 'Perguntas frequentes', to: '' }
  ];

  const handleTopic = (index) => {
    setSelectedTopicIndex(index);
  }

  function expandAnswer(index) {
    index !== expandedAnswer
      ? setExpandedAnswer(index)
      : setExpandedAnswer('')
  }


  return (
    <>
      <Breadcrumbs items={breadcrumbs} style={{ padding: '0px 135px' }} />
      
      <MainContainer>
        <LoadingContainer loading={isLoading}>
          {isMobile &&
          <div className={classes.responsiveMenu}>
            <SelectSearch
              className={classes.selectSearch}
              options={FAQcontent && FAQcontent.map((topic, index) => {
                return {
                  label: topic.topic,
                  value: index,
                };
              })}
              onSelectChange={(value) => setSelectedTopicIndex(value)}
              placeholder={'Selecione o tópico'}
              value={{
                label: FAQcontent[selectedTopicIndex].topic,
                value: selectedTopicIndex
              }}
            />
          </div>
          }
          <Container className={classes.container} >
          {!isMobile &&
            <Paper className={classes.menu}>
              <MenuList className={classes.menuList}>
                {FAQcontent.map((topic, index) => {
                  return (
                    <StyledMenuItem value={index} selected={selectedTopicIndex == index} onClick={() => handleTopic(index)}>{topic.topic}</StyledMenuItem>
                  )
                })}
              </MenuList>
            </Paper>
          }
          <section className={classes.selectedMenu}>
            <Typography variant="h3" className={classes.topic}>{selectedTopic?.topic}</Typography>
            {selectedTopic?.questions.sort((a, b) => a.order - b.order).map((item, index) => {
              return (
                <React.Fragment>

                  <Typography variant="subtitle1" className={classes.question}>{item.question}</Typography>
                  <div className={classes.answerContainer}>
                    <div className={`${classes.answer} ${expandedAnswer === index && classes.expandedAnswer}`}>
                      {item.answer && <Typography ref={elementsRef.current ? elementsRef.current[index] : null} variant="body2" color="textPrimary" >{item.answer}</Typography>}
                    </div>
                    {showButton[index] || item.image &&
                      <>
                        <button
                          className={classes.viewMore}
                          onClick={() => expandAnswer(index)}
                        >
                          {expandedAnswer === index ? 'Ocultar -' : 'Ler mais +'}
                        </button>
                        {item.image && expandedAnswer === index && <img src={`${process.env.REACT_APP_API_URL || 'https://senai-gatewayd.exablack.com/api/'}faqs/image/${item.image}`} height='100%' alt=''/>}
                      </>
                    }
                    {item.link && <a href={item.link}>{item.link}</a>}
                    {item.html && <div ref={elementsRef.current ? elementsRef.current[index] : null} className={classes.htmlText} dangerouslySetInnerHTML={{ __html: item.html}} />}
                  </div>
                </React.Fragment>

              )
            })}
          </section>
          </Container>
        </LoadingContainer>
      </MainContainer>
    </>
  );
};

export default FAQ;
