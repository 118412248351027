import FAQ from './FAQ.view';
import { connect } from 'react-redux';
import { fetchQuestions } from 'modules/FAQ/FAQ.ducks.js';

const mapStateToProps = state => {
  return {
    faq: state.faq.faq,
    isLoading: state.faq.isLoading
  };
};

const mapDispatchToProps = {
  fetchQuestions
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
