import * as Yup from 'yup';

const registerInterestForm = {
  email: Yup.string().required('Este campo é obrigatório').email('Email inválido'),
  phone: Yup.string().test('phone1', 'Este campo é obrigatório', function(value) {
    return (value !== undefined ? value.replace(/_|-|\(|\)|\s/g, '') : '').length >= 10;
  }),
};

export const registerFormValidator = Yup.object().shape(registerInterestForm);
