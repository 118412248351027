import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';
import { getAddressMock } from 'mock/Address';

export const getAdresses = async () => {
  try {
    // const response = await request.get('/products/showcase');
    // return response.data;

    return new Promise(resolve =>
      setTimeout(function() {
        const result = getAddressMock();
        resolve(result);
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
};

export const saveAddress = async ({name, street, complement, neighbourhood, city, uf, cep}) => {
  try {
    // const response = await request.post('/products/showcase');
    // return response.data;
    return new Promise(resolve =>
      setTimeout(function() {

        resolve('success');
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
}

export const deleteAddress = async (id) => {
  try {
    // const response = await request.delete('/products/showcase');
    // return response.data;
    return new Promise(resolve =>
      setTimeout(function() {

        resolve('success');
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
}