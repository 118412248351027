import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import createStyles from './AddressCard.styles';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import DeleteModal from 'components/DeleteModal';
import AddEditAddressModal from 'modules/Adresses/AddEditAddressModal';
import ButtonPrimary from 'components/ButtonPrimary';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const StyledButton = withStyles(theme => ({
  root: {
    fontSize: '18px',
    lineHeight: '21px'
  }
}))(Button);


const PaymentMethodCard = props => {
  const mediaQuery = {
    isPhone: useMediaQuery('(max-width:450px)'),
    isMobile: useMediaQuery('(max-width:814px)')
  }
  const classes = createStyles(mediaQuery);

  const [state, setState] = React.useState({ paymentSelected: false });
  const [isDeleting, setOpenDeleteModal] = React.useState(false);
  const [isAdding, setOpenAddingModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);


  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  }

  const handleEdit = () => {
    setOpenAddingModal(true);
    setIsEdit(true);
  }


  const handleAddModal = () => {
    setOpenAddingModal(false);
  }

  return (
    <Card className={classes.root}>
      {props.data ?
        (
          <CardContent className={classes.container} key={props.data.number}>
            <div className={classes.cardContainer}>
              <div className={classes.titleContainer} >
                <Typography variant="subtitle1" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>{props.data.name}</Typography>
                <Typography variant="body1">Endereço de entrega</Typography>
              </div>

              <div className={classes.buttonContainer}>
                <ButtonPrimary
                  className={classes.button}
                  variant='outlined'
                  lowercase={true}
                  buttonText="Alterar"
                  onClick={handleEdit}
                />
                <DeleteForeverIcon style={{ cursor: 'pointer' }} onClick={setOpenDeleteModal} />
              </div>
            </div>

            <div className={classes.cardInfoContainer}>
              <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>{props.data.street}, {props.data.number}</Typography>
              <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>{props.data.complement} </Typography>
              <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>{props.data.neighbourhood} </Typography>
              <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>{props.data.city} - {props.data.uf}</Typography>
              <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>CEP: {props.data.cep} </Typography>
            </div>
            <DeleteModal open={isDeleting} handleClose={handleClose} title="endereço" data={props.data} handleDeleteMethod={props.delete} />
            <AddEditAddressModal
              open={isAdding}
              isEdit={isEdit}
              handleClose={handleAddModal}
              data={props.data}
            />
          </CardContent>
        ) : (
          <React.Fragment>
            <CardContent className={classes.newContainer} onClick={setOpenAddingModal}>
              <AddOutlinedIcon fontSize="large" className={classes.addIcon} />
              <Typography variant="subtitle1" className={classes.title} >Adicionar endereço</Typography>
            </CardContent>
            <AddEditAddressModal
              open={isAdding}
              isEdit={isEdit}
              handleClose={handleAddModal}
            />
          </React.Fragment>
        )
      }

    </Card>
  );
};

PaymentMethodCard.propTypes = {};

export default PaymentMethodCard;
