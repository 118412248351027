import OrderSummary from './OrderSummary';
import { applyVoucher } from 'modules/Cart/Cart.ducks.js';
import { removeItemFromCart } from 'modules/Cart/Cart.ducks.js';
import { removeVoucher, getTotalsInCart, deleteVoucher } from 'modules/Cart/Cart.ducks.js';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.items,
    cartVouchers: state.cart.cartVouchers,
    isLoading: state.cart.isLoading,
    isLoadingTotals: state.cart.isLoadingTotals,
    isLoadingVoucher: state.cart.isLoadingVoucher,
    totals: state.cart.totals
  };
};

const mapDispatchToProps = { applyVoucher, removeItemFromCart, removeVoucher, getTotalsInCart, deleteVoucher };

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
