import React from 'react';
import createStyles from './MainContainer.styles';

const MainContainer = (props) => {
  const classes = createStyles(props);
  const { className } = props;

  return (
    <div className={`${classes.rootMainContainer} ${className}`}>
      <div className={classes.container}>{props.children}</div>
    </div>
  );
};

MainContainer.propTypes = {};

export default MainContainer;
