import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  title: {
    color: theme.colors.text.dark,
    margin: '0 0 30px 0',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'center',
    margin: '0',
    width: '100%',
  },
  cardContainer: isMobile => ({
    display: 'flex', 
    flexDirection: 'row ',
    flexFlow: 'wrap', 
    justifyContent: 'flex-start', 
    alignItems: 'flex-start', 
    justifyContent: isMobile ? 'center' : 'flex-start', 
    alignSelf: 'center', 
    margin: '0', 
    width: '100%'
  }),
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

export default createStyles;
