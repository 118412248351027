import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  warning: {
      color: '#855104', 
      fontWeight: 'bold', 
      fontSize: '14px',
      background: 'rgba(0, 0, 0, 0.03)', 
      padding:'8px 20px'
  },
  btn: {
      display: 'flex', 
      justifyContent: 'flex-end', 
      alignItems: 'center', 
      margin: '24px 0', 
  }, 
  cardContent: {
    padding: '16px'
  },
  table: {
    boxShadow: 'none', 
    margin: '23px 0'
  },
  tableLine: {
    border: 'none', 
    color: 'rgba(0, 0, 0, 0.8)'
  },
  details: {
      display: 'flex', 
      flexFlow: 'row wrap', 
      justifyContent: 'space-between', 
      margin: '10px 0'
  }, 
  detailTitle: {
      color: 'rgba(0, 0, 0, 0.8)', 
      fontWeight: 'bold'
  }
}));

export default createStyles;
