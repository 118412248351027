
export const getPaymentMethodMock = () => {
  return {
    paymentMethods: [
      {
        id: 1206739,
        flag: 'Mastercard', 
        number: 8415, 
        fullName: 'João da Silva', 
        month: '12', 
        year: '2027',
        formatedDate: '31/12/2027',
        defaultPayment: true,
      },
      {
        id: 1206740,
        flag: 'Visa', 
        number: 3278, 
        fullName: 'João da Silva', 
        month: '12', 
        year: '2027',
        formatedDate: '31/03/2028',
        defaultPayment: false,
      },
      
    ]
  };
};
