import React, { useState, useEffect } from 'react';
import Input from 'components/Input';
import Button from 'components/ButtonPrimary';
import { FormHelperText } from '@material-ui/core';

import createStyles from './ForgotPasswordForm.styles';

const ForgotPasswordForm = props => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isLoading,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;


  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        placeholder='Ex: joao.silva@gmail.com'
        label='Email'
        type='text'
        name='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={touched.email ? errors.email : null}
      />
      <Button lowercase isLoading={isLoading} type='submit' width='100%' style={{marginTop: '24px'}}>
        Enviar
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
