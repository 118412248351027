import Help from './Help.view';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    // category: state.category
  };
};

const mapDispatchToProps = {
  // fetchCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);