import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  rootRegisterBox: {
    width: '50%',
    borderRadius: 0,
    '@media (max-width: 400px)': {
      width: '100%'
    },
    boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.25)'
  },
  loginHook: {
    color: theme.colors.blueLink
  },
  registerCall: {
    marginTop: '2rem'
  },
  cardContent: {
    padding: '1.5rem 3rem',
    // width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  title: {
    marginTop: '1.5rem'
  },
  messageText: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem'
  },
  label: {
    color: (props) =>
      props.error ? theme.colors.alerts.error : theme.colors.text.primary,
    marginBottom: '0.5rem',
    fontSize: '1.4rem',
    width: '100%'
  },
}));

export default createStyles;
