import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/Check';
import Input from '../Input/Input';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SenaiLogoBranco from 'assets/icons/SenaiLogoBranco.svg';
import FindesLogoBranco from 'assets/icons/FindesLogoBranco.svg';
import BandeiraVisa from 'assets/icons/bandeira-visa-48px.png';
import BandeiraMastercard from 'assets/icons/bandeira-mastercard-48px.png';
import BandeiraElo from 'assets/icons/bandeira-elo-48px.png';
import MainContainer from 'components/MainContainer';
import { Link } from 'react-router-dom';

import createStyles from './Footer.styles';

const Footer = (props) => {
  const classes = createStyles();

  const {
    registerNewsletter,
    newsletterIsLoading,
    newsletterSuccess,
  } = props;

  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterInit, setNewsletterInit] = useState(true);

  const handleChange = (e) => {
    setNewsletterEmail(e.target.value);
  };

  const handleRegistrationNewsletterClick = () => {
    if (newsletterEmail && newsletterInit) {
      registerNewsletter(newsletterEmail)
        .then(() => {
          setNewsletterInit(false);
        });
    }
  };

  return (
    <div className={classes.root}>
      <MainContainer>
        <div className={classes.mainFooter}>
          <Grid container direction='row' spacing={5}>
            <Grid classes={{root: classes.logoContainer}} item lg={3} md={12} sm={12} xs={12}>
              <img
                alt='Logo da Findes'
                src={FindesLogoBranco}
                className={classes.sesiLogo}
              />
              <img
                alt='Logo do Senai'
                src={SenaiLogoBranco}
                className={classes.sesiLogo}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={12} xs={12}>
              <div className={classes.footerColumn}>
                <Typography gutterBottom className={classes.title}>
                  Redes Sociais
                </Typography>
                <ul className={classes.list}>
                <li>
                    <FacebookIcon />
                    <a href="https://www.facebook.com/SenaiES/" target="_blank" >/SenaiES</a>{' '}
                  </li>
                  <li>
                    <InstagramIcon />
                    <a href="https://www.instagram.com/senaies/" target="_blank" >@senaies</a>
                  </li>
                  <li>
                    <YouTubeIcon />
                    <a href="https://www.youtube.com/c/SENAIES_Oficial" target="_blank" >SENAIES_Oficial</a>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div className={classes.footerColumn}>
                <Typography gutterBottom className={classes.title}>
                  SAC - Serviço de Atendimento ao Cliente
                </Typography>
                <ul className={classes.list}>
                  <li>
                    <AlternateEmailIcon />
                    <a href="https://senaies.com.br/fale-conosco/" target="_blank" >Fale Conosco</a>
                  </li>
                  <li>
                    <ContactSupportIcon />
                    <Link to="/perguntas-frequentes">Perguntas Frequentes</Link>
                  </li>
                  <li>
                    <PhoneEnabledIcon />
                    <span>Telefone: 0800 102 0880</span>
                  </li>
                  <li>
                    <QueryBuilderIcon />
                    <span>
                      Horário de funcionamento: Segunda a Sexta-feira, das 7h às
                      19h. Exceto feriados nacionais e locais.
                    </span>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <div className={classes.footerColumn}>
                <Typography gutterBottom className={classes.title}>
                  Newsletter
                </Typography>
                <Input
                  id="input_newsletter"
                  placeholder='Email'
                  value={newsletterEmail}
                  onChange={handleChange}
                  readOnly={!newsletterInit && newsletterSuccess}
                  endIcon={ newsletterInit || (!newsletterInit && !newsletterSuccess) ? {
                    icon: <EmailIcon />,
                    className: classes.emailIcon
                  } : {
                    icon: <CheckIcon />,
                    className: classes.emailIcon
                  }}
                  endIconProps={ newsletterInit || (!newsletterInit && !newsletterSuccess) ? {
                    onClick: handleRegistrationNewsletterClick
                  } : {
                    disabled: true
                  }}
                />

                <Typography gutterBottom className={classes.newsletterStatus}>
                    { !newsletterInit && newsletterSuccess &&
                        'Você foi inscrito na Newsletter!'
                    }
                    { !newsletterInit && !newsletterSuccess &&
                        'Erro ao inscrever-se na Newsletter'
                    }
                    {
                      newsletterInit &&
                        <span>&nbsp;&nbsp;</span>
                    }
                </Typography>                

                <Typography gutterBottom className={classes.title + ' ' + classes.bandeirasTitle}>
                  Aceitamos as seguintes bandeiras:
                </Typography>
                <img
                  alt='Visa'
                  src={BandeiraVisa}
                  className={classes.bandeirasIcon}
                />
                <img
                  alt='Mastercard'
                  src={BandeiraMastercard}
                  className={classes.bandeirasIcon}
                />
                <img
                  alt='Elo'
                  src={BandeiraElo}
                  className={classes.bandeirasIcon}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </MainContainer>

      <div className={classes.copyRightContainer}>
        <Grid container spacing={4} justifyContent='space-between'>
          {/* <Grid
            item
            lg={3}
            md={4}
            sm={12}
            xs={12}
            className={classes.firstItem}
          >
            Núcleo Digital - Unidade de Comunicação Integrada{' '}
          </Grid> */}
          <Grid
            item
            lg={6}
            md={8}
            sm={12}
            xs={12}
            className={classes.middleItem}
          >
            Copyright {new Date().getFullYear()} - SENAI ES - Uma realização da Indústria{' '}
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12} className={classes.lastItem}>
            Sistema Findes
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
