import React from 'react';
import createStyles from './SuccessModal.styles';
import Modal from '@material-ui/core/Modal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom';


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const SuccessModal = props => {
  const classes = createStyles(props);
  const [ modalStyle ] = React.useState(getModalStyle);
  
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ClearIcon className={classes.clearIcon} onClick={props.handleClose}/>
      <div className={classes.titleContainer}>
        <CheckCircleIcon fontSize="large" style={{ color: '#32559D' }} className={classes.deleteIcon}/>
        <h3 className={classes.title}>{props.title}</h3>
      </div>

      <Typography variant="body1" className={classes.text}>
        Em breve você receberá o email, caso não encontre verifique na caixa de span ou lixeira.
      </Typography>

      {!props.isPassword && 
        <Typography variant="body1" className={classes.text}>
          Ao receber, confirme o seu novo e-mail pelo link para que a alteração seja afetuada.
        </Typography>
      }
      <Button onClick={props.handleSubmit} onClick={props.handleClose} className={classes.button}>{props.buttonTitle}</Button>

      {!props.isPassword && 
        <Link className={classes.forgotPasswordLabel} style={{marginTop: '2px'}}>
          Não recebeu? Reenviar email.
        </Link>
      }
      
    </div>
  );


  return (
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
  );
};


export default SuccessModal;
