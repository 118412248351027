import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import EditProfileForm from './EditProfileForm';
import { EditProfileFormValidator } from './EditProfileForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';

const EditProfileFormWrapper = (props) => {
  const {
    login,
    history,
    redirectTo,
    isLoading,
    handleClose,
    externalErrors,
    updateUser,
    user
  } = props;

  const initialValues = {
    fullName: user.name,
    email: user.email,
    cpf: user.cpf,
    birthDate: user.birthDate,
    gender: user.gender,
    phone: user.phone
  };

  const handleSubmit = async (formValues, actions) => {
    try {
      alert('//TODO');
      updateUser();
      //TODO
    } catch (err) {
      //TODO
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={EditProfileFormValidator}
    >
      {(propsFormik) => (
        <EditProfileForm
          isLoading={isLoading}
          externalErrors={externalErrors}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default EditProfileFormWrapper;
