import React, { useEffect } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import MainContainer from 'components/MainContainer';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PaymentIcon from '@material-ui/icons/Payment';
import CheckIcon from '@material-ui/icons/Check';
import CartHeader from './CartHeader';
import PropTypes from 'prop-types';
import CartBody from './CartBody/CartBody.connect';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import createStyles from './Cart.styles';

const Cart = (props) => {
  const { isLoading, isAuthenticated, history } = props;
  const classes = createStyles(props);
  const breadcrumbs = [
    { label: 'Home', to: '/' },
    { label: 'Carrinho' }
  ];

  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { step } = useParams();

  useEffect(() => {
    setSteps([
      { label: 'Carrinho', icon: ShoppingCartIcon, hidden: false },
      { label: 'Identificação', icon: PermIdentityIcon, hidden: isAuthenticated},
      { label: 'Pagamento', icon: PaymentIcon, hidden: false },
      { label: 'Confirmação', icon: CheckIcon, hidden: false }
    ]);
  }, [activeStep, isAuthenticated]);
  useEffect(() => {
    switch (step) {
      case 'itens':
        setActiveStep(0);
        break;
      case 'auth':
        setActiveStep(1);
        break;
      case 'pagamento':
        setActiveStep(2);
        break;
      case 'confirmacao':
        setActiveStep(3);
        break;
      default:
        setActiveStep(0);
        break;
    }
  }, [step]);

  const handleNext = (props) => {

    let nextActiveStep = null;
    let redirectUrl = '';

    switch (activeStep) {
      case 0:
      case 1:
        nextActiveStep = 2;
        redirectUrl = '/carrinho/pagamento';
        break;
      case 2:
        nextActiveStep = 3;
        redirectUrl = '/carrinho/confirmacao';
        break;
      default:
        nextActiveStep = 0;
        redirectUrl = '/carrinho/itens';
        break;
    }

    if (!isAuthenticated) {
      nextActiveStep = 1;
      redirectUrl = '/carrinho/auth';
    }

    setActiveStep(nextActiveStep);
    if(props){
      history.push({
        pathname: redirectUrl,
        state: {data: props}
      })
    } else {
      history.push(redirectUrl);
    }
  };

  return (
    <MainContainer>
      <Breadcrumbs items={breadcrumbs} />
      <CartHeader activeStep={activeStep} steps={steps} />
      <CartBody
        handleNext={handleNext}
        activeStep={activeStep}
        history={history}
      />
    </MainContainer>
  );
};

Cart.propTypes = {
  isLoading: PropTypes.bool
};

export default Cart;
