import React, { useState, useEffect } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Typography, Backdrop } from '@material-ui/core';
import PropTypes from 'prop-types';
import UserMenuOptions from './UserMenuOptions/UserMenuOptions';
import createStyles from './UserMenu.styles';

const UserMenu = (props) => {
  const classes = createStyles();
  const {
    className,
    openLoginBox,
    isAuthenticated,
    user,
    handleLogout,
    setIsUserMenuOpen,
    isUserMenuOpen
  } = props;

  const [anchorElAccountMenu, setUserMenuAnchorEl] = React.useState(null);

  const size = useWindowSize();

  function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
      if (!isClient) {
        return false;
      }

      function handleResize() {
        setWindowSize(getSize());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  }

  const handleOpenUserMenu = (event) => {
    setIsUserMenuOpen(true);
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setIsUserMenuOpen(false);
    setUserMenuAnchorEl(null);
  };

  const handleUserLogout = () => {
    handleCloseUserMenu();
    handleLogout();
  };

  return (
    <>
      {isAuthenticated ? (
        <div className={`${classes.rootMenu} ${className}`}>
          <div className={classes.authWrapper} onClick={handleOpenUserMenu}>
            <AccountCircle
              className={classes.accountCircleIcon}
              fontSize='large'
            />
            {size.width > 1100 && (
              <div className={classes.authLabelsWrapper}>
                <Typography width='' variant='body2'>
                  Olá,
                </Typography>
                <Typography variant='body2' fontSize='1.2rem'>
                  {user.name ? user.name : user.fantasyName}
                </Typography>
              </div>
            )}
          </div>
          <Backdrop
            open={isUserMenuOpen}
            className={classes.backdrop}
            onClick={handleCloseUserMenu}
          />
          <UserMenuOptions
            handleClose={handleCloseUserMenu}
            handleLogout={handleUserLogout}
            className={classes.boxPosition}
            anchorEl={anchorElAccountMenu}
          />
        </div>
      ) : (
        <div className={`${classes.rootMenu} ${className}`}>
          <div
            className={classes.authWrapper}
            onClick={() => openLoginBox('top')}
          >
            <AccountCircle
              className={classes.accountCircleIcon}
              fontSize='large'
            />
            {size.width > 1100 && (
              <div className={classes.authLabelsWrapper}>
                <Typography width='' variant='body2'>
                  Olá, faça login{' '}
                </Typography>
                <Typography variant='body2' fontSize='1.2rem'>
                  ou cadastre-se
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

UserMenu.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default UserMenu;
