import React from 'react';
import { Formik } from 'formik';
import BilletForm from './BilletForm';
import { BilletFormValidator } from './BilletForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';

const BilletFormWrapper = (props) => {
  const initialValues = {
    useTerms: false
  };

  const { sale, totals, isLoading, address, cart, products, externalErrors, handleNext } = props;

  const handleSubmit = async (formValues, actions) => {
    try {
      const saleObject = {
        ...formValues,
        products: products,
        responsible: address,
        cartId: cart && cart.quote_id,
      };
      await sale(saleObject);
    } catch (err) {
      // if (err instanceof InvalidCredentialsError) {

      // }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={BilletFormValidator}
      isInitialValid={false}
    >
      {(propsFormik) => (
        <BilletForm
          isLoading={isLoading}
          externalErrors={externalErrors}
          products={products}
          totals={totals}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default BilletFormWrapper;
