import { getPurchaseHistory } from 'api/PurchaseHistory';

// Actions
const FETCH_PURCHASE_HISTORY_START = 'sesi/login/FETCH_PURCHASE_HISTORY_START';
const FETCH_PURCHASE_HISTORY_SUCCESS = 'sesi/login/FETCH_PURCHASE_HISTORY_SUCCESS';
const FETCH_PURCHASE_HISTORY_FAILURE = 'sesi/login/FETCH_PURCHASE_HISTORY_FAILURE';
const PURCHASE_HISTORY_RESET = 'sesi/login/PURCHASE_HISTORY_RESET';

// Reducer
const initialState = {
  purchaseHistory: [],
  isLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_PURCHASE_HISTORY_START:
      return { ...state, isLoading: true };
    case FETCH_PURCHASE_HISTORY_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case FETCH_PURCHASE_HISTORY_FAILURE:
      return { ...state, ...action.payload };
    case PURCHASE_HISTORY_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// Action Creators
export function fetchPurchaseHistoryStart() {
  return { type: FETCH_PURCHASE_HISTORY_START };
}

export function fetchPurchaseHistorySuccess(data) {
  return { type: FETCH_PURCHASE_HISTORY_SUCCESS, payload: data };
}

export function fetchPurchaseHistoryFailure(data) {
  return { type: FETCH_PURCHASE_HISTORY_FAILURE, payload: data };
}

export function reset() {
  return { type: PURCHASE_HISTORY_RESET };
}

// side effects, only as applicable
export const fetchPurchaseHistory = () => {
  return async dispatch => {
    dispatch(fetchPurchaseHistoryStart());

    try {
      const response = await getPurchaseHistory();
      dispatch(fetchPurchaseHistorySuccess(response));
    } catch (error) {
      dispatch(fetchPurchaseHistoryFailure(error));
    }
  };
};
