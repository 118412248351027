import request, { setHeaderAuth, deleteHeaderAuth } from 'shared/request';
import InvalidCredentialsException from 'shared/error/Exceptions/InvalidCredentialsException';
import BadRequestErrorException from 'shared/error/Exceptions/BadRequestException';
import { deleteAuthToken, setAuthToken } from 'shared/util/LocalStorage';

export const authenticate = async (rawUsername, password) => {
  const username = rawUsername.trim();

  try {
    const response = await request.post('/authentication/login', {
      username,
      password,
      rememberMe: false
    });
    const authorization = response.headers['x-authorization'];
    if (authorization) {
      setAuthToken(authorization);
      setHeaderAuth(authorization);

      return {
        user: response.data.user,
        token: authorization
      };
    }
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();

    throw new BadRequestErrorException();
  }
};

export const getSession = async (token) => {
  try {
    setHeaderAuth(token);
    const response = await request.get('/customers/userByToken');
    return response.data.user;
  } catch (error) {
    return null;
    // const { response } = error;
    // if (response.status === 401) throw new InvalidCredentialsException();
    // throw new BadRequestErrorException();
  }
};

export const logouts = () => {
  try {
    deleteAuthToken();
    deleteHeaderAuth();
    return { user: '', token: '' };
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};

export const logout = async () => {
  try {
    deleteAuthToken();
    deleteHeaderAuth();
    return { user: '', token: '' };
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};

export const registerUser = async ( data ) => {
  data.name = data.name.trim();
  data.email = data.email.trim();
  data.addressShipping = {
    address: data.address,
    addressNumber: data.addressNumber,
    addressComplement: data.addressComplement,
    cep: data.cep,
    state: data.state,
    city: data.city,
    neighbourhood: data.neighbourhood
  };
  data.addressBilling = {
    address: data.addressBilling,
    addressNumber: data.addressNumberBilling,
    addressComplement: data.addressComplementBilling,
    cep: data.cepBilling,
    state: data.stateBilling,
    city: data.cityBilling,
    neighbourhood: data.neighbourhoodBilling
  };
  try {
    return await request.post('/users', data );
  } catch (error) {
    throw error;
  }
};

export const editUserRegistration = async (values) => {
  const user = values
  user.name = values.firstname;
  user.maritalStatus = values.maritalStatus;
  user.scholarity = values.scholarity;
  try {
    const response = await request.put('/users', user);
    return response.data;
  } catch (error) {
    //TODO
  }
};

export const recoverPassword = async (email) => {
  try {
    const response = await request.post('/account/email-reset', email);
    return response.data;
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};

export const registerNewPassword = async (values) => {
  // debugger
  try {
    const response = await request.post('/account/reset-password', {email: values.email, resetToken: values.resetToken, newPassword: values.newPassword});
    return response.data;
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};
