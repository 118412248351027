import React from 'react';
import createStyles from './UserMenuOptions.styles';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const UserMenuOptions = (props) => {
  const classes = createStyles();

  const { handleClose, handleLogout, anchorEl } = props;

  return (
    <Menu
      id='user-account-menu'
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top'
      }}
      elevation={0}
      getContentAnchorEl={null}
      PaperProps={{ square: true }}
      classes={{ paper: classes.rootUserMenuOptions }}
    >
      <MenuItem onClick={handleClose}>
        <Link to='/minha-conta' className={classes.link}>
          Minha Conta
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link to='/meus-pedidos' className={classes.link}>
          Meus Pedidos
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link to='/meus-cursos' className={classes.link}>
          Meus Cursos
        </Link>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <Link to='/' className={classes.link}>
          Sair
        </Link>
      </MenuItem>
    </Menu>
  );
};

export default UserMenuOptions;
