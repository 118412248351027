import Category from './Category.view';
import { connect } from 'react-redux';
import { fetchCategory } from 'modules/Category/Category.ducks.js';
import { openLoginBox, closeLoginBox } from 'modules/Login/Login.ducks.js';

const mapStateToProps = state => {
  return {
    category: state.category
  };
};

const mapDispatchToProps = {
  fetchCategory,
  openLoginBox,
  closeLoginBox
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
