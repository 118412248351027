import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  title: {
    margin: '3rem 0 2.5rem 0',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '0 -1%',
    marginBottom: '8rem',

  },
  course: {
    padding: '1.5rem 1%',
    width: '99%',
    '@media (min-width: 700px)': {
      width: '48%',
    },
    '@media (min-width: 1000px)': {
      width: '31%',
    },
    '@media (min-width: 1800px)': {
      width: '23%',
    },
  },
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

}));

export default createStyles;
