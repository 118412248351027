import React, { useState } from 'react';
import { Formik } from 'formik';
import ForgotPasswordForm from './ForgotPasswordForm';
import { forgotPasswordFormValidator } from './ForgotPasswordForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';

const ForgoPasswordFormWrapper = props => {
  const initialValues = {
    email: ''
  };

  const {
    recoverPassword, 
    isLoading, 
    handleClose, 
    errors, 
    afterRecover
  } = props;


  const handleSubmitForm = async (formValues, actions) => {
    try {
      await recoverPassword(formValues);
      handleClose();
      if (afterRecover) afterRecover();
    } catch (err) {
      if (err instanceof InvalidCredentialsError) {
        this.setState({
          externalErrors: { email: 'Email inválido ou não cadastrado' }
        });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={forgotPasswordFormValidator}
    >
    {propsFormik => (
      <ForgotPasswordForm
        {...propsFormik}
        isLoading={isLoading}
      />
    )}
    </Formik>
  );
};

export default ForgoPasswordFormWrapper;
