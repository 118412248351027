import request, { setHeaderAuth } from 'shared/request';
import InvalidCredentialsException from 'shared/error/Exceptions/InvalidCredentialsException';
import BadRequestErrorException from 'shared/error/Exceptions/BadRequestException';
import { getRegistrationMock } from 'mock/Registration';
import { getAuthToken } from 'shared/util/LocalStorage';

export const getUserRegistration = async () => {
  try {
    // const response = await request.get('/products/showcase');
    // return response.data;
    return new Promise(resolve =>
      setTimeout(function() {
        const result = getRegistrationMock();
        resolve(result);
      }, 1000)
    );
  } catch (error) {
    //TODO
  }
};

//mudar
export const changePassword = async ({newPassword, passwordConfirmation, oldPassword}) => {
  // const bearerToken = getHeaderAuth();
  // let config = {
  //   headers: {
  //     Authorization: bearerToken,
  //   }
  // }
  try {
    const response = await request.post('/account/change-password', {currentPassword: oldPassword, newPassword});
    return response.data;
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};

//mudar
export const changeEmail = async ({email, confirmationPassword}) => {
  try {
    const response = await request.post('/account/change-email', {email, confirmationPassword});
    return response.data;
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};

export const registerNewsletter = async (email) => {
  try {
    const response = await request.post('/newsletter', {email});
    return response.data;
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};

export const registerInterest = async (interest) => {
  // {
//   authorizationToken: string,
//   interest: {
//     fullname: string,
//     telefone: string,
//     email: string,
//     productId: long, id do curso
//     customerToken: string
//   }
// }
  try {
    const response = await request.post('/interest', interest);
    return response;
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};


export const confirmationRequest = async (body) => {
  try {
    const response = await request.post('/account/confirm', body);
    return response.data;
  } catch (error) {
    const { response } = error;
    if (response.status === 401) throw new InvalidCredentialsException();
    throw new BadRequestErrorException();
  }
};