import { makeStyles } from '@material-ui/core/styles';
import { flexbox } from '@material-ui/system';

const createStyles = makeStyles(theme => ({
  root: mediaQuery => ({
    width:  mediaQuery.isPhone ? '100%' : '45.4rem', 
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)', 
    display: 'flex', 
    marginBottom: '16px', 
    marginRight: mediaQuery.isMobile? '0' : '1.6rem' ,
  }), 
  container: {
    justifyContent: 'center',
    position: 'relative', 
    display: 'flex', 
    flexDirection: 'column',
    width: '100%', 
    padding: '1.6rem',
    paddingBottom: '1.6rem!important',
  },
  cardContainer: {
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1.3rem',
  }, 
  titleContainer:{
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  cardInfoContainer: {
    display: 'flex', 
    flexDirection: 'column', 
    position: 'relative', 
  },
  button: {
    marginRight: '2rem',
  }, 
  addIcon: {
    width: 80,
    height: 80,
    color: 'rgba(0, 0, 0, 0.1)',
    '&:hover': {
      color: '#32559D', 
      cursor: 'pointer'
    }
  }, 
  title: {
    textAlign: 'center'

  },
  newContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '232px',
    padding: '1.6rem',
    paddingBottom: '1.6rem!important',
    width: '100%', 

    '&:hover':{
      cursor: 'pointer',

      '& $addIcon': {
        color: theme.colors.blueCompany,
      },
      '& $title': {
        color: theme.colors.blueCompany,
      }
    }
  }
  
}));

export default createStyles;
