import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  orderResumeTitle: {
    marginBottom: '2rem'
  },
  resumeSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  reumePriceSection: {
    borderBottom: `1px solid ${theme.colors.divider}`,
    padding: '2rem 0rem'
  },
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  vouchersContainer: {
    borderBottom: `1px solid ${theme.colors.divider}`,
    padding: '0 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  voucherItem: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    color: theme.colors.blueCompany,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  // discountPriceSection: {
  //   borderBottom: (props) =>
  //     props.cartVouchers.length > 0
  //       ? null
  //       : `1px solid ${theme.colors.divider}`,
  //   padding: '2rem 0rem',
  //   display: 'flex',
  //   justifyContent: 'space-between'
  // },
  priceSectionContainer: {
    marginTop: '3rem'
  },
  resumeItemSection: {
    marginBottom: '3rem'
  },
  voucherItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  voucherPriceNumber: {
    margin: '0 1rem',
    whiteSpace: 'nowrap'
  }
}));

export default createStyles;
