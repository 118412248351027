import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import createStyles from './LoadingContainer.styles';

const LoadingContainer = ({ loading, children, ...props }) => {
  const classes = createStyles(props);
  if (loading) {
    return (
      <div className={classes.rootLoading}>
        <CircularProgress {...props} />
      </div>
    );
  } else {
    return children;
  }
}

export default LoadingContainer;