import React from 'react';
import createStyles from './CartAuthentication.styles';
import AuthenticationBox from 'components/AuthenticationBox/';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const CartAuthentication = (props) => {
  const classes = createStyles(props);

  const { handleNext, isAuthenticated, history } = props;

  if (isAuthenticated) {
    window.location = '/carrinho/itens';
    return null;
  }

  return (
    <div className={classes.authContainer}>
      <AuthenticationBox history={history} isLoginBoxOpen={true} callback={handleNext} />
    </div>
  );
};

CartAuthentication.propTypes = {};

export default withRouter(CartAuthentication);
