import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { addThousandDot } from 'shared/util/textTransformation.js';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';

import createStyles from './CartItem.styles';

const CartItem = (props) => {
  const { x, y, z, w, a } = props;
  const classes = createStyles(props);

  const {
    itemId,
    image,
    areaTag,
    title,
    place,
    duration,
    courseClass,
    price,
    parcels,
    special_price,
    link,
    date,
    isFavorite,
    discount,
    priceWithDiscount,
    handleDelete,
    quantity,priceTotal
  } = props;

  const hasDiscount = discount && discount > 0;

  const formattedPrice = price > 0 ? parseFloat(price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'Gratuito';
  const formattedPriceTotal = priceTotal > 0 ? parseFloat(priceTotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'Gratuito';

  const formattedPriceWithDiscount = priceWithDiscount > 0 ? priceWithDiscount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'Gratuito';

  let priceParcel = priceWithDiscount && parcels && addThousandDot((priceWithDiscount / parcels).toFixed(2).toString().replace('.', ','));

  const formattedTitle = title.length > 60 ? title.slice(0, 60) + '...' : title;

  return (
    <div className={classes.root}>
      <Link to={link} className={classes.imageContainer}>
        <img alt='Imagem do curso' className={classes.image} src={image} />
      </Link>
      <div className={classes.infoContainer}>
        <Link to={link} className={classes.title}>
          {formattedTitle}
        </Link>
        <div className={classes.lowerInfo}>
          <ul className={classes.detailsList}>
            <li className={classes.detail}>
              <Typography>Unidade: </Typography>
              <Typography color='textPrimary'>{place}</Typography>
            </li>
            <li className={classes.detail}>
              <Typography>Duração: </Typography>
              <Typography color='textPrimary'>{duration + ' horas'}</Typography>
            </li>
            {/* <li className={classes.detail}>
              <Typography>Número de vagas: </Typography>
              <Typography color='textPrimary'>{quantity}</Typography>
            </li> */}
            <li className={classes.detail}>
              <Typography>{courseClass}: </Typography>
              <Typography color='textPrimary'>{date}</Typography>
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.priceContainer}>
        <div>
          <Typography color='textSecondary' className={classes.originalPrice}>
            {hasDiscount || special_price !=="" && formattedPrice ? `${formattedPriceTotal}` : ``}
          </Typography>

          <Typography className={classes.price}>
            {hasDiscount && formattedPriceWithDiscount ? `${formattedPriceWithDiscount}` : formattedPrice && `${formattedPrice}`}
          </Typography>

          <Typography
            color='textSecondary'
            className={classes.paymentConditions}
            variant='body2'
          >
            {parcels &&
              priceParcel &&
              `em até ${parcels.toString()}x de R$${priceParcel}`}
          </Typography>
        </div>
      </div>
      <DeleteForeverIcon
        onClick={() => handleDelete(itemId)}
        className={classes.deleteIcon}
      />
    </div>
  );
};

CartItem.propTypes = {
  x: PropTypes.bool,
  y: PropTypes.string,
  z: PropTypes.number,
  w: PropTypes.func,
  a: PropTypes.object
};

export default CartItem;
