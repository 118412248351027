import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: props => props.bgColor,
    width: '58%',
    '@media (max-width: 1160px)': {
      width: '100%',
    },
  }
}));

export default createStyles;
