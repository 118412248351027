import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  input: {
    marginBottom: '1.6rem'
  },
  form: {
    marginTop: '2.4rem'
  }, 
  warning: {
    marginBottom: '1.6rem',
    height: 15,
    left: '0%',
    right: '0%',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 11,
    lineHeight: 15,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.04em',
    color: 'rgba(0, 0, 0, 0.65)'
  },
  errorsText: {
    margin: '5px 0 1rem 0rem'
  }
}));

export default createStyles;
