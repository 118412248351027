import Courses from './Courses.view';
import { connect } from 'react-redux';
import {
  fetchCourses,
  fetchCategories
} from 'modules/Courses/Courses.ducks.js';
import { openLoginBox, closeLoginBox } from 'modules/Login/Login.ducks.js';

const mapStateToProps = (state) => {
  return {
    results: state.courses.courses,
    isLoading: state.courses.isLoading,
    categoriesInCourses: state.courses.categoriesInCourses,
    cityName: state.courses.cityName
  };
};

const mapDispatchToProps = {
  fetchCourses,
  openLoginBox, closeLoginBox
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
