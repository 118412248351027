import { authenticate, getSession } from 'api/Session';
import { logout, logouts } from 'api/Session';
import { recoverPassword } from 'api/Session';
import { registerNewPassword as _registerNewPassword } from 'api/Session';
import {
  setFavorite as _setFavorite,
  hasFavorite as _hasFavorite
} from 'api/Favorites';
import { getAuthToken } from 'shared/util/LocalStorage';
import InvalidCredentialsException from 'shared/error/Exceptions/InvalidCredentialsException';
import { editUserRegistration } from 'api/Session';

// Actions
const LOGIN_START = 'sesi/login/LOGIN_START';
const LOGIN_SUCCESS = 'sesi/login/LOGIN_SUCCESS';
const LOGIN_FAILURE = 'sesi/login/LOGIN_FAILURE';
const LOGIN_RESET = 'sesi/login/LOGIN_RESET';

const SESSION_START = 'sesi/login/SESSION_START';
const SESSION_SUCCESS = 'sesi/login/SESSION_SUCCESS';
const SESSION_FAILURE = 'sesi/login/SESSION_FAILURE';
const SESSION_RESET = 'sesi/login/SESSION_RESET';

const LOGOUT_START = 'sesi/login/LOGOUT_START';
const LOGOUT_SUCCESS = 'sesi/login/LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'sesi/login/LOGOUT_FAILURE';
const LOGOUT_RESET = 'sesi/login/LOGOUT_RESET';

const OPEN_LOGIN_BOX = 'sesi/register/OPEN_LOGIN_BOX';
const CLOSE_LOGIN_BOX = 'sesi/register/CLOSE_LOGIN_BOX';

const RECOVER_PASSWORD_START = 'sesi/login/RECOVER_PASSWORD_START';
const RECOVER_PASSWORD_SUCCESS = 'sesi/login/RECOVER_PASSWORD_SUCCESS';
const RECOVER_PASSWORD_FAILURE = 'sesi/login/RECOVER_PASSWORD_FAILURE';
const RECOVER_PASSWORD_RESET = 'sesi/login/RECOVER_PASSWORD_RESET';

const EDIT_USER_REGISTRATION_START = 'sesi/registration/EDIT_USER_REGISTRATION_START';
const EDIT_USER_REGISTRATION_SUCCESS = 'sesi/registration/EDIT_USER_REGISTRATION_SUCCESS';
const EDIT_USER_REGISTRATION_FAILURE = 'sesi/registration/EDIT_USER_REGISTRATION_FAILURE';
const EDIT_USER_REGISTRATION_RESET = 'sesi/registration/EDIT_USER_REGISTRATION_RESET';

const REGISTER_NEW_PASSWORD_START = 'sesi/registration/REGISTER_NEW_PASSWORD_START';
const REGISTER_NEW_PASSWORD_SUCCESS = 'sesi/registration/REGISTER_NEW_PASSWORD_SUCCESS';
const REGISTER_NEW_PASSWORD_FAILURE = 'sesi/registration/REGISTER_NEW_PASSWORD_FAILURE';
const REGISTER_NEW_PASSWORD_RESET = 'sesi/registration/REGISTER_NEW_PASSWORD_RESET';

// Reducer
const initialState = {
  isAuthenticated: false,
  isLoading: false,
  isRecovering: false,
  success: false,
  errors: '',
  externalErrors: '',
  emailToResetPassword: '',
  token: '',
  user: {},
  ui: {
    isLoginBoxOpen: false,
    authBoxPosition: 'initial'
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EDIT_USER_REGISTRATION_START:
      return { ...state, isEditing: true };
    case EDIT_USER_REGISTRATION_SUCCESS:
      return { ...state, user: action.payload, isLoading: false, status: action.payload };
    case EDIT_USER_REGISTRATION_FAILURE:
      return { ...state, ...action.payload };
    case REGISTER_NEW_PASSWORD_START:
      return { ...state, isLoading: true };
    case REGISTER_NEW_PASSWORD_SUCCESS:
      return { ...state, user: action.payload, isLoading: false, success: true };
    case REGISTER_NEW_PASSWORD_FAILURE:
      return { ...state, errors: "Falha ao registrar sua nova senha.", isLoading: false, success: false };
    case REGISTER_NEW_PASSWORD_RESET:
      return { ...state, ...initialState };
    case SESSION_START:
      return { ...state, isLoading: true };
    case LOGIN_SUCCESS:
    case SESSION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        token: action.payload.token,
        user: action.payload.user,
        ui: { isLoginBoxOpen: false },
        externalErrors: '',
      };
    case LOGIN_START:
      return { ...state, isLoading: true };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        externalErrors: action.payload.message
      };
    case LOGIN_RESET:
      return state;
    default:
      return state;
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: false,
        isLoading: false,
        token: '',
        user: undefined,
        externalErrors: '',
      };
    case LOGOUT_START:
      return { ...state, isLoading: true };
    case LOGOUT_FAILURE:
      return { ...state, isLoading: false, errors: action.data };
    case LOGOUT_RESET:
      return state;
    case OPEN_LOGIN_BOX:
      return {
        ...state,
        externalErrors: '',
        ui: { isLoginBoxOpen: true, authBoxPosition: action.payload }
      };
    case CLOSE_LOGIN_BOX:
      return {
        ...state,
        ui: { isLoginBoxOpen: false }
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        emailSent: action.payload.data,
        emailToResetPassword: action.payload.email,
        isRecovering: false
      };
    case RECOVER_PASSWORD_START:
      return { ...state, isRecovering: true };
    case RECOVER_PASSWORD_FAILURE:
      return { ...state, isRecovering: false, externalErrors: action.data };
    case RECOVER_PASSWORD_RESET:
      return state;
  }
}

// Action Creators
export function loginStart() {
  return { type: LOGIN_START };
}

export function loginSuccess(data) {
  return { type: LOGIN_SUCCESS, payload: data };
}

export function loginFailure(data) {
  return { type: LOGIN_FAILURE, payload: data };
}

export function loginReset() {
  return { type: LOGIN_RESET };
}

export function logoutStart() {
  return { type: LOGOUT_START };
}

export function logoutSuccess(data) {
  return { type: LOGOUT_SUCCESS, payload: data };
}

export function logoutFailure(data) {
  return { type: LOGOUT_FAILURE, payload: data };
}

export function logoutReset() {
  return { type: LOGOUT_RESET };
}

export function openLoginBox(position) {
  return { type: OPEN_LOGIN_BOX, payload: position };
}

export function closeLoginBox() {
  return { type: CLOSE_LOGIN_BOX };
}

export function recoverPasswordStart() {
  return { type: RECOVER_PASSWORD_START };
}

export function recoverPasswordSuccess(data, email) {
  return { type: RECOVER_PASSWORD_SUCCESS, payload: {data, email} };
}

export function recoverPasswordFailure(data) {
  return { type: RECOVER_PASSWORD_FAILURE, payload: data };
}

export function recoverPasswordReset() {
  return { type: RECOVER_PASSWORD_RESET };
}

export function sessionStart() {
  return { type: SESSION_START };
}

export function sessionSuccess(data) {
  return { type: SESSION_SUCCESS, payload: data };
}

export function sessionFailure(data) {
  return { type: SESSION_FAILURE, payload: data };
}

export function sessionReset() {
  return { type: SESSION_RESET };
}

export function editUserRegistrationStart() {
  return { type: EDIT_USER_REGISTRATION_START };
}

export function editUserRegistrationSuccess(data) {
  return { type: EDIT_USER_REGISTRATION_SUCCESS, payload: data };
}

export function editUserRegistrationFailure(data) {
  return { type: EDIT_USER_REGISTRATION_FAILURE, payload: data };
}

export function editUserRegistrationReset() {
  return { type: EDIT_USER_REGISTRATION_RESET };
}

export function registerNewPasswordStart() {
  return { type: REGISTER_NEW_PASSWORD_START };
}

export function registerNewPasswordSuccess(data) {
  return { type: REGISTER_NEW_PASSWORD_SUCCESS, payload: data };
}

export function registerNewPasswordFailure(data) {
  return { type: REGISTER_NEW_PASSWORD_FAILURE, payload: data };
}

// side effects, only as applicable
export const login = (formValues) => {
  const { username, password, rememberMe } = formValues;
  return async (dispatch) => {
    dispatch(loginStart());

    try {
      const response = await authenticate(username, password, rememberMe);
      dispatch(loginSuccess(response));
    } catch (error) {
      if (error instanceof InvalidCredentialsException) {
        dispatch(loginFailure({ message: 'Usuário ou senha inválidos. A senha deve ter no mínimo oito caracteres, contendo letra maiúscula, minúscula e números e um caractere especial' }));
      } else {
        dispatch(loginFailure({ message: 'Ocorreu um erro inesperado ao tentar efetuar o login. Por favor, tente novamente mais tarde.' }));
      }
    }
  };
};

export const session = () => {
  return async (dispatch) => {
    dispatch(sessionStart());
    try {
      const token = getAuthToken();
      if (token) {
        const user = await getSession(token);
        if(user && token){
          dispatch(sessionSuccess({ user, token }));
        }else{
          const response = await logout();
          dispatch(logoutSuccess(response));
        }
      }else{
        const response = await logout();
        dispatch(logoutSuccess(response));
      }
    } catch (error) {
      dispatch(sessionFailure(''));
    }
  };
};


export const sessionNew = async () => {
    sessionStart()
    try {
      const token = getAuthToken();
      if (token) {
        const user = await getSession(token);
        if(user && token){
          sessionSuccess({ user, token });
        }else{
          logoutStart()
          const response = logouts();
          logoutSuccess(response)
        }
      }
    } catch (error) {
      sessionFailure('')
    }
};



export const handleLogout = () => {
  return async (dispatch) => {
    dispatch(logoutStart());
    try {
      const response = await logout();
      // debugger;
      dispatch(logoutSuccess(response));
    } catch (error) {
      dispatch(logoutFailure(error));
    }
  };
};

export const handlePasswordRecover = ({ email }) => {
  return async (dispatch) => {
    dispatch(recoverPasswordStart());
    try {
      const response = await recoverPassword(email);

      dispatch(recoverPasswordSuccess(response, email));
    } catch (error) {
      dispatch(recoverPasswordFailure(error));
    }
  };
};

export const updateUser = () => {
  return async (dispatch) => {
    // alert('//TODO update user');

    // dispatch(registerStart());

    // try {
    //   const response = await registerUser(
    //     name,
    //     email,
    //     cpf,
    //     password,
    //     confirmPassword,
    //     subscribeOffers
    //   );
    //   dispatch(registerSuccess(response.data));
    //   return response;
    // } catch (error) {
    //   dispatch(registerFailure(error));
    //   return error;
    // }
  };
};

export const handleEditUserRegistration = (values) => {
  return async dispatch => {
    dispatch(editUserRegistrationStart());

    try {
      const response = await editUserRegistration(values);
      dispatch(editUserRegistrationSuccess(response));
    } catch (error) {
      dispatch(editUserRegistrationFailure(error));
    }
  };
};

export const registerNewPassword = (values) => {
  return async dispatch => {
    dispatch(registerNewPasswordStart());

    try {
      const response = await _registerNewPassword(values);
      dispatch(registerNewPasswordSuccess(response));
      return response;
    } catch (error) {
      dispatch(registerNewPasswordFailure(error));
    }
  };
};