import NavBar from './NavBar.jsx';
import { connect } from 'react-redux';
import { openLoginBox, closeLoginBox, session } from 'modules/Login/Login.ducks.js';
import { fetchFilters } from 'modules/CourseDetails/CourseDetails.ducks.js';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.session.isAuthenticated,
    searchFilters: state.courseDetails.filters, 
    categories: state.vitrine.categories
  };
};

const mapDispatchToProps = {
  openLoginBox,
  closeLoginBox,
  fetchFilters,
  session
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
