import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  rootMenu: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.white,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  authWrapper: {
    display: 'flex',
    cursor: 'pointer'
  },
  authLabelsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.5rem',
    color: theme.colors.white,
    textDecoration: 'none'
  },
  accountCircleIcon: {
    fontSize: '4.4rem'
  },
  boxPosition: {
    position: 'absolute',
    top: '7.5rem',
    right: '9rem',
    '@media(max-width:960px)': {
      right: '3rem'
    },
    '@media(max-width:400px)': {
      right: '0rem'
    },
    zIndex: theme.zIndex.drawer + 2
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    cursor: 'initial'
  }
}));

export default createStyles;
