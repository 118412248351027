import React, { useEffect, useState, useRef, Fragment } from 'react';
import SearchFilterDesktop from './SearchFilterDesktop';
import SearchFilterMobile from './SearchFilterMobile';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import createStyles from './SearchFilter.styles';

const SearchFilter = props => {
  const classes = createStyles(props);
  const {
    isOpen,
    handleFilterSubmitClick,
    handleClose,
    variant,
    onFiltersChange,
    searchFilters,
    addFilter,
    removeFilter
  } = props;

  const [filters, setFilters] = useState({});
  const isMobile = useMediaQuery('(max-width:1100px)');
  const filterRef = useRef(null);

  useEffect(() => {
    let searchQuery = window.location.search.substring(1) || '';

    if (searchQuery) {
      searchQuery = JSON.parse(
        '{"' +
          decodeURI(searchQuery)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

      let urlFilter = {};

      Object.keys(searchQuery).forEach(item => {
        urlFilter[item] = searchQuery[item].split(',');
      });

      setFilters(urlFilter);

      if (onFiltersChange) {
        onFiltersChange({ ...urlFilter });
      }
    }
  }, []);

  const handleFilterChange = (key, value) => {
    let filterKeyArray = filters[key] || [];
    if (filterKeyArray.includes(value.name)) {
      filterKeyArray = filterKeyArray.filter((item) => item != value.name);
      removeFilter(key, value);
    } else {
      filterKeyArray.push(value.name);
      addFilter(key, value);
    }
        
    const newFilters = {
      ...filters,
      [key]: filterKeyArray
    };
    
    setFilters({
      ...newFilters
    });

    if (onFiltersChange) {
      onFiltersChange({ ...newFilters });
    }
  };

  const onFilterSubmitClick = () => {
    handleFilterSubmitClick(filters);
  };

  return isMobile ? (
    <SearchFilterMobile
      searchFilters={searchFilters}
      isOpen={isOpen}
      checkedFilters={filters}
      handleFilterChange={handleFilterChange}
      handleFilterSubmitClick={onFilterSubmitClick}
      handleClose={handleClose}
      variant={variant}
      filterRef={filterRef}
    />
  ) : (
    <SearchFilterDesktop
      searchFilters={searchFilters}
      isOpen={isOpen}
      variant={variant}
      checkedFilters={filters}
      handleFilterChange={handleFilterChange}
      handleFilterSubmitClick={onFilterSubmitClick}
      handleClose={handleClose}
      filterRef={filterRef}
    />
  );
};

SearchFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleFilterSubmitClick: PropTypes.func,
  handleClose: PropTypes.func,
  variant: PropTypes.string,
  onFiltersChange: PropTypes.func,
  addFilter: PropTypes.func
};

export default SearchFilter;
