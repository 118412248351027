import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.colors.blueCompany,
    color: theme.colors.white,
    // marginTop: '12rem',
  },
  title: {
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%'
    }
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    '& li': {
      marginBottom: '2rem',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '40rem',
      [theme.breakpoints.up('md')]: {
        maxWidth: '28rem',
        textAlign: 'start',
        justifyContent: 'flex-start'
      },
      '& span': {
        marginLeft: '0.8rem'
      },
      '& a': {
        marginLeft: '0.8rem',
        color: theme.colors.white,
        textDecoration: 'none',

        '&:hover':{
          textDecoration: 'underline',
        }
      }
    }
  },
  emailIcon: {
    color: theme.colors.blueCompany
  },
  newsletterStatus: {
    fontSize: '1.35rem',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%'
    }
  },
  mainFooter: {
    padding: '4rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 2rem'
    }
  },
  logoContainer:{
    '@media (min-width: 500px) and (max-width: 1279px)': {
      display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
  },
  sesiLogo: {
    width: '100%',
    maxHeight: '8rem',
    maxWidth: '22rem',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1rem',
    marginBottom: '2rem',
    '@media (min-width: 500px) and (max-width: 1279px)': {
      marginLeft: '2rem',
      marginRight: '2rem',
      marginTop: '0rem',
      marginBottom: '0rem',
  }
  },
  copyRightContainer: {
    backgroundColor: theme.colors.blueHover,
    padding: '1.6rem 3.2rem'
  },
  footerColumn: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'fit-content',
      display: 'block',
      margin: '0 auto'
    }
  },
  bandeirasIcon: {
    margin: '0.5rem'
  },
  bandeirasTitle: {
    marginTop: '4rem'
  },
  '@media (min-width: 960px)': {
    middleItem: {
      display: 'flex',
      justifyContent: 'left'
    },
    lastItem: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  '@media (max-width: 960px)': {
    firstItem: {
      textAlign: 'center'
    },
    middleItem: {
      textAlign: 'center'
    },
    lastItem: {
      textAlign: 'center'
    }
  }
}));

export default createStyles;
