import UserMenu from './UserMenu.jsx';
import { openLoginBox, closeLoginBox, handleLogout } from 'modules/Login/Login.ducks.js';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    isLoginBoxOpen: state.session.ui.isLoginBoxOpen,
    isAuthenticated: state.session.isAuthenticated,
    user: state.session.user
  };
};

const mapDispatchToProps = {
  openLoginBox,
  closeLoginBox, 
  handleLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
