import request from 'shared/request';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL || 'https://senai-gatewayd.exablack.com/api/';

export const create = async (course) => {
  try {
    const response = await request.post('/carts', course);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const applyVoucher = async (code) => {
  try {
    const response = await request.put(`/carts/mine/coupons/${code}`);
    return response.data;
  } catch (error) {
    //TODO
  }
};

export const sale = async (data) => {
  try {
    const response = await request.post('/carts/sale', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const billetSale = async (data) => {
  try {
    const response = await request.post('/carts/sale/billet', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unitSale = async (data) => {
  try {
    const response = await request.post('/carts/sale/unit', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBillet = async (data) => {
  try {
    const requestPdf = axios.create({
      baseURL: `${url}`,
      headers: { 'Content-Type': 'application/json'},
      responseType: 'arraybuffer'
    });
    const response = await requestPdf.post('/boletobb/imprimir', data).then (response => {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'boleto.pdf'
      link.click()
  })
    return response;
  } catch (error) {
    throw error;
  }
};

export const getItemsCart = async () => {
  try {
    const response = await request.get('/carts/mine/items');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTotalsCart = async () => {
  try {
    const response = await request.get('/carts/mine/totals');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeVoucher = async (code) => {
  try {
    const response = await request.delete('/carts/mine/coupons/', {code: code});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteItem = async (sku) => {
  try {
    const response = await request.post('/carts/mine/items/remove', {sku: sku});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePaymentMethod = async (data) => {
  let method = '';
  switch (data.paymentMethod) {
    case 'Cartão de Crédito':
      method = 'getnet_credit_card';
      break;
    case 'Boleto Parcelado':
      method = 'banktransfer';
      break;
    case 'Boleto':
      method = 'boleto_bb';
      break;
    case 'Gratuito':
      method = 'free';
      break;
  }

  const payload = {
    paymentMethod: {
      method: method
    },
    billingAddress: {
      email: data.email,
      region: data.addressBilling.region.region,
      region_code: data.addressBilling.region.region_code,
      country_id: data.addressBilling.country_id,
      street: data.addressBilling.street,
      postcode: data.addressBilling.postcode,
      city: data.addressBilling.city,
      telephone: data.addressBilling.telephone,
      firstname: data.addressBilling.firstname,
      lastname: data.addressBilling.lastname,
      saveInAddressBook: 0
    }
  };

  try {
    const response = await request.post(`/carts/mine/set-payment-method`, payload);
  } catch (error) {
    throw error;
  }
}
