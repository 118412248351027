import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  rootNavBar: {
    position: 'fixed',
    top: 0,
    zIndex: 1000,
    flexGrow: 1,
    width: '100%'
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appBar: {
    backgroundColor: theme.colors.blueEducation,
    boxShadow: 'none',
    transform: 'none',
    paddingRight: (props) => {
      return props.state && props.state.isUserMenuOpen ? '15px' : '0';
    }
  },
  toolBar: {
    minHeight: '7rem',
    padding: 0,
    width: '100%',
    maxWidth: '250rem'
  },
  menuButton: {
    marginRight: '1.6rem'
  },
  userMenuContainer: {
    transition: 'all 400ms ease-in-out',
    display: 'flex',
    alignItems: 'center'
  },
  userMenuItem: {
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginRight: '2rem'
    },
    '@media (max-width: 360px)': {
      '&:not(:last-child)': {
        marginRight: '1rem'
      }
    }
  },
  menuMobile: {
    padding: 0,
    '@media (max-width: 980px)': {
      flexGrow: 1
    }
  },
  menuIcon: {
    fontSize: '4rem'
  },
  logo: {
    height: '5.4rem',
    margin: '1.5rem 2.5rem 1.5rem 0',

    '@media (max-width: 980px)': {
      height: '4.6rem',
      margin: '1.5rem 1.5rem 1.5rem 0'
    }
  },
  searchButton: {
    color: theme.colors.white,
    padding: '.65rem',
    transition: 'all 300ms ease-in-out',
    '@media (max-width: 980px)': {
      display: 'none'
    }
  },
  icon: {
    fontSize: '3.5rem',
    color: theme.colors.white
  },
  searchMobileContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  searchMobile: {
    width: '100%',
    margin: '0'
  },
  link: {
    color: 'white'
  }
}));

export default createStyles;
