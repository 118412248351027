import React, { useState, useEffect } from 'react';
import Input from 'components/Input';
import Button from 'components/ButtonPrimary';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import createStyles from './EditProfileForm.styles';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import Checkbox from '@material-ui/core/Checkbox';
import MaskedInput from 'react-text-mask';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: 'rgba(0, 0, 0, 0.65)'
  }
});

const EditProfileForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isLoading,
    handleChange,
    handleBlur,
    handleSubmit,
    externalErrors
  } = props;

  const [state, setState] = React.useState({
    checkbox: values.receiveInfo
  });

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.formLeft}>
        <Input
          placeholder='Ex:. João da Silva'
          label='Nome completo'
          type='text'
          name='fullName'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.fullName}
          customClasses={{ root: classes.input }}
          error={touched.fullName ? errors.fullName : null}
        />
        <Input
          placeholder='joao.silva@gmail.com'
          label='E-mail'
          type='text'
          name='email'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          customClasses={{ root: classes.input }}
          disabled
          error={touched.email ? errors.email : null}
        />
        <Input
          placeholder='123.458.854-89'
          label='CPF'
          type='text'
          name='cpf'
          mask={'999.999.999-99'}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.cpf}
          customClasses={{ root: classes.input }}
          disabled
          error={touched.cpf ? errors.cpf : null}
        />
      </div>

      <div className={classes.formRight}>
        <Input
          placeholder=''
          label='Data de nascimento'
          type='text'
          name='birthDate'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.birthDate}
          customClasses={{ root: classes.input }}
          mask={'99/99/9999'}
        />

        <FormControl component='fieldset'>
          <FormLabel className={classes.label}>Sexo*</FormLabel>
          <RadioGroup
            defaultValue='female'
            name='gender'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.gender}
            style={{ flexDirection: 'row', marginBottom: '1.6rem' }}
          >
            <FormControlLabel
              value='masculino'
              control={<StyledRadio />}
              label='Masculino'
            />
            <FormControlLabel
              value='feminino'
              control={<StyledRadio />}
              label='Feminino'
            />
          </RadioGroup>
        </FormControl>

        <Input
          placeholder='(27) 99999-9999'
          label='Telefone'
          type='text'
          name='phone'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          customClasses={{ root: classes.input }}
          mask={'(99) 9 9999-9999'}
        />
        <Button lowercase isLoading={isLoading} type='submit' width='100%'>
          Salvar alterações
        </Button>
      </div>
    </form>
  );
};

export default EditProfileForm;

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={
        <RadioButtonCheckedRoundedIcon style={{ color: '#32559D' }} />
      }
      icon={<RadioButtonUncheckedRoundedIcon />}
      {...props}
    />
  );
}
