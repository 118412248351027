import React from 'react';
import PlaceIcon from '@material-ui/icons/Place';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EventIcon from '@material-ui/icons/Event';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'components/ButtonPrimary';
import createStyles from './MyCourseCard.styles';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import moment from 'moment';

const MyCourseCard = props => {
  const classes = createStyles(props);

  const {
    image,
    title,
    place,
    duration,
    courseClasses,
    modalidade,
    date,
    status,
    link,
  } = props;

  let statusName = "";
  switch (status) {
    case 1:
      statusName = "Em andamento";
      break;
    case 2:
      statusName = "Aguardando início";
      break;
    case 3:
      statusName = "Documentação pendente";
      break;
    case 4:
      statusName = "Finalizado";
      break;
  }

  const formattedTitle = title.length > 60 ? title.slice(0, 60) + '...' : title;

  return (
    <div className={classes.root}>
      <Link to={link} className={classes.imageContainer}>
        <img alt='Imagem do curso' className={classes.image} src={image} />
      </Link>
      <div className={classes.infoContainer}>
        <Link to={link} className={classes.title}>
          {formattedTitle}
        </Link>
        <div className={classes.lowerInfo}>
          <div className={classes.coursepropreties}>
          <ul className={classes.detailsList}>
            <li className={classes.detail}>
              <Typography>Cidade: </Typography>
              <Typography color='textPrimary'>{place}</Typography>
            </li>
            <li className={classes.detail}>
              <Typography>Modalidade: </Typography>
              <Typography color='textPrimary'>{modalidade}</Typography>
            </li>
          </ul>
          <ul className={classes.detailsListRight}>
          <li className={classes.detail}>
              <Typography>Duração: </Typography>
              <Typography color='textPrimary'>
                {(duration | 0) + " horas"}
              </Typography>
            </li>
            <li className={classes.detail}>
              <Typography>
                {courseClasses && courseClasses[0].name +":"}
              </Typography>
              <Typography color='textPrimary'>
                {date && moment(date).format('DD/MM/yyyy')}
              </Typography>
            </li>
          </ul>

          </div>
         
          {/* <Typography color='textPrimary' className={classes.status}>{statusName}</Typography> */}
        </div>
      </div>
    </div>
  );
};

MyCourseCard.propTypes = {};

export default MyCourseCard;
