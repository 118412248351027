import React, { useState } from 'react';
import { Formik } from 'formik';
import AddCardForm from './AddCardForm';
import { addCardFormValidator } from './AddCardForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';

const AddCardFormWrapper = props => {
  const initialValues = {
    cardNumber: '', 
    fullName: '', 
    month: '', 
    year: '', 
    securityCode: ''
  };

  const {
    handleAddPayment, 
    handleClose, 
    isLoading
  } = props;
 
 
  const handleSubmitForm = async (formValues, actions) => {
    try {
      await handleAddPayment(formValues);
      handleClose();
    } catch (err) {
      if (err instanceof InvalidCredentialsError) {
        this.setState({
          externalErrors: { email: 'Email inválido ou não cadastrado' }
        });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={addCardFormValidator}
    >
      {propsFormik => (
        <AddCardForm
          {...propsFormik}
          isLoading={isLoading}
        />
      )}
    </Formik>
  );
};

export default AddCardFormWrapper;
