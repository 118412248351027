export const getSearchFilterUrl = (filters, searchTerm) => {
  let searchFiltersUrl = '';

  Object.keys(filters).forEach(filterName => {
    if (filters[filterName].length > 0) {
      let queryParamsFilter = `&${filterName}=`;

      filters[filterName].forEach(filterValue => {
        queryParamsFilter = queryParamsFilter.concat(`${filterValue},`);
      });

      searchFiltersUrl = searchFiltersUrl.concat(
        queryParamsFilter.slice(0, -1)
      );
    }
  });

  searchFiltersUrl = searchFiltersUrl.slice(1);

  if (searchFiltersUrl) searchFiltersUrl = '?'.concat(searchFiltersUrl);
  const separator = searchTerm ? '/' : '';
  
  // return `/busca${separator}${searchTerm}${searchFiltersUrl}`;
  return `/busca${separator}${searchTerm}`;
};
