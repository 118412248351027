import Cart from './Cart.view';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.session.isAuthenticated,
    isLoading: state.favorites.isLoading
  };
};

// const mapDispatchToProps = {
//   fetchCart
// };

export default connect(mapStateToProps, null)(Cart);
// export default connect(null, null)(Cart);
