import React, { useEffect, Fragment } from 'react';
import ButtonPrimary from 'components/ButtonPrimary';
import { Typography } from '@material-ui/core';
import SelectSearch from 'components/SelectSearch';
import createStyles from './SearchHeader.styles';
import SearchFilter from 'components/SearchFilter';
import { getSearchFilterUrl } from 'shared/util/UrlTransformation';
import PropTypes from 'prop-types';
import { useState } from 'react';

const SearchHeader = props => {
  const classes = createStyles(props);

  const {
    totalSearchResults,
    staticFilters,
    searchTerm,
    searchQuery,
    onOrderByChange
  } = props;

  const formatedSearchTerm = searchTerm ? (
    <span className={classes.searchTermSpan}>{`"${searchTerm}"`}</span>
  ) : (
    'a busca'
  );

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleFilterClick = () => {
    setIsFiltersOpen(true);
  };

  const handleFilterSubmitClick = filters => {
    // const urlToRedirect = getSearchFilterUrl(filters, searchTerm);
    // window.location.href = urlToRedirect;
    // search(searchTerm, searchQuery, 0, 10, "", filters);
  };

  return (
    <div className={classes.searchHeader}>
      <Typography
        className={classes.searchDescription}
        variant='h5'
        color='textSecondary'
      >
        {`${totalSearchResults} resultados para `}
        {formatedSearchTerm}
      </Typography>
      <div className={classes.filters}>
        <SelectSearch
          className={classes.headerSelectSeach}
          onSelectChange={onOrderByChange}
          placeholder='Ordenar por...'
          options={staticFilters.map(filter => {
            return {
              label: filter.label,
              value: filter.value
            };
          })}
          icon={'sort'}
          iconSize='small'
        />
        {/* <ButtonPrimary variant='outlined' onClick={handleFilterClick}>
          Filtros
        </ButtonPrimary> */}
      </div>
      <SearchFilter
        isOpen={isFiltersOpen}
        handleFilterSubmitClick={handleFilterSubmitClick}
        handleClose={() => setIsFiltersOpen(false)}
        variant='formBased'
        searchQuery={searchQuery}
      />
    </div>
  );
};

SearchHeader.propTypes = {
  totalSearchResults: PropTypes.number,
  staticFilters: PropTypes.array,
  searchTerm: PropTypes.string,
  searchQuery: PropTypes.string,
  onOrderByChange: PropTypes.func
};
export default SearchHeader;
