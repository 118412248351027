import courseImage from 'assets/images/courseImage.jpg';

export const getMyCoursesMock = () => {
  return {
    myCourses: [
      {
        id: 0,
        image: courseImage,
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          },
       date: '07/09 - 8h às 12h',
        link: '#',
        status: 3
      },
      {
        id: 1,
        image: courseImage,
        title:
          'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          },
       date: '07/09 - 8h às 12h',
        link: '/teste',
        status: 4
      },
      {
        id: 2,
        image: courseImage,
        title: 'Aliquam justo sapien.',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          },
       date: '07/09 - 8h às 12h',
        link: '#',
        status: 2
      },
      {
        id: 3,
        image: courseImage,
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          },
        date: '07/09 - 8h às 12h',
        link: '#',
        status: 1
      },
      {
        id: 4,
        image: courseImage,
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          },
        date: '07/09 - 8h às 12h',
        link: '#',
        status: 2
      },
      {
        id: 5,
        image: courseImage,
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          },
        date: '07/09 - 8h às 12h',
        link: '#',
        status: 3
      },
      {
        id: 6,
        image: courseImage,
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          },
        date: '07/09 - 8h às 12h',
        link: '#',
        status: 4
      }
    ]
  };
};
