import React from 'react';
import createStyles from './CourseBanner.styles';
import { Typography } from '@material-ui/core';
import MainContainer from '../MainContainer/MainContainer';
import CourseDescriptionContainer from '../CourseDetailsContainer/CourseDestailsContainer';

const CourseBanner = props => {
  const classes = createStyles(props);

  return (
    <div className={classes.root}>
      <MainContainer className={classes.mainContainer}>
        <CourseDescriptionContainer  className={classes.mainContainer}>
          <Typography variant='h2' className={classes.title}>
             {props.title}
          </Typography>
        </CourseDescriptionContainer>
      </MainContainer>
      </div>
  );
};

export default CourseBanner;
