import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  sampleRoot: {
    padding: '2rem',
    margin: (props) => (props.x ? '2rem' : '')
  },
  header: {
    background: '#32559D',
    padding: '15px', 
    display: 'flex', 
    flexDirection: 'row',
    ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
      flexWrap: 'wrap'
    },
    justifyContent: 'center',
    alignItems: 'center', 
    alignContent: 'center',
    margin: '23px 0',
    '& > svg': {
      color: '#fff',
      margin: '0 22px'
    },
    '& > p': {
      color: '#fff', 
      fontSize: '30px', 
      lineHeight: '42px',
      ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
        textAlign: 'center'
      },
    },
  }, 
  confirmationContent: {
    background: 'rgba(0, 0, 0, 0.05)',
    padding: '15px', 
    margin: '23px 0 80px 0', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      margin: '20px', 
      // width: '20%'
    }, 
    '& > p': {
      fontSize: '20px', 
      lineHeight: '21px',
      fontWeight: 'normal',
      margin: '20px'
    }
  },
  btn:{
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "16px", 
  }
}));

export default createStyles;
