import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  rootAuthenticatioBox: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.white,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  authWrapper: {
    display: 'flex',
    cursor: 'pointer'
  },
  authLabelsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.5rem'
  },
  accountCircleIcon: {
    fontSize: '3.5rem'
  },
  boxPosition: {
    position: (props) =>
      props.authBoxPosition === 'top' ? 'absolute' : 'initial',
    top: '7.5rem',
    right: '6%',
    '@media(min-width:1500px)': {
      right: '10%'
    },
    '@media(max-width:400px)': {
      right: '0rem'
    },
    zIndex: (props) => (props.isModal ? theme.zIndex.drawer + 2 : 0)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

export default createStyles;
