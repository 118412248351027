import React, { useEffect, useState, useRef, createRef } from 'react';
import MainContainer from 'components/MainContainer';
import LoadingContainer from 'shared/util/LoadingContainer/LoadingContainer';
import moment from 'moment'

const Politicas = props => {
  const {
    isLoading,
    politicaCompras,
    fetchPoliticaCompras
  } = props;

  useEffect(() => {
    fetchPoliticaCompras();
  }, []);

  useEffect(() => {
    document.title = politicaCompras?.title;
  }, [politicaCompras]);

  return (
    <LoadingContainer loading={isLoading}>
      <MainContainer>
        <div dangerouslySetInnerHTML={{ __html: politicaCompras?.content }} />
        {politicaCompras && <p>Última modificação:{moment(politicaCompras.updateTime).format('DD/MM/YYYY, HH:mm:ss')}</p>}
      </MainContainer>
    </LoadingContainer>
  );
};

export default Politicas;