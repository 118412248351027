import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';
import { getCourseDetailsMock } from 'mock/CourseDetails';
import courseImage from 'assets/images/courseImage.jpg';
import gallery1 from 'assets/images/mock/gallery1.jpg';
import gallery2 from 'assets/images/mock/gallery2.jpg';
import gallery3 from 'assets/images/mock/gallery3.jpg';
import banner from 'assets/images/mock/gallery1.jpg';
import card from 'assets/images/mock/gallery1.jpg';

export const getCourseDetails = async (sku) => {
  try {
    const response = await request.get(`/products/sku/${sku}`);
    response.data.details = response.data.details;
    response.data.navigationHistory = getNavigationHistory();
    return response.data;
  } catch (error) {
    //TODO
  }
};

export const getFilters = async () => {
  try {
    const response = await request.get(`/filters/types`);
    return response.data;
  } catch (error) {
    //TODO
  }
};

const getRelatedCourses = () => [
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title:
      '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],
    price: 100,
    discount: 25,
    priceWithDiscount: 75,
    parcels: 2,
    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title:
      'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],
    price: 100,
    discount: 30,
    priceWithDiscount: 34.93,
    parcels: 2,
    link: '/teste',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title: 'Aliquam justo sapien.',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],
    price: 100,
    discount: 30,
    priceWithDiscount: 34.93,
    parcels: 2,
    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title:
      '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],
    price: 49.9,
    discount: 30,
    priceWithDiscount: 34.93,
    parcels: 2,
    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title:
      '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],
    price: 49.9,
    discount: 30,
    priceWithDiscount: 34.93,
    parcels: 2,
    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title: '“Cozinha Prática e Saudável 3”',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],
    price: 49.9,
    discount: 30,
    priceWithDiscount: 34.93,
    parcels: 2,
    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title: '“Cozinha Prática e Saudável 3”',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],
    price: 49.9,
    discount: 30,
    priceWithDiscount: 34.93,
    parcels: 2,
    link: '#',
    isFavorite: false
  }
];

const getNavigationHistory = () => [
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title:
      '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],
    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title:
      'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],

    link: '/teste',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title: 'Aliquam justo sapien.',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],

    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title:
      '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],

    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title:
      '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],

    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title: '“Cozinha Prática e Saudável 3”',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],

    link: '#',
    isFavorite: false
  },
  {
    image: courseImage,
    areaTag: 'Área de Aprendizagem',
    title: '“Cozinha Prática e Saudável 3”',
    place: 'Jardim da Penha - Vitória',
    duration: 40,
    classes: [
      {
        id: 1,
        name: 'Turma A',
        openChairs: 20
      }
    ],

    link: '#',
    isFavorite: false
  }
];
