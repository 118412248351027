import { makeStyles, withTheme } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        padding: 30,
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-between',
        left: 0,
        top: 0,
    }, 
    titleContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
    },
    deleteIcon: {
        position: 'relative', 
        width: 50,
        height: 50,
    }, 
    title: {
        fontStyle: 'normal', 
        fontWeight: 'normal', 
        fontSize: '26px',
        color: ' rgba(0, 0, 0, 0.65)',
    }, 
    buttonContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'flex-end', 
        marginTop: '1rem'
    }, 
    clearIcon: {
        position: 'absolute',
        left: '90.83%',
        top: '0.83%',
        cursor: 'pointer', 
        color: ' rgba(0, 0, 0, 0.65)'
    }
}));

export default createStyles;