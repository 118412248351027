import React, { useEffect } from 'react';
import createStyles from './AddEditAddressModal.styles';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AddEditAddressForm from './Form/AddEditAddressForm.formik';
import AddEditAddressFormPJ from './FormPJ/AddEditAddressFormPJ.formik';
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    paddingBottom: '2px'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
  },
  checked: {},
}))(Switch);

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#32559D;'
    }
  },
  checked: {},
  body1: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px'
  }
})((props) => <Checkbox color='default' {...props} />);

const AddEditAddressModal = (props) => {
  const classes = createStyles(props);

  const [modalStyle] = React.useState(getModalStyle);

  const {
    handleSaveAddress,
    isLoading,
    user,
    isAuthenticated,
    handleNext
  } = props;

  const [state, setState] = React.useState({
    isPJ: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ClearIcon className={classes.clearIcon} onClick={props.handleClose} />
      <div className={classes.frame}>
        <Typography className={classes.title} variant='subtitle1'>
          Responsável Financeiro
        </Typography>
        <Typography style={{ textAlign: 'center' }}>
          {' '}

        </Typography>
        <Typography component="div" style={{ marginTop: '10px' }}>
        <div style={{ marginBottom: '15px', marginTop: '10px' }}>
        
      </div>
          <Grid className={classes.alig} component="label" container alignItems="center" spacing={1}>
          </Grid>
        </Typography>
        <Typography component="div" style={{ marginTop: '10px' }}>
          <Grid className={classes.alig} component="label" container alignItems="center" spacing={1}>
            <Grid item>Pessoa Física</Grid>
            <Grid item>
              <AntSwitch checked={state.isPJ} onChange={handleChange} name="isPJ" />
            </Grid>
            <Grid item>Pessoa Jurídica</Grid>
          </Grid>
        </Typography>


        {state.isPJ ? (
          <AddEditAddressFormPJ
          handleCloseModal={props.handleClose}
          handleSave={handleSaveAddress}
          isLoading={isLoading}
          handleNext={handleNext}
          isEdit={props.isEdit}
          formData={props.data}
          user={props.user}
        />
        ) : (
          <AddEditAddressForm
            handleCloseModal={props.handleClose}
            handleSave={handleSaveAddress}
            isLoading={isLoading}
            handleNext={handleNext}
            isEdit={props.isEdit}
            formData={props.data}
            user={props.user}
          />
        )}


      </div>
    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      // scroll='body'
      scroll="paper"
    >
      {body}
    </Modal>
  );
};

export default AddEditAddressModal;
