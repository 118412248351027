import PrivateRoute from './PrivateRoute.jsx';
import { connect } from 'react-redux';
import { session } from '../../modules/Login/Login.ducks.js';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.session.isAuthenticated
  };
};

const mapDispatchToProps = {
  session
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
