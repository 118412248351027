import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Button from 'components/ButtonPrimary';
import { FormHelperText } from '@material-ui/core';

import createStyles from './RegisterBoxForm.styles';

const RegisterBoxForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className,
    loginErrors,
    setFieldError,
    setFieldValue
  } = props;

  const [customValue, setcustomValue] = useState('');
  useEffect(() => {
    setFieldValue('cpfCNPJ', customValue);
  }, [customValue]);

  function maskCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return cpf;
  }
  
  function maskCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.replace(/(\d{2})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1/$2');
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    return cnpj;
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        placeholder='Ex.: 123.456.789-00'
        label='CPF'
        // label='CPF ou CNPJ'
        type='text'
        name='cpfCNPJ'
        onChange={(e) => {
          let userInput = e.target.value.replace(/\D/g, '');
          if (userInput.length <= 11) {
            setcustomValue(maskCPF(userInput));
          } else if (userInput.length <= 14) {
            setcustomValue(maskCNPJ(userInput));
          }
        }}
        onBlur={handleBlur}
        value={values.cpfCNPJ}
        customClasses={{ root: classes.input }}
        error={touched.cpfCNPJ ? errors.cpfCNPJ : null}
      />
      {loginErrors && (
        <FormHelperText className={classes.errorsText} error>
          {loginErrors}
        </FormHelperText>
      )}
      <Button
        lowercase
        isLoading={isLoading}
        type='submit'
        to='/cadastro'
        width='100%'
      >
        Cadastrar
      </Button>
    </form>
  );
};

export default RegisterBoxForm;
