import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginBottom: '1.6rem'
  },
  form: {
    marginTop: '2.4rem'
  },
  checkbox: {},
  errorsText: {
    margin: '-1rem 0 2rem 0'
  },
  addressLabel: {
    marginBottom: '1rem'
  },
  label: {
    color: (props) =>
      props.error ? theme.colors.alerts.error : theme.colors.text.primary,
    marginBottom: '0.5rem',
    fontSize: '1.4rem',
    width: '100%'
  },
}));

export default createStyles;
