import React, { useState, useEffect, useRef } from 'react';
import Input from 'components/Input';
import Button from 'components/ButtonPrimary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCEP } from 'shared/util/GetCEP.js';

import moment from 'moment';
import 'moment/locale/pt-br';

import createStyles from './AddEditAddressForm.styles';

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#32559D;'
    }
  },
  checked: {},
  body1: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px'
  }
})((props) => <Checkbox color='default' {...props} />);

const AddEditAddressForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [cepError, setCepError] = useState('');
  const [cepCanChange, setCepCanChange] = useState(true);
  const [responsibleSameAsCustomer, setResponsibleSameAsCustomer] = useState(false);

  const classes = createStyles(isMobile);
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    formikRef,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    isEdit
  } = props;

  useEffect(() => {
    let inputCEP = '';

    if (values.cep) {
      inputCEP = values.cep.replace(/_|-/g, '');
    }

    if (inputCEP.length === 0) {
      setCepCanChange(true);
      setCepError('');
    }
    else if (inputCEP.length > 0 && inputCEP.length !== 8) {
      setCepCanChange(true);
      setCepError('CEP inválido');
    }
    else if (inputCEP.length === 8) {
      getCEP(inputCEP).then(
        (response) => {
          setCepError('');
          values.cityCode = response.ibge;

          if (!responsibleSameAsCustomer) {
            values.address = response.address;
            values.neighbourhood = response.neighbourhood;
            values.city = response.city;
            values.state = response.state;

            if (response.city === "" || response.state === "") {
              setCepCanChange(false);
            }
          }
        },
        () => {
          setCepError('CEP não encontrado');
        });
    }
  }, [values.cep]);

  useEffect(() => {
    const updateWidth = () => {
      setIsMobile(window.innerWidth < 800 ? true : false);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  const handleSubmitForm = () => {
    props.handleSubmit(values);
  };

  useEffect(() => {
    setCepError('');

    if (responsibleSameAsCustomer && props.user.customer) {
      formikRef.current.resetForm({ values: values });
      const customer = props.user.customer;
      values.firstName = customer.firstname;
      values.lastName = customer.lastname;
      values.email = customer.email;
      values.cpfCnpj = props.user.cpf;
      values.cep = customer.addresses[0].postcode;
      values.state = customer.addresses[0].region.region;
      values.city = customer.addresses[0].city;
      values.number = customer.addresses[0].street[1];
      values.complement = customer.addresses[0].street[3];
      values.address = customer.addresses[0].street[0];
      values.neighbourhood = customer.addresses[0].street[2];
      values.birthDate = props.user.birthDate;
      values.phone = props.user.phone1;
    } else {
      values.firstName = '';
      values.email = '';
      values.cpfCnpj = '';
      values.cep = '';
      values.state = '';
      values.city = '';
      values.cityCode = '';
      values.number = '';
      values.complement = '';
      values.address = '';
      values.neighbourhood = '';
      values.lastName = '';
      values.birthDate = '';
      values.phone = '';
      formikRef.current.resetForm();
    }
  }, [responsibleSameAsCustomer]);

  return (
    <form className={classes.container} onSubmit={handleSubmitForm}>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}

      <div style={{ marginBottom: '15px', marginTop: '10px' }}>
        <FormControlLabel
          style={{ position: 'relative', top: '15%' }}
          control={<StyledCheckbox onChange={() => { setResponsibleSameAsCustomer(!responsibleSameAsCustomer) }} checked={values.responsible} />}
          label={<Typography variant='body2'>Sou o Responsável Financeiro</Typography>}
          disabled={props.user && props.user.birthDate && moment().diff(moment(props.user.birthDate, "DD/MM/YYYY"), 'years') < 18}
        />
      </div>

      <div>
        { props.user && props.user.birthDate && moment().diff(moment(props.user.birthDate, "DD/MM/YYYY"), 'years') < 18 && (
          <Typography variant='body3'>Para ser seu próprio responsável financeiro, você deve ser maior de 18 anos.</Typography>
        )}
      </div>

      <div className={classes.form}>
        <div className={classes.formColumn}>
          <Input
            label='Nome'
            type='text'
            name='firstName'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            customClasses={{ root: classes.input }}
            error={touched.firstName ? errors.firstName : null}
            disabled={responsibleSameAsCustomer}
          />

          <Input
            label='Sobrenome'
            type='text'
            name='lastName'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            customClasses={{ root: classes.input }}
            error={touched.lastName ? errors.lastName : null}
            disabled={responsibleSameAsCustomer}
          />


          <Input
            label='CPF'
            mask={'999.999.999-99'}
            type='text'
            name='cpfCnpj'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cpfCnpj}
            customClasses={{ root: classes.input }}
            error={touched.cpfCnpj ? errors.cpfCnpj : null}
            disabled={responsibleSameAsCustomer}
          />
          <Input
            label='E-mail'
            type='text'
            name='email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            customClasses={{ root: classes.input }}
            error={touched.email ? errors.email : null}
            disabled={responsibleSameAsCustomer}
          />

          <Input
            placeholder='(27) 99999-9999'
            label='Telefone'
            type='text'
            name='phone'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            customClasses={{ root: classes.input }}
            mask={'(99) 9 9999-9999'}
            error={touched.phone ? errors.phone : null}
            disabled={responsibleSameAsCustomer}
          />

          <Input
            placeholder=''
            label='Data de nascimento'
            type='text'
            name='birthDate'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.birthDate}
            // className={classes.input}
            mask={'99/99/9999'}
            error={touched.birthDate ? errors.birthDate : null}
            disabled={responsibleSameAsCustomer}
          />

        </div>

        <div className={classes.formColumn}>
          <Input
            placeholder='Ex: 11023-456'
            mask={'99999-999'}
            label='CEP (Seu endereço aparecerá automaticamente)'
            type='text'
            name='cep'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cep}
            autoComplete={"off"}
            customClasses={{ root: classes.input }}
            error={cepError ? cepError : touched.cep ? errors.cep : null}
            disabled={responsibleSameAsCustomer}
          />

          <Input
            label='Estado'
            type='text'
            name='state'
            disabled={cepCanChange}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state}
            customClasses={{ root: classes.input }}
            error={touched.state ? errors.state : null}
          />

          <Input
            label='Cidade'
            type='text'
            name='city'
            disabled={cepCanChange}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            customClasses={{ root: classes.input }}
            error={touched.city ? errors.city : null}
          />

          <Input
            label='Bairro'
            type='text'
            name='neighbourhood'
            disabled={responsibleSameAsCustomer}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.neighbourhood}
            customClasses={{ root: classes.input }}
            error={touched.neighbourhood ? errors.neighbourhood : null}
          />

          <Input
            label='Endereço'
            type='text'
            name='address'
            disabled={responsibleSameAsCustomer}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            customClasses={{ root: classes.input }}
            error={touched.address ? errors.address : null}
          />
          <Input
            placeholder='Ex: 250'
            label='Número'
            type='text'
            name='number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.number}
            customClasses={{ root: classes.input }}
            error={touched.number ? errors.number : null}
            disabled={responsibleSameAsCustomer}
          />

          <Input
            placeholder='Ex: Condomínio ABC, Bloco A, APT 202'
            label='Complemento'
            type='text'
            name='complement'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.complement}
            customClasses={{ root: classes.input }}
            error={touched.complement ? errors.complement : null}
            disabled={responsibleSameAsCustomer}
          />
        </div>
      </div>

      <Button
        disabled={ !(responsibleSameAsCustomer || (props.dirty && props.isValid)) }
        style={{ width: '244px' }}
        lowercase
        isLoading={isLoading}
        type='submit'
        width='100%'
      >
        Continuar
      </Button>
    </form>
  );
};

export default AddEditAddressForm;
