import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  rootLinksBar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      float: 'left',
      cursor: 'pointer',
      '& a': {
        borderBottom: `1px solid ${theme.colors.background.bg10}`,
        display: 'block',
        color: theme.colors.text.primary,
        textDecoration: 'none',
        padding: '1.5rem 2.5rem',
        '@media(max-width: 1500px)': { padding: '1.5rem 1.5rem' },
        '@media(max-width: 1250px)': {
          padding: '1.5rem 1.0rem',
          fontSize: '1.2rem'
        },
        '@media(max-width: 1100px)': {
          fontSize: '1.1rem'
        }
      },
      '&:hover a': {
        color: `${theme.colors.blueEducation}`,
        borderBottom: `1px solid ${theme.colors.blueEducation}`
      }
    }
  }
}));

export default createStyles;
