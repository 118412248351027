import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';
import { getSearchMock } from 'mock/Search';

export const getSearch = async (urlParams, page, size, sort, filters) => {
  try {   
    const response = await request.post(`/products${urlParams}`, {
      
        page: page,
        size: size,
        sort: sort,
        params: filters
      
    });
    
    return response.data;
  } catch (error) {
    //TODO
  }
};
