import CartPayment from './CartPayment';
import { connect } from 'react-redux';
import { saleCreditCard, saleReset, saleBillet, saleUnit, setPaymentMethod } from 'modules/Cart/Cart.ducks.js';

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.items,
    totals: state.cart.totals,
    cartVouchers: state.cart.cartVouchers,
    isLoading: state.cart.isLoading,
    isLoadingTotals: state.cart.isLoadingTotals,
    user: state.session.user,
    isAuthenticated: state.session.isAuthenticated,
    cart: state.cart.cart,
    saleError: state.cart.saleError,
    hasSaleError: state.cart.hasSaleError,
    saleSuccess: state.cart.saleSuccess,
    billet: state.cart.billet.isLoading
  };
};

const mapDispatchToProps = { saleCreditCard, saleReset, saleBillet, saleUnit, setPaymentMethod };

export default connect(mapStateToProps, mapDispatchToProps)(CartPayment);
