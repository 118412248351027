import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  title: {
    color: theme.colors.blueCompany,
    textTransform: 'uppercase',
    margin: '3rem 0 2.5rem 0',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    margin: '0',
    marginBottom: '8rem',
    width: '100%',
    maxWidth: '90rem',
  },
  course: {
    boxSizing: 'border-box',
    padding: '1.5rem 0',
    width: '100%',
  },

  tabs:{
    alignSelf: 'flex-start',
    marginBottom: '3rem',

  },
  select:{
    width: '100%',
    marginBottom: '3rem',

  },
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

}));

export default createStyles;
