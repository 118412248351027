import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import MyCourseCard from 'components/MyCourseCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MainContainer from 'components/MainContainer';
import TabButton from 'components/TabButton';
import SelectSearch from 'components/SelectSearch';
import CustomTitle from 'components/CustomTitle';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import createStyles from './MyCourses.styles';
import { toUrlFriendly } from 'shared/util/textTransformation';

const MyCourses = (props) => {
  const { fetchMyCourses } = props;
  const { myCourses, isLoading, user } = props;

  const classes = createStyles(props);

  const isMobile = useMediaQuery('(max-width: 950px)');

  const optionsPF = [
    { value: 0, label: 'Todos' },
    { value: 1, label: 'Em andamento' },
    { value: 2, label: 'Aguardando início' },
    { value: 3, label: 'Documentação pendente' },
    { value: 4, label: 'Finalizado' }
  ];

  const optionsPJ = [
    { value: 0, label: 'Todos' },
    // { value: 1, label: 'Vouchers distribuídos' },
    // { value: 2, label: 'Distribuição pendente' },
    // { value: 3, label: 'Pagamento pendente' },
    // { value: 4, label: 'Cancelado' }
  ];


  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: 'Meus cursos', to: '' }
  ];

  const [stateFilter, setStateFilter] = useState(0);

  useEffect(() => {
    fetchMyCourses();
  }, []);

  var filteredCourses = myCourses.filter((course) => {
    if (stateFilter == 0) {
      return course;
    } else {
      return course.status == stateFilter;
    }
  });

  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbs} />

      {isLoading ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
          <MainContainer>
            <CustomTitle
              className={classes.title}
              title='Meus cursos'
              subtitle='SENAI'
            />
            <div className={classes.container}>
              {isMobile ? (
                <div className={classes.select}>

                  {user.cpf ? 
                  
                    <SelectSearch
                      value={optionsPF[stateFilter]}
                      icon='filter'
                      iconSize='medium'
                      placeholder='Todos'
                      options={optionsPF}
                      onSelectChange={(option) => setStateFilter(option)}
                    ></SelectSearch>

                    :

                    <SelectSearch
                    value={optionsPJ[stateFilter]}
                    icon='filter'
                    iconSize='medium'
                    placeholder='Todos'
                    options={optionsPJ}
                    onSelectChange={(option) => setStateFilter(option)}
                    ></SelectSearch>
                
                  }
                 
                </div>
              ) : (
                
                <div className={classes.tabs}>

                    { user.cpf ? 

                          <>
                             <TabButton
                              onClick={() => setStateFilter(0)}
                              selected={stateFilter == 0}
                              buttonText='Todos'
                            />
                            {/* <TabButton
                              onClick={() => setStateFilter(1)}
                              selected={stateFilter == 1}
                              buttonText='Em andamento'
                            />
                            <TabButton
                              onClick={() => setStateFilter(2)}
                              selected={stateFilter == 2}
                              buttonText='Aguardando início'
                            />
                            <TabButton
                              onClick={() => setStateFilter(3)}
                              selected={stateFilter == 3}
                              buttonText='Documentação pendente'
                            />
                            <TabButton
                              onClick={() => setStateFilter(4)}
                              selected={stateFilter == 4}
                              buttonText='Finalizado'
                            /> */}
                          
                          
                          </>

                        : 

                          <>
                          
                          <TabButton
                              onClick={() => setStateFilter(0)}
                              selected={stateFilter == 0}
                              buttonText='Todos'
                            />
                            {/* <TabButton
                              onClick={() => setStateFilter(1)}
                              selected={stateFilter == 1}
                              buttonText='Vouchers distribuídos'
                            />
                            <TabButton
                              onClick={() => setStateFilter(2)}
                              selected={stateFilter == 2}
                              buttonText='Distribuição pendente'
                            />
                            <TabButton
                              onClick={() => setStateFilter(3)}
                              selected={stateFilter == 3}
                              buttonText='Pagamento pendente'
                            />
                            <TabButton
                              onClick={() => setStateFilter(4)}
                              selected={stateFilter == 4}
                              buttonText='Cancelados'
                            /> */}
                          
                          </>

                    }     
                  </div>
                )}
              {filteredCourses &&
                filteredCourses.map((course) => {
                  return (
                    <div className={classes.course}>
                      <MyCourseCard
                        key={course.id}
                        id={course.id}
                        image={course.image}
                        areaTag={course.areaTag}
                        title={course.title}
                        place={course.classes && course.classes[0].place}
                        duration={course.classes[0].duration}
                        courseClasses={course.classes}
                        modalidade={course.presencialDistancia}
                        price={course.price}
                        parcels={course.parcels}
                        link={`/curso/${toUrlFriendly(course.title)}/${course.sku}`}
                        favorite={course.favorite}
                        discount={course.discount}
                        priceWithDiscount={course.priceWithDiscount}
                        status={course.status}
                        date={getCustomAttribute(course.classes[0].product, "start_date")}
                      />
                    </div>
                  );
                })}
            </div>
          </MainContainer>
        )}
    </React.Fragment>
  );
};

export default MyCourses;
