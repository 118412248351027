import AddEditAddressModal from './AddEditAddressModal.jsx';
import { connect } from 'react-redux';
import { handleSaveAddress } from 'modules/Adresses/Address.ducks.js';

const mapStateToProps = state => {
  return {
    isLoading: state.adresses.isLoading
  };
};

const mapDispatchToProps = {
  handleSaveAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAddressModal);