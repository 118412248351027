import React, { useState } from 'react';
import { Formik } from 'formik';
import EditAddressForm from './EditAddressForm';
import { EditAddressFormValidator } from './EditAddressForm.validator';

const EditAddressFormWrapper = (props) => {
  const initialValues = {
    name: '',
    neighbourhood: '',
    cep: '',
    address: '',
    state: '',
    number: '',
    city: '',
    complement: '',
    deliveryAddress: false,
    chargeAddress: true
  };

  const handleSubmit = async (formValues, actions) => {
    props.handleCloseModal();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={EditAddressFormValidator}
    >
      {(propsFormik) => <EditAddressForm {...propsFormik} />}
    </Formik>
  );
};

export default EditAddressFormWrapper;
