import { getMyCoursesMock } from 'mock/MyCourses';
import request from 'shared/request';

export const getMyCourses = async () => {
  try {
    const response = await request.get('/customers/completedCourses');
    return response.data;
  } catch (error) {
    //TODO
  }
};
