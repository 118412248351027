import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  rootMainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: (props) => props.bgColor,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    // maxWidth: '130rem',
    position: 'relative'
  }
}));

export default createStyles;
