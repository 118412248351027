import * as Yup from 'yup';

const EditAddressForm = {
  // password: Yup.string().required('Este campo é obrigatório')

  name: Yup.string().required('Este campo é obrigatório'),
  neighbourhood: Yup.string().required('Este campo é obrigatório'),
  cep: Yup.string().required('Este campo é obrigatório')
    .test('cep', 'Este campo é obrigatório', function(value) {
      // needed because placeholder `_____-___` counts as a non-empty string for required check above
      return value && value.replace(/_|-/g, '');
    }),
  address: Yup.string().required('Este campo é obrigatório'),
  state: Yup.string().required('Este campo é obrigatório'),
  number: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
  deliveryAddress: Yup.boolean().test(
    function (value) {
      if (!value && !this.parent.chargeAddress) {
        return false;
      }
      return true;
    }
  ),
  chargeAddress: Yup.boolean().test(
    function (value) {
      if (!value && !this.parent.chargeAddress) {
        return false;
      }
      return true;
    }
  )
};

export const EditAddressFormValidator = Yup.object().shape(EditAddressForm);
