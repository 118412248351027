import axios from 'axios';
import { getAuthToken } from 'shared/util/LocalStorage';

import { handleLogout, session } from '../modules/Login/Login.ducks'

// const url = process.env.REACT_APP_API_URL || 'http://127.0.0.1:9999/api';
const url = process.env.REACT_APP_API_URL || 'https://senai-gatewayd.exablack.com/api/';

const request = axios.create({
  baseURL: `${url}`,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json'
});

export const configureIntecerptor = (history) => {
  
  const token = getAuthToken();
  if(token){
    setHeaderAuth(token);
  }
  request.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error.response.status === 401 &&
        !error.response.config.url.includes('change_password') &&
        !error.response.config.url.endsWith('login')
      ) {
        return history.push(history.location);
      }

      throw error;
    }
  );
};

export const setHeaderAuth = (token) => {
  request.defaults.headers.common['Authorization'] = token;
};

export const getHeaderAuth = () =>
  request.defaults.headers.common['Authorization'];

export const deleteHeaderAuth = (token) => {
  delete request.defaults.headers.common['Authorization'];
};

export default request;
