import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  root: ({ props }) => ({
    transition: 'all 300ms ease-in-out',
    transform: props.isOpen ? '' : 'translateX(100%)',
    visibility: props.isOpen ? 'visible' : 'hidden',
    position: 'fixed',
    top: '10rem',
    right: '0',
    height: '100%',
    display: 'flex',
    // justifyContent: 'flex-start',
    alignItems: 'flex-start',
    zIndex: '100',
    paddingBottom: '1rem',
    backgroundColor: theme.colors.white,
    '@media (max-width: 980px)': {
      top: '15.5rem'
    },
    width: '100%',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent'
  }),

  container: ({ windowSize }) => ({
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: windowSize.height - 130,
    overflowY: 'auto',
    overflowX: 'hidden',
    // width: '100%',
    width: '30rem',
    padding: '1.5rem 0',
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)',

    '@media (max-width: 980px)': {
      height: windowSize.height - 185
    }
  }),

  filtersLabel: {
    marginLeft: '1.6rem',
    padding: '1rem 0'
  },

  button: {
    color: theme.palette.primary,
    width: '30rem',
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    borderRadius: '0',
    padding: '1rem 1.6rem',
    backgroundColor: theme.colors.white,

    '&:hover': {
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)'
    }
  },

  buttonLabel: {
    textTransform: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.6rem'
  },

  buttonLabelBack: {
    textTransform: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '2rem'
  },

  optionContainer: ({ windowSize }) => ({
    visibility: 'hidden',
    position: 'absolute',
    top: '0rem',
    right: '-100%',
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: windowSize.height - 130,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '30rem',
    padding: '1.5rem 0',
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 400ms ease-in-out',

    '@media (max-width: 980px)': {
      height: windowSize.height - 185
    }
  }),
  showOptions: {
    visibility: 'visible!important',
    right: '0!important'
  },
  option: {
    margin: '0',
    padding: '0 .6rem'
  },

  optionLabel: {
    fontSize: '1.6rem',
    padding: '1rem 0rem',
    color: theme.palette.text.primary
  },
  filterBtn: {
    marginTop: '2rem',
    boxSizing: 'border-box',
    padding: '0 2rem',
    width: '100%'
  }
}));

export default createStyles;
