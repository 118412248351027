import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    color: theme.colors.blueEducation,
    textTransform: 'uppercase',
    wordBreak: "normal",
    '@media(max-width:720px)': {
      wordBreak: "break-word",
    }
  },
  subtitle: {
    color: theme.colors.text.miniTextLight
  },
  titleBar: {
    fontSize: '6rem',
    color: theme.colors.blueEducation,
    fontWeight: 'bold',
    marginRight: '2rem'
  }
}));

export default createStyles;
