
export const getPurchaseHistoryMock = () => {
  return {
    purchaseHistory: [
      {
        id: 1206739,
        date: '01/03/2020',
        totalPrice: 1200,
        payment: 'Mastercard  •••• 8415',
        status: 1,
        items: [
          {
            id: 1,
            name: '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            price: 200,
            discount: 0,
        priceWithDiscount: 200,
        parcels: 2,
          },
          {
            id: 2,
            name: 'Instalador Mecânico de Máquina de Costura Ponto Corrente e Overlock',
            price: 2000,
            discount: 50,
            priceWithDiscount: 1000,
            parcels: 10,
          }
        ],
        nfe: "TODO",
      },
      {
        id: 1206738,
        date: '01/03/2020',
        totalPrice: 1200,
        payment: 'Mastercard  •••• 8415',
        status: 2,
        items: [
          {
            id: 1,
            name: '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            price: 200,
            discount: 0,
        priceWithDiscount: 200,
        parcels: 2,
          },
          {
            id: 2,
            name: 'Instalador Mecânico de Máquina de Costura Ponto Corrente e Overlock',
            price: 2000,
            discount: 50,
            priceWithDiscount: 1000,
            parcels: 10,
          }
        ],
        nfe: "TODO",
      },
      {
        id: 1206737,
        date: '01/03/2020',
        totalPrice: 1200,
        payment: 'Mastercard  •••• 8415',
        status: 2,
        items: [
          {
            id: 1,
            name: '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            price: 200,
            discount: 0,
        priceWithDiscount: 200,
        parcels: 2,
          },
          {
            id: 2,
            name: 'Instalador Mecânico de Máquina de Costura Ponto Corrente e Overlock',
            price: 2000,
            discount: 50,
            priceWithDiscount: 1000,
            parcels: 10,
          }
        ],
        nfe: "TODO",
      },
      {
        id: 1206736,
        date: '01/03/2020',
        totalPrice: 1200,
        payment: 'Mastercard  •••• 8415',
        status: 3,
        items: [
          {
            id: 1,
            name: '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            price: 200,
            discount: 0,
        priceWithDiscount: 200,
        parcels: 2,
          },
          {
            id: 2,
            name: 'Instalador Mecânico de Máquina de Costura Ponto Corrente e Overlock',
            price: 2000,
            discount: 50,
            priceWithDiscount: 1000,
            parcels: 10,
          }
        ],
        nfe: "TODO",
      },
      {
        id: 1206735,
        date: '01/03/2020',
        totalPrice: 1200,
        payment: 'Mastercard  •••• 8415',
        status: 4,
        items: [
          {
            id: 1,
            name: '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            price: 200,
            discount: 0,
        priceWithDiscount: 200,
        parcels: 2,
          },
          {
            id: 2,
            name: 'Instalador Mecânico de Máquina de Costura Ponto Corrente e Overlock',
            price: 2000,
            discount: 50,
            priceWithDiscount: 1000,
            parcels: 10,
          }
        ],
        nfe: "TODO",
      },
    ]
  };
};
