import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import { Typography, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormHelperText } from '@material-ui/core';

import createStyles from './LoginBoxForm.styles';

const LoginForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    loginErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className,
    handleOpenModal
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const visibilityIcon = isPasswordVisible ? <Visibility /> : <VisibilityOff />;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        placeholder='Ex.: joao.silva@gmail.com'
        // label='E-mail, CPF ou CNPJ'
        label='E-mail ou CPF'
        name='username'
        type='text'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.username}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.username ? errors.username : null}
      />
      <Input
        placeholder='*******'
        label='Senha'
        type={isPasswordVisible ? 'text' : 'password'}
        name='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.password ? errors.password : null}
        endIcon={{
          onClick: () => setIsPasswordVisible(!isPasswordVisible),
          icon: visibilityIcon,
        }}
      />
      <div className={classes.actions}>
        <Typography
          className={classes.rememberMeLabel}
          variant='body2'
          color='textSecondary'
        >
          <Checkbox
            size='small'
            color='primary'
            inputProps={{ 'aria-label': 'lembrar de mim' }}
            name='rememberMe'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            className={classes.rememberMeCheckbox}
          />
          Lembrar de mim
        </Typography>
        <Link
          to='#'
          onClick={handleOpenModal}
          className={classes.forgotPasswordLabel}
        >
          Esqueceu a senha?
        </Link>
      </div>
      {loginErrors && (
        <FormHelperText className={classes.errorsText} error>
          {loginErrors}
        </FormHelperText>
      )}
      <Button lowercase isLoading={isLoading} type='submit' width='100%'>
        Entrar
      </Button>
    </form>
  );
};

export default LoginForm;
