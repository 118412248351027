import React, {useEffect, useState, useRef, useCallback} from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import MainContainer from 'components/MainContainer';
import createStyles from './Courses.styles';
import { Typography } from '@material-ui/core';
import Pagination from 'components/Pagination';
import CourseCard from 'components/CourseCard';
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import { toUrlFriendly } from 'shared/util/textTransformation';
import SelectSearch from 'components/SelectSearch';

const Courses = props => {
  const {
    fetchCourses,
    openLoginBox,
    closeLoginBox,
    results,
    totalSearchResults,
    isLoading,
  } = props;

  const [filterBy, setFilterBy] = useState('');
  const [itensPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const rootRef = useRef(null);
  const classes = createStyles(isMobile);

  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: results && results[0] && results[0].classes[0].place, to: '' },
  ];

  const { city, id } = useParams();
  let location = useLocation();

  const [orderBy, setOrderBy] = useState('more_relevant');
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const staticFilters = [
    { label: 'Menor preço', value: 'lower_price' },
    { label: 'Maior preço', value: 'higher_price' },
    { label: 'A-Z', value: 'a_z' },
    { label: 'Z-A', value: 'z_a' }
  ]

  function onOrderByChange (value) {

      let stockZero = results.filter(r=> r.qtyClasses == 0);
      let withStock = results.filter(r=> r.qtyClasses > 0);
      if (value === "lower_price")
        withStock.sort((a, b) => (a.qtyClasses == 0 ? -1 : parseFloat(a.price)) - parseFloat(b.price));

      if (value === "higher_price")
        withStock.sort((a, b) => parseFloat(b.price) - (a.qtyClasses == 0 ? -1 : parseFloat(a.price)) );

      if (value === "a_z")
        withStock.sort((a, b) => {
          if(a.title < b.title)
            return -1;
          else if(a.title > b.title)
            return 1;
          return 0;
        });

      if (value === "z_a")
        withStock.sort((a, b) => {
          if(a.title < b.title)
            return 1;
          else if(a.title > b.title)
            return -1;
          return 0;
        });

    results.length = 0;

    results.push(...withStock)
    results.push(...stockZero)

    forceUpdate()
    setOrderBy(results);
  }

  useEffect(() => {
    const updateWidth = () => {
      if (rootRef.current) {
        setIsMobile(rootRef.current.offsetWidth < 720 ? true : false)
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });


  useEffect(() => {
    window.scrollTo(0, 0)
    fetchCourses(id, currentPage, itensPerPage, filterBy);
  }, []);

  const onPageChange = page => {
    setCurrentPage(page.selected);
  };

  const onFilterBy = value => {
    setFilterBy(value);
  }

  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbs} />
      {isLoading ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
          <React.Fragment >
            <div ref={rootRef}>
              <div className={classes.bar}>
                <Typography variant="h5" className={classes.title}>SENAI {results && results[0] && results[0].classes[0].place}</Typography>
              </div>
              <MainContainer >
                {/* <div className={classes.selectContainer}>
                  <SelectSearch
                    placeholder="Categorias"
                    icon={'area'}
                    // options={categoriesInCourses.map(filter => {
                    //   return {
                    //     label: filter.categoryName,
                    //     value: filter
                    //   };
                    // })}
                    className={classes.select}
                    onSelectChange={onFilterBy}
                  />
                </div> */}
                {results && results.length == 0 &&
                  <Typography variant="h5" style={{color: 'gray', marginTop: '20px'}}>Não foram encontrados cursos</Typography>
                }
                <div className={classes.filters}>
                  <SelectSearch
                      onSelectChange={onOrderByChange}
                      className={classes.headerSelectSeach}
                      placeholder='Ordenar por...'
                      options={staticFilters.map(filter => {
                        return {
                          label: filter.label,
                          value: filter.value
                        };
                      })}
                      icon={'sort'}
                      iconSize='small'
                  />
                </div>
                <div className={classes.courseContainer}>
                  {
                    filterBy.length === 0 ? results.map((result, index) => {
                      return (
                        <div className={classes.item} key={index}>
                          <CourseCard
                            image={result.image}
                            areaTag={getCustomAttribute(result.classes[0].product, 'area')}
                            title={result.title}
                            place={result.classes[0].place}
                            duration={parseInt(result.classes[0].duration)}
                            courseClasses={result.hasOpenChairs}
                            price={result.price}
                            parcels={result.parcels == 0 ? '' : result.parcels}
                            modalidade={result.presencialDistancia}
                            link={`/curso/${toUrlFriendly(result.title)}/${result.sku}`}
                            favorite={result.favorite}
                            discount={result.discount}
                            priceWithDiscount={result.priceWithDiscount}
                            startDate={getCustomAttribute(result.classes[0].product, 'start_date')}
                            urlNacional={result.link}
                            showLoginBox={openLoginBox}
                            closeLoginBox={closeLoginBox}
                            descriptionPlan={getCustomAttribute(result.classes[0].product, 'desc_plano')}
                          />
                        </div>
                      );
                    }) :
                      results.map((result, index) => {
                        if (result.extensionAttributes.categoryLinks.some(e => e.categoryId === filterBy.categoryId)) {
                          return (
                            <div className={classes.item} key={index}>
                              <CourseCard
                                image={result.image}
                                areaTag={getCustomAttribute(result.classes[0].product, 'area')}
                                title={result.title}
                                place={result.place}
                                duration={parseInt(result.classes[0].duration)}
                                courseClasses={result.hasOpenChairs}
                                price={result.price}
                                modalidade={result.presencialDistancia}
                                parcels={result.parcels == 0 ? '' : result.parcels}
                                link={`/curso/${toUrlFriendly(result.title)}/${result.sku}`}
                                favorite={result.favorite}
                                discount={result.discount}
                                priceWithDiscount={result.priceWithDiscount}
                                startDate={getCustomAttribute(result.classes[0].product, 'start_date')}
                                urlNacional={result.link}
                              />
                            </div>
                          )
                        };
                      })
                  }
                </div>

                <div className={classes.pagination}>
                  <Pagination
                    className={classes.paginationContainer}
                    totalSearchResults={totalSearchResults}
                    itensPerPage={itensPerPage}
                    initialPage={currentPage}
                    onPageChange={onPageChange}
                  />
                </div>
              </MainContainer>
            </div>
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default Courses;
