import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  rootUserMenuOptions: {
    width: '200px',
    '@media (max-width: 400px)': {
      width: '100%'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.8)'
  }
}));

export default createStyles;
