
export const getAddressMock = () => {
    return {
      adresses: [
        {
            id: 12345,
            name: 'Casa1', 
            street: 'Rua Dr. Antônio Basílio',
            number: '400', 
            complement: 'Condomínio do João, Bloco C, APT 505', 
            neighbourhood: 'Jardim da Penha', 
            city: 'Vitória', 
            uf: 'ES', 
            cep: '29060-390', 
            deliveryAddress: false, 
            chargeAddress: true
          }, 
          {
            id: 78549,
            name: 'Endereço 1', 
            street: 'Av Nossa Senhora da Penha',
            number: '2150', 
            complement: 'Condomínio do João, Bloco C, APT 505', 
            neighbourhood: 'Barro Vermelho ', 
            city: 'Vitória', 
            uf: 'ES', 
            cep: '29057-910', 
            deliveryAddress: true, 
            chargeAddress: false
          }
        
      ]
    };
  };
  