import React, { useState, useEffect } from 'react';
import Input from 'components/Input';
import Button from 'components/ButtonPrimary';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import createStyles from './RegistrationForm.styles';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { getCEP } from 'shared/util/GetCEP';
import { getCitiesByState, getMunicipalitiesByState } from "api/City";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: 'rgba(0, 0, 0, 0.65)'
  }
});

const RegistrationForm = props => {
  const [selectedScholarity, setSelectedScholarity] = useState({});
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState({});
  const [selectedHomestate, setSelectedHomestate] = useState({});
  const [selectedSituacaoOcup, setSelectedSituacaoOcup] = useState({});
  const [selectedMunicipality, setSelectedMunicipality] = useState({});
  const [selectedHometown, setSelectedHometown] = useState({});
  const [selectedRgUf, setSelectedRgUf] = useState();
  const letter = /(?!.*[DFIOQU])[A-Z]/i;
  const radioStyles = useStyles();
  const [isMobile, setIsMobile] = useState(false);
  const [cepError, setCepError] = useState('');
  const [selectState, setSelectState] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);

  const classes = createStyles(isMobile);
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className,
    loginErrors,
    success
  } = props;

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (values.hometown) {

      const homestateOption = selectState.filter(item => item.label === values.hometown)
      setSelectedHometown(homestateOption);
      values.hometown = values.hometown
    }
  }, [selectState]);

  useEffect(() => {
    if (values.municipality) {

      const homestateOption = municipalities.filter(item => item.value === values.municipality)
      setSelectedMunicipality(homestateOption);
      values.municipality = values.municipality
    }
  }, [municipalities]);
  
  useEffect(() => {
    setOpen(success);
  }, [success]);

  useEffect(() => {
    if (!values.gender) {
      values.gender = "1";
    }
    if (values.scholarity) {
      const scholarityOption = scholarityOptions.filter(item => item.magentoValue === values.scholarity.value);
      setSelectedScholarity(scholarityOption);
      values.scholarity = values.scholarity.value;
    }

    if (values.maritalStatus) {
      const maritalStatusOption = maritalStatusOptions.filter(item => item.magentoValue === values.maritalStatus.value);
      setSelectedMaritalStatus(maritalStatusOption);
      values.maritalStatus = values.maritalStatus.value;
    }

    if (values.homestate) {
      const homestateOption = homestateOptions.filter(item => item.value === values.homestate.value);
      setSelectedHomestate(homestateOption);
      values.homestate = values.homestate.value;
    }

    if (values.rgUf) {
      const homestateOption = homestateOptions.filter(item => item.label === values.rgUf);
      setSelectedRgUf(homestateOption);
      values.rgUf = values.rgUf;
    }

    if (values.situacaoOcup) {
      const homestateOption = situacaoOpcoes.filter(item => item.value === values.situacaoOcup);
      setSelectedSituacaoOcup(homestateOption);
      values.situacaoOcup = values.situacaoOcup;
    }

    setOpen(success);
  }, [])

  useEffect(() => {
    const updateWidth = () => {
      setIsMobile(window.innerWidth < 800 ? true : false);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  //FIXME mover para RegisterBoxForm.validator.js
  useEffect(() => {
    let inputCEP = '';

    if (values.cepBilling) {
      inputCEP = values.cepBilling.replace(/_|-/g, '');
    }

    if (inputCEP.length === 0) {
      setCepError('');
    }
    else if (inputCEP.length > 0 && inputCEP.length !== 8) {
      setCepError('CEP inválido');
    }
    else if (inputCEP.length === 8 && touched.cepBilling) {
      getCEP(inputCEP).then(
        (response) => {
          values.addressBilling = response.address;
          values.neighbourhoodBilling = response.neighbourhood;
          values.stateBilling = response.state;
          values.cityBilling = response.city;
          values.ibgeBilling = response.ibge;

          values.cepShipping = values.cepBilling;
          values.stateShipping = response.state;
          values.cityShipping = response.city;
          values.ibgeShipping = response.ibge;
          setCepError('');
        },
        () => {
          setCepError('CEP não encontrado');
        });
    }
  }, [values.cepBilling]);

  useEffect(() => {
    values.addressShipping = values.addressBilling;
  }, [values.addressBilling]);

  useEffect(() => {
    values.cityShipping = values.cityBilling;
  }, [values.cityBilling]);

  useEffect(() => {
    values.neighbourhoodShipping = values.neighbourhoodBilling;
  }, [values.neighbourhoodBilling]);

  useEffect(() => {
    values.addressNumberShipping = values.addressNumberBilling;
  }, [values.addressNumberBilling]);

  useEffect(() => {
    values.addressComplementShipping = values.addressComplementBilling;
  }, [values.addressComplementBilling]);

  useEffect(() => {
    if (values.homestate !== "") {

      var resp = {}
      homestateOptions.map((item) => {
        if (item.value === values.homestate) {
          resp = item;
        }
      });
      handleSelectedState(resp.label)
    }
  }, [values.homestate])

  const handleSelectedState = async (resp) => {
    // var respCidade = await getCitiesByState(resp)
    // setSelectState(respCidade)

    var respMunicipio = await getMunicipalitiesByState(resp)
    setMunicipalities(respMunicipio)
  }

  const handleChangeHomecity = (selectedOption) => {
    // setSelectState(selectedOption)
    values.hometown = selectedOption.label;
  };

  const handleChangeMunicipio = (selectedOption) => {
    setSelectedMunicipality(selectedOption)
    values.municipality = selectedOption.value;
    values.hometown = selectedOption.label;
  };

  var type = (function (global) {
    var cache = {};
    return function (obj) {
      var key;
      return obj === null ? 'null' // null
        : obj === global ? 'global' // window in browser or global in nodejs
          : (key = typeof obj) !== 'object' ? key // basic: string, boolean, number, undefined, function
            : obj.nodeType ? 'object' // DOM element
              : cache[key = ({}).toString.call(obj)] // cached. date, regexp, error, object, array, math
              || (cache[key] = key.slice(8, -1).toLowerCase()); // get XXXX from [object XXXX], and cache it
    };
  }(this));

  const handleChangeRadio = (event) => {
    values.gender = event.target.value;
  }

  const handleChangeSituacaoOcupacional = (selectedOption) => {
    setSelectedSituacaoOcup(selectedOption)
    values.situacaoOcup = selectedOption.value;
  };

  const situacaoOpcoes = [
    { value: '1', label: 'Empregado' },
    { value: '2', label: 'Desempregado' },
    { value: '3', label: 'Empregador' },
    { value: '4', label: 'Autônomo/Conta Própria' },
    { value: '5', label: 'Profissional Liberal' },
    { value: '6', label: '1° Emprego' },
    { value: '7', label: 'Aposentado' },
    { value: '8', label: 'Microempreendedor Individual - MEI' },
    { value: '9', label: 'Aprendiz com contrato' }
  ];

  const maritalStatusOptions = [
    { value: "C", label: "Casado", magentoValue: "5476" },
    { value: "D", label: "Desquitado", magentoValue: "5477" },
    { value: "E", label: "União Estável", magentoValue: "5478" },
    { value: "I", label: "Divorciado", magentoValue: "5479" },
    { value: "J", label: "Separado Judicialmente", magentoValue: "5480" },
    { value: "P", label: "Separado", magentoValue: "5481" },
    { value: "S", label: "Solteiro", magentoValue: "5482" },
    { value: "U", label: "União Estável", magentoValue: "5483" },
    { value: "V", label: "Viúvo", magentoValue: "5484" },
    { value: "O", label: "Outros", magentoValue: "5485" }
  ];

  const scholarityOptions = [
    { value: "1", label: "Analfabeto", magentoValue: "5486" },
    { value: "2", label: "Até o 5º ano incompleto do ensino fundamental", magentoValue: "5487" },
    { value: "3", label: "5º ano completo do ensino fundamental", magentoValue: "5488" },
    { value: "4", label: "Do 6º ao 9º ano do ensino fundamental", magentoValue: "5489" },
    { value: "5", label: "Ensino fundamental completo", magentoValue: "5490" },
    { value: "6", label: "Ensino médio incompleto", magentoValue: "5491" },
    { value: "7", label: "Ensino médio completo", magentoValue: "5492" },
    { value: "8", label: "Educação superior incompleto", magentoValue: "5493" },
    { value: "9", label: "Educação superior completo", magentoValue: "5494" },
    { value: "A", label: "Pós Grad. incompleto", magentoValue: "5495" },
    { value: "B", label: "Pós Grad. completo", magentoValue: "5496" },
    { value: "C", label: "Mestrado incompleto", magentoValue: "5497" },
    { value: "D", label: "Mestrado completo", magentoValue: "5498" },
    { value: "E", label: "Doutorado incompleto", magentoValue: "5499" },
    { value: "F", label: "Doutorado completo", magentoValue: "5500" },
    { value: "G", label: "Pós Dout.incompleto", magentoValue: "5501" },
    { value: "H", label: "Pós Dout.completo", magentoValue: "5502" }
  ];

  const homestateOptions = [
    { label: 'AC', value: '5449' },
    { label: 'AL', value: '5450' },
    { label: 'AM', value: '5451' },
    { label: 'AP', value: '5452' },
    { label: 'BA', value: '5453' },
    { label: 'CE', value: '5454' },
    { label: 'DF', value: '5455' },
    { label: 'ES', value: '5456' },
    { label: 'GO', value: '5457' },
    { label: 'MA', value: '5458' },
    { label: 'MG', value: '5459' },
    { label: 'MS', value: '5460' },
    { label: 'MT', value: '5461' },
    { label: 'PA', value: '5462' },
    { label: 'PB', value: '5463' },
    { label: 'PE', value: '5464' },
    { label: 'PI', value: '5465' },
    { label: 'PR', value: '5466' },
    { label: 'RJ', value: '5467' },
    { label: 'RN', value: '5468' },
    { label: 'RO', value: '5469' },
    { label: 'RR', value: '5470' },
    { label: 'RS', value: '5471' },
    { label: 'SC', value: '5472' },
    { label: 'SE', value: '5473' },
    { label: 'SP', value: '5474' },
    { label: 'TO', value: '5475' }
  ];

  const handleChangeMaritalStatus = selectedOption => {
    setSelectedMaritalStatus(selectedOption);
    values.maritalStatus = selectedOption.magentoValue;
  }

  const handleChangeScholarity = selectedOption => {
    setSelectedScholarity(selectedOption);
    values.scholarity = selectedOption.magentoValue;
  }

  const handleChangeHomestate = (selectedOption) => {
    setSelectedHomestate(selectedOption);
    values.homestate = selectedOption.value;
  };

  const handleChangeUf = (selectedOption) => {
    setSelectedRgUf(selectedOption);
    values.rgUf = selectedOption.label;
  };

  return (
    <form onSubmit={handleSubmit} >
      <div className={classes.form}>
        <div className={classes.formColumn}>
          <Input
            placeholder='João'
            label='Nome'
            type='text'
            name='firstname'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstname}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            error={touched.firstname ? errors.firstname : null}
          />
          <Input
            placeholder='da Silva'
            label='Sobrenome'
            type='text'
            name='lastname'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastname}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            error={touched.lastname ? errors.lastname : null}
          />
          <Input
            placeholder='joao.silva@gmail.com'
            label='Email'
            type='text'
            name='email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            disabled
            error={touched.email ? errors.email : null}
          />
          <Input
            placeholder='123.458.854-89'
            label='CPF'
            type='text'
            name='cpf'
            mask={'999.999.999-99'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cpf}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            disabled
            error={touched.cpf ? errors.cpf : null}
          />
          <Input
            placeholder='Ex.: 123456789'
            label='RG'
            type='text'
            name='rg'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.rg}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            error={touched.rg ? errors.rg : null}
          />

          <Input
            label='UF'
            placeholder='Ex.: SC, RJ, SP...'
            select
            selectOptions={homestateOptions}
            type='text'
            name='rgUf'
            onChange={handleChangeUf}
            onBlur={handleBlur}
            value={selectedRgUf}
            customClasses={{ root: classes.input }}
            error={touched.rgUf ? errors.rgUf : null}
          />

          <Input
            placeholder='Ex.: SSP-CE, SSP-SP'
            label='Orgão expedidor'
            type='text'
            name='rgEmitter'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.rgEmitter}
            customClasses={{ root: classes.input }}
            error={touched.rgEmitter ? errors.rgEmitter : null}
          />

          <Input
            placeholder=''
            label='Data de Emissão'
            type='text'
            name='rgEmissionDate'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.rgEmissionDate}
            customClasses={{ root: classes.input }}
            mask={'99/99/9999'}
            error={touched.rgEmissionDate ? errors.rgEmissionDate : null}
          />

          <Input
            placeholder=''
            label='Data de nascimento'
            type='text'
            name='birthDate'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.birthDate}
            // className={classes.input}
            mask={'99/99/9999'}
            error={touched.birthDate ? errors.birthDate : null}
          />

          <FormControl component="fieldset">
            <FormLabel component="legend">Sexo*</FormLabel>
            <RadioGroup onChange={handleChangeRadio} value={values.gender.toString()} defaultValue="1" aria-label="gender" name="customized-radios" style={{ flexDirection: 'row', marginBottom: '1.6rem' }}>
              <FormControlLabel value="1" control={<StyledRadio />} label="Masculino" />
              <FormControlLabel value="2" control={<StyledRadio />} label="Feminino" />
            </RadioGroup>
          </FormControl>

          <Input
            placeholder='Ex.: SC, RJ, SP...'
            select
            selectOptions={homestateOptions}
            label='Estado natal'
            type='text'
            name='homestate'
            onChange={handleChangeHomestate}
            onBlur={handleBlur}
            value={selectedHomestate}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            error={touched.homestate ? errors.homestate : null}
          />

      {municipalities.length > 0 ?
        <Input
          placeholder=''
          select
          selectOptions={municipalities}
          label='Municipio/Cidade natal'
          type='text'
          name='municipality'
          onChange={handleChangeMunicipio}
          onBlur={handleBlur}
          value={selectedMunicipality}
          customClasses={{ root: classes.input }}
          error={touched.municipality ? errors.municipality : null}
        /> :
        <div disabled>
          <Input
            placeholder=''
            disabled
            selectOptions={municipalities}
            label='Municipio/Cidade natal'
            type='text'
            name='municipality'
            onChange={handleChangeMunicipio}
            onBlur={handleBlur}
            value={selectedMunicipality}
            customClasses={{ root: classes.input }}
            error={touched.municipality ? errors.municipality : null}
          />
        </div>
      }

          <Input
            placeholder='Ex.: Solteiro...'
            select
            selectOptions={maritalStatusOptions}
            label='Estado Civíl'
            type='text'
            name='maritalStatus'
            onChange={handleChangeMaritalStatus}
            onBlur={handleBlur}
            value={selectedMaritalStatus}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            error={touched.maritalStatus ? errors.maritalStatus : null}
          />
          <Input
            placeholder='Ex.: Ensino médio completo...'
            select
            selectOptions={scholarityOptions}
            label='Escolaridade'
            type='text'
            name='scholarity'
            onChange={handleChangeScholarity}
            onBlur={handleBlur}
            value={selectedScholarity}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            error={touched.scholarity ? errors.scholarity : null}
          />

          <Input
            label='Situação Ocupacional'
            placeholder='Ex.: Empregado, Desempregado, MEI ...'
            select
            selectOptions={situacaoOpcoes}
            type='text'
            name='situacaoOcup'
            onChange={handleChangeSituacaoOcupacional}
            onBlur={handleBlur}
            value={selectedSituacaoOcup}
            customClasses={{ root: classes.input }}
            error={touched.situacaoOcup ? errors.situacaoOcup : null}
          />

          <Input
            placeholder='Ana Maria da Silva'
            label='Nome da mãe'
            type='text'
            name='mothersName'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mothersName}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            error={touched.mothersName ? errors.mothersName : null}
          />
          <Input
            placeholder='(27) 99999-9999'
            label='Telefone'
            type='text'
            name='phone1'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone1}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            mask={'(99) 9 9999-9999'}
            error={touched.phone1 ? errors.phone1 : null}
          />
          <Checkbox
            size='medium'
            color='primary'
            inputProps={{ 'aria-label': 'quero receber ofertas' }}
            name='receiveNews'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.receiveNews}
            checked={values.receiveNews}
            className={classes.checkbox}
          />
          Quero receber ofertas e novidades por e-mail, WhastApp e contato telefônico.
        </div>
        <div className={classes.formColumn}>

        <Input
            placeholder='(27) 99999-9999'
            label='Segundo telefone (opcional)'
            type='text'
            name='phone2'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone2}
            // className={classes.input}
            customClasses={{ root: classes.input }}
            mask={'(99) 9 9999-9999'}
          />

          <Typography
            variant='subtitle2'
            align='left'
            className={classes.title}
          >
            Endereço
          </Typography>
          <Input
            placeholder='Ex: 11023-456'
            mask={'99999-999'}
            label='CEP*'
            type='text'
            name='cepBilling'
            helperText='Seu endereço aparecerá automaticamente.'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cepBilling}
            customClasses={{ root: classes.input }}
            error={cepError ? cepError : touched.cepBilling ? errors.cepBilling : null}
          />
          <Input
            label='Estado*'
            type='text'
            name='stateBilling'
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.stateBilling}
            customClasses={{ root: classes.input }}
            error={touched.stateBilling ? errors.stateBilling : null}
          />

          <Input
            label='Cidade*'
            type='text'
            name='cityBilling'
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cityBilling}
            customClasses={{ root: classes.input }}
            error={touched.cityBilling ? errors.cityBilling : null}
          />

          <Input
            label='Bairro*'
            type='text'
            name='neighbourhoodBilling'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.neighbourhoodBilling}
            customClasses={{ root: classes.input }}
            error={touched.neighbourhoodBilling ? errors.neighbourhoodBilling : null}
          />

          <Input
            label='Endereço*'
            type='text'
            name='addressBilling'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.addressBilling}
            customClasses={{ root: classes.input }}
            error={touched.addressBilling ? errors.addressBilling : null}
          />

          <Input
            label='Número*'
            type='text'
            name='addressNumberBilling'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.addressNumberBilling}
            customClasses={{ root: classes.input }}
            error={touched.addressNumberBilling ? errors.addressNumberBilling : null}
          />

          <Input
            label='Complemento'
            type='text'
            name='addressComplementBilling'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.addressComplementBilling}
            customClasses={{ root: classes.input }}
            error={touched.addressComplementBilling ? errors.addressComplementBilling : null}
          />
        </div>

      </div>
      <Button lowercase isLoading={isLoading} type='submit' width='100%'>
        Salvar alterações
      </Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Informações atualizadas com sucesso!
        </Alert>
      </Snackbar>
    </form>
  );
};

export default RegistrationForm;

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <RadioButtonCheckedRoundedIcon style={{ color: '#32559D' }} />
      }
      icon={<RadioButtonUncheckedRoundedIcon />}
      {...props}
    />
  );
}