import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import RegisterBoxForm from './RegisterBoxForm';
import { registerFormValidator } from './RegisterBoxForm.validator';

const LoginBoxFormWrapper = (props) => {
  const initialValues = {
    name: '',
    email: '',
    cnpj: props.history ? props.history.location.state.cpfCNPJ : '',
    password: '',
    passwordConfirmation: '',
    subscribeOffers: true, 
    cep: '', 
    address: '', 
    state:  '', 
    number:  '', 
    city: '', 
    complement: '', 
    industry: ''
  };

  const {
    register,
    history,
    redirectTo,
    isLoading,
    errors,
    setSignUpCompleted,
    setConfirmationEmail,
    afterRegister
  } = props;

  const handleSubmit = async (formValues, actions) => {
    try {
      const response = await register(formValues);
      if (response.status === 200) {
        setSignUpCompleted(true);
        setConfirmationEmail(formValues.email);
        actions.resetForm();
      }
      if (afterRegister) {
        afterRegister();
      }
    } catch (err) {}
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={registerFormValidator}
    >
      {(propsFormik) => (
        <RegisterBoxForm
          isLoading={isLoading}
          errors={errors}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default LoginBoxFormWrapper;
