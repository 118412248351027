import React, { useState } from 'react';
import createStyles from './EditUserInfoModal.styles';
import Modal from '@material-ui/core/Modal';
import EditProfileForm from './EditProfileForm';
import EditAddress from './EditAddress';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const EditUserInfoModal = (props) => {
  const classes = createStyles(props);
  const { open, handleClose, user } = props;

  const [activePaymentTab, setActivePaymentTab] = useState(0);
  const paymentTabs = ['Cadastro', 'Endereços'];

  const handleSubmit = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
      {/* <DialogTitle id='form-dialog-title'>Subscribe</DialogTitle> */}
      <DialogContent>
        <ul className={classes.paymentOptionsTabs}>
          {paymentTabs.map((tab, index) => {
            return (
              <li
                key={index}
                onClick={() => setActivePaymentTab(index)}
                className={
                  index == activePaymentTab
                    ? classes.activeTab
                    : classes.nonActiveTab
                }
              >
                {tab}
              </li>
            );
          })}
        </ul>
        {activePaymentTab === 0 ? <EditProfileForm /> : <EditAddress />}
      </DialogContent>
      <ClearIcon className={classes.clearIcon} onClick={handleClose} />
    </Dialog>

    // <Modal open={open} onClose={handleClose} className={classes.modal}>
    //   <div className={classes.paper}>
    //     <ClearIcon className={classes.clearIcon} onClick={handleClose} />
    //     <ul className={classes.paymentOptionsTabs}>
    //       {paymentTabs.map((tab, index) => {
    //         return (
    //           <li
    //             onClick={() => setActivePaymentTab(index)}
    //             className={
    //               index == activePaymentTab
    //                 ? classes.activeTab
    //                 : classes.nonActiveTab
    //             }
    //           >
    //             {tab}
    //           </li>
    //         );
    //       })}
    //     </ul>
    //   </div>
    // </Modal>
  );
};

export default EditUserInfoModal;
