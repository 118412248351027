import React from 'react';
import createStyles from './ChangeEmailModal.styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import ChangeEmailForm from './Form/ChangeEmailForm.formik';
import ClearIcon from '@material-ui/icons/Clear';
import SuccessModal from '../SuccessModal';
import ForgotPasswordModal from 'components/AuthenticationBox/LoginBox/ForgotPasswordModal'

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ChangeEmailModal = (props) => {
  const classes = createStyles(props); 

  const {
    handleEmailChange,
    isEditing,
    errors,
    afterEmailChange, 
  } = props;


  const [ isSuccess, setSuccess ] = React.useState(false);
  const [ recoverPass, setOpenRecoverPass ] = React.useState(false);
  const [ modalStyle ] = React.useState(getModalStyle);


  const handleClose = () => {
    setSuccess(true);
    props.handleClose();
  }

  const handleCloseSuccess = () => {
    setSuccess(false);
  }

  
  const body = (
    <div style={modalStyle} className={classes.paper}>
       <ClearIcon className={classes.clearIcon} onClick={props.handleClose}/>
      <div className={classes.frame}>
        <Typography className={classes.title} variant="subtitle1">Alterar Email</Typography>
        <Typography className={classes.subtitle} variant="subtitle2">Insira abaixo o seu novo email. Ative o novo email pelo link de confirmação que será enviado para o mesmo.</Typography>
        
        <ChangeEmailForm 
          handleClose={handleClose}
          handleEmailChange={handleEmailChange}
          isLoading={isEditing}
          errors={errors}
          afterEmailChange={afterEmailChange}
          handleOpenModal={setOpenRecoverPass}/>
      </div>

      <ForgotPasswordModal
          open={recoverPass}
          handleClose={() => {setOpenRecoverPass(false)}}
        />              

    </div>
  );

  return (
      <div>
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>

        <SuccessModal open={isSuccess} 
                      height="403px"
                      handleClose={handleCloseSuccess} 
                      buttonTitle="ENTENDI" 
                      title="Email enviado!"/>

      </div>
  );
};


export default ChangeEmailModal;
