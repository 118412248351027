const logger = store => next => action => {
  const { type, payload, meta } = action;

  if (process.env.NODE_ENV !== 'production' && action.type) {
    console.groupCollapsed(type);
    console.info('dispatching', action);
    console.info('Payload:', payload);
    console.info('Meta:', meta);
    console.info('next state', store.getState());
    console.groupEnd();
  }

  return next(action);
};
export default logger;
