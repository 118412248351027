import { getMyPayments } from 'api/PaymentMethod';

// Actions
const FETCH_MYPAYMENTS_START = 'sesi/login/FETCH_MYPAYMENTS_START';
const FETCH_MYPAYMENTS_SUCCESS = 'sesi/login/FETCH_MYPAYMENTS_SUCCESS';
const FETCH_MYPAYMENTS_FAILURE = 'sesi/login/FETCH_MYPAYMENTS_FAILURE';
const MYPAYMENTS_RESET = 'sesi/login/MYPAYMENTS_RESET';

// Reducer
const initialState = {
  myPayments: [],
  isLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_MYPAYMENTS_START:
      return { ...state, isLoading: true };
    case FETCH_MYPAYMENTS_SUCCESS:
      return { ...state, myPayments: action.payload.reverse() || [], isLoading: false };
    case FETCH_MYPAYMENTS_FAILURE:
      return { ...state, ...action.payload };
    case MYPAYMENTS_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// Action Creators
export function fetchMyPaymentsStart() {
  return { type: FETCH_MYPAYMENTS_START };
}

export function fetchMyPaymentsSuccess(data) {
  return { type: FETCH_MYPAYMENTS_SUCCESS, payload: data };
}

export function fetchMyPaymentsFailure(data) {
  return { type: FETCH_MYPAYMENTS_FAILURE, payload: data };
}

export function reset() {
  return { type: MYPAYMENTS_RESET };
}

// side effects, only as applicable
export const fetchMyPayments = () => {
  return async dispatch => {
    dispatch(fetchMyPaymentsStart());

    try {
      const response = await getMyPayments();
      dispatch(fetchMyPaymentsSuccess(response));
    } catch (error) {
      dispatch(fetchMyPaymentsFailure(error));
    }
  };
};
