import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: (props) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: '1.6rem',
    backgroundColor: theme.colors.white,
    boxShadow: '0px 1px 6px 0px rgba(0,0,0,0.25)',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'all 150ms ease-in-out',
    borderRadius: '0px',
    position: 'relative',
    '&:hover': {
      boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.25)',

      '& $image': {
        transform: 'scale(1.03)'
      }
    },
    '@media (max-width: 950px)': {
      flexDirection: 'column'
    }
  }),
  originalPrice: {
    textAlign: 'right',
    textDecoration: 'line-through'
  },
  imageContainer: {
    boxSizing: 'border-box',
    width: '13.6rem',
    height: '13.6rem',
    display: 'block',
    cursor: 'pointer',
    overflow: 'hidden',
    '@media (max-width: 950px)': {
      width: '100%',
      marginBottom: '1.6rem'
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'all 200ms ease-in-out',

    '&:hover': {
      filter: 'brightness(90%)'
    }
  },

  infoContainer: {
    boxSizing: 'border-box',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    paddingLeft: '1.6rem',
    '@media (max-width: 950px)': {
      paddingLeft: '0'
    }
  },
  lowerInfo: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',

    '@media (max-width: 950px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  title: {
    margin: '0',
    color: theme.colors.text.dark,
    fontSize: '1.75rem',
    marginBottom: '1.85em',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  detailsList: {
    margin: '0',
    marginLeft: '-1rem',
    listStyle: 'none',
    padding: '0',
    marginBottom: '0rem',
    color: theme.palette.text.secondary,
    '@media (max-width: 950px)': {
      marginBottom: '1.6rem'
    },
  },

  detail: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '0.6rem'
    },
    '& p': {
      fontSize: '1.3rem',
      margin: '0',
      display: 'inline-block',
      marginLeft: '1rem'
    }
  },
  status: (props) => ({
    '&:before': {
      content: '""',
      display: 'inline-block',
      marginRight: '.8rem',
      width: '1rem',
      height: '1rem',
      borderRadius: '2rem',
      backgroundColor:
        props.status == 1
          ? theme.colors.purpleBlog
          : props.status == 2
          ? theme.colors.cyanYou
          : props.status == 3
          ? theme.colors.yellowHealthCare
          : props.status == 4
          ? theme.colors.blueCompany
          : theme.colors.divider
    }
  }),

  noClassesAvailable: {
    textAlign: 'center',
    width: '100%',
    padding: '3rem 0'
  },

  deleteIcon: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    cursor: 'pointer',
    color: theme.colors.background.bg65
  },

  priceContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    right: '2rem'
  },
  originalPrice: {
    textAlign: 'right',
    textDecoration: 'line-through'
  },
  price: {
    color: (props) =>
      props.discount > 0 ? theme.colors.cyanYou : theme.colors.blueCompany,
    fontSize: '2.6rem',
    textAlign: 'right',
    fontWeight: 'bold'
  },
  paymentConditions: {
    marginBottom: '2rem'
  },
  span: {
    display: 'inline',
    fontSize: '1.4rem'
  }
}));

export default createStyles;
