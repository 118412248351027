import ChangePasswordModal from './ChangePasswordModal.jsx';
import { connect } from 'react-redux';
import { handlePasswordChange } from 'modules/Registration/Registration.ducks.js';

const mapStateToProps = state => {
  return {
    isEditing: state.userRegistration.isEditing,
    errors: state.userRegistration.errors
  };
};

const mapDispatchToProps = {
  handlePasswordChange
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);

