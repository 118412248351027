import React, { useState, useEffect } from 'react';
import { Typography, Checkbox, InputLabel, List, ListItem, Box } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import ButtonPrimary from 'components/ButtonPrimary';

import createStyles from './UnitForm.styles';
import { addThousandDot } from 'shared/util/textTransformation.js';
import { Link } from 'react-router-dom';

const UnitForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isLoading,
    externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
    totals,
    products
  } = props;

  const formatPrice = (value) => {
    if (value)
      return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    else return '-';
  };

  function priceTotal() {
    const rawPrice = totals.totalSegments.filter((segment) => segment.code === "grand_total")[0]
    var valor = parseFloat(rawPrice.value)
    return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  }

  return (
    <div className={classes.unitFormRoot}>
      <Typography className={classes.totalPrice} variant='subtitle2'>
        {priceTotal()}
      </Typography>
      <Box>
        {/* Introdução */}
        <Typography variant="h6" gutterBottom>
          Informações sobre pagamento e matrícula
        </Typography>

        <Typography variant="body1" paragraph>
          O boleto de matrícula será enviado por e-mail. Nosso prazo de envio é de até <strong>2 dias úteis </strong>
          após a confirmação do pedido e o recebimento dos documentos de matrícula.
        </Typography>

        {/* Opções de pagamento */}
        <Typography variant="body1" paragraph>
          Também é possível realizar o pagamento e entrega dos documentos presencialmente. Para isso, basta comparecer à unidade de matrícula
          portando um dos métodos aceitos:
        </Typography>

        <List>
          <ListItem>
            <Typography variant="body1">
              <strong>CARTÃO - DÉBITO OU CRÉDITO</strong> (bandeiras: VISA, MASTERCARD e ELO)
            </Typography>
          </ListItem>
        </List>

        {/* Documentos comprobatórios */}
        <Typography variant="body1" paragraph>
          Caso seja trabalhador da indústria ou de empresas associadas a um dos sindicatos patronais e/ou filiados à Findes,
          será necessário apresentar uma via original de um dos documentos abaixo para comprovar o vínculo empregatício.
          Serão aceitos como documentos comprobatórios:
        </Typography>

        <List>
          <ListItem>
            <Typography variant="body1">• Contracheque atualizado (dos últimos 90 dias)</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">• Carteira de Trabalho e Previdência Social (CTPS) física ou digital</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">• Pró-labore (em caso de MEI)</Typography>
          </ListItem>
        </List>

        {/* Link para dúvidas */}
        <Typography variant="body1" paragraph>
          Em caso de dúvidas sobre a política de descontos e porcentagens, confira os descontos concedidos por perfil de público,
          no item 10 do link:
        </Typography>

        <Typography variant="body1" paragraph>
          <a href="/perguntas-frequentes" target="_blank">Perguntas Frequentes (FAQ)</a>
        </Typography>

        {/* Aviso sobre formas de pagamento */}
        <Typography variant="body2" color="textSecondary" paragraph>
          <strong>*Reforçamos que pagamentos em dinheiro ou PIX não serão aceitos.</strong>
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <div className={classes.actions}>
          <Checkbox
            size='medium'
            color='primary'
            name='useTerms'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.useTerms}
          />
          <Typography
            className={classes.checkboxLabel}
            variant='body2'
            color='textPrimary'
          >
            Li e concordo com as <a href="/politicas" target="_blank">políticas do curso</a>
          </Typography>
        </div>

        <div className={classes.actions}>
          <ButtonPrimary type='submit' isLoading={isLoading} disabled={!isValid} lowercase>
            Confirmar
          </ButtonPrimary>
        </div>

        {externalErrors && (
          <FormHelperText className={classes.errorsText} error>
            {externalErrors}
          </FormHelperText>
        )}
      </form>
    </div>
  );
};

export default UnitForm;
