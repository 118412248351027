import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';
import { getPoliticaComprasMock } from 'mock/Politicas';

export const getPoliticaCompras = async () => {
    try {
      const response = await request.get('/legal/policies');
      return response.data;
    } catch (error) {
      //TODO
    }
};