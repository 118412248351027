import React from 'react';
import CartItems from './CartItems';
import CartAuthentication from './CartAuthentication/CartAuthentication.connect';
import CartConfirmation from './CartConfirmation/CartConfirmation.connect';
import CartPayment from './CartPayment/CartPayment.connect';
import ErrorBoundaryRoute from 'shared/error/ErrorBoundaryRoute';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotFound from 'shared/error/NotFound/NotFound.view';

import createStyles from './CartBody.styles';

const CartBody = (props) => {
  const { isLoading, activeStep, handleNext, saleSuccess, ...rest } = props;
  const classes = createStyles(props);
  return (
    <Switch>
      <ErrorBoundaryRoute
        exact
        path='/carrinho/itens'
        component={() => <CartItems handleNext={handleNext} />}
      />
      <ErrorBoundaryRoute
        exact
        path='/carrinho/auth'
        component={() => <CartAuthentication handleNext={handleNext} />}
      />
      <ErrorBoundaryRoute
        exact
        path='/carrinho/pagamento'
        component={() => <CartPayment handleNext={handleNext} {...rest} />}
      />
      <ErrorBoundaryRoute
        exact
        path='/carrinho/confirmacao'
        component={() => <CartConfirmation handleNext={handleNext} saleSuccess={saleSuccess} />}
      />
      <Redirect to='/carrinho/itens' />
    </Switch>
  );
};

export default CartBody;
