import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  cartItemsRoot: {
    display: 'flex',
    margin: '3rem 0 8rem 0',
    '@media(max-width:1100px)': {
      flexDirection: 'column'
    }
  },
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rootInnerLoading: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  orderResumeContainer: {
    width: '40%',
    boxSizing: 'border-box',
    padding: '1.6rem',
    backgroundColor: theme.colors.white,
    boxShadow: '0px 1px 6px 0px rgba(0,0,0,0.25)',
    marginLeft: '3rem',
    '@media(max-width:1100px)': {
      width: '100%',
      margin: 0
    }
  },
  orderResumeTitle: {
    marginBottom: '2rem'
  },
  cartItemsContainer: {
      width: '60%',
      '@media(max-width:1100px)': {
        width: '100%',
        margin: 0
    }
  },
  resumeSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  reumePriceSection: {
    borderBottom: `1px solid ${theme.colors.divider}`,
    paddingBottom: '2rem'
  },
  vouchersContainer: {
    borderBottom: `1px solid ${theme.colors.divider}`,
    padding: '0 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  voucherItem: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    color: theme.colors.blueCompany,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  priceSectionContainer: {
    marginTop: '3rem'
  },
  resumeItemSection: {
    marginBottom: '3rem'
  },
  continueBtn: {
    margin: '2rem auto 0rem auto',
    padding: '1rem 4rem',
    display: 'flex'
  },
  course: {
    marginBottom: '1.6rem'
  },
  voucherItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  voucherPriceNumber: {
    margin: '0 1rem',
    whiteSpace: 'nowrap'
  },
  header: {
    background: '#32559D',
    padding: '25px', 
    display: 'flex', 
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center', 
    width: '50%',
    margin: '50px auto',
    '& > svg': {
      color: '#fff',
      margin: '0 22px'
    },
    '& > p': {
      color: '#fff', 
      fontSize: '30px', 
      lineHeight: '42px'
    },
  }, 
}));

export default createStyles;
