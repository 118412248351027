import courseImage from 'assets/images/courseImage.jpg';
import gallery1 from 'assets/images/mock/gallery1.jpg';
import gallery2 from 'assets/images/mock/gallery2.jpg';
import gallery3 from 'assets/images/mock/gallery3.jpg';
import banner from 'assets/images/mock/gallery1.jpg';
import card from 'assets/images/mock/gallery1.jpg';

export const getCourseDetailsMock = () => {
  return {
    course: {
      image: card,
      bannerImage: banner,
      areaTag: 'Área de Aprendizagem',
      title: 'Instalador Mecânico de Máquina de Costura Ponto Corrente e Overlock',
      description: 'Proporcionar o desenvolvimento das capacidades técnicas, sociais, metodológicas e organizativas referente à qualificação profissional em Mecânico de Máquina de Costura.',
      isFavorite: false,
      details: [{
        title: "Objetivo",
        content: [{
          content: ["Preparar o aluno para realizar a manutenção preventiva e corretiva em máquinas de costura de ponto fixo, ponto corrente e overlock incluindo seus respectivos aparelhos e acessórios adequando-os ao produto e ao material, tendo em vista a melhoria contínua da qualidade e da produtividade, de acordo com as normas e os procedimentos técnicos de qualidade, segurança, higiene e saúde."]}]
      },
      {
        title: "Conteúdo",
        content: [{
          title: 'Seção 1',
          content: ["Curabitur vitae enim nec nunc mattis tincidunt."]
        },
        {
          title: 'Seção 2',
          content: ["Etiam euismod auctor finibus.",
            "Vestibulum suscipit dolor vel mi ultricies"]
        },
        {
          title: 'Seção 3',
          content: ["Curabitur vitae enim nec nunc mattis tincidunt.",
            "Etiam euismod auctor finibus.",
            "Vestibulum suscipit dolor vel mi ultricies"]
        }]
      },
      {
        title: "Pré-Requisitos",
        content: [{
          content: ["Possuir Ensino Fundamental – completo",
          "Ter no mínimo 16 anos completos"]
        }]
      },
      {
        title: "Informações Importantes",
        content: [{
          title: 'Documentos necessários para matrícula: ',
          content: ["Cópia simples do RG e CPF.",
            "Para candidatos menores de 18 (dezoito) anos, será exigida apresentação de um responsável legal portando cópia do RG e CPF."]
        },
        {
          title: 'Formas de Pagamento',
          content: ["Cartões de crédito e débito - VISA, ELO e MASTERCARD",
            "Dinheiro"]
        },
        {
          title: 'Formas de Pagamento',
          content: ["Certificados só serão emitidos para cursos com carga horária igual ou superior a 08 horas.",
            "As datas de início dos cursos poderão sofrer alterações, pois o SESI se reserva do direito de somente começar um curso com a quantidade mínima de 12 alunos matriculados.",
          ]
        }
        ]
      }
      ],
      courseClasses: [
        {
          id: 0,
          name: 'Turma A',
          place: 'Jardim da Penha - Vitória',
          duration: '4 Horas',
          date: '03/08 - 13h às 17h',
          openChairs: 20
        },
        {
          id: 1,
          name: 'Turma B',
          place: 'Cariacica',
          duration: '4 Horas',
          date: '07/09 - 8h às 12h',
          openChairs: 20
        },
        {
          id: 2,
          name: 'Turma C',
          place: 'Linhares',
          duration: '4 Horas',
          date: '20/08 - 18h às 22h',
          openChairs: 20
        },
      ],
      price: 200,
      discount: 50,
      priceWithDiscount: 100,
      parcels: 2,
      gallery: [
        {
          id: 1,
          image: gallery1
        },
        {
          id: 2,
          image: gallery2
        },
        {
          id: 3,
          image: gallery3
        }
      ],
    },
    relatedCourses: [
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 100,
        discount: 25,
        priceWithDiscount: 75,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 100,
        discount: 30,
        priceWithDiscount: 34.93,
        parcels: 2,
        link: '/teste',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: 'Aliquam justo sapien.',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 100,
        discount: 30,
        priceWithDiscount: 34.93,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 49.9,
        discount: 30,
        priceWithDiscount: 34.93,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 49.9,
        discount: 30,
        priceWithDiscount: 34.93,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 49.9,
        discount: 30,
        priceWithDiscount: 34.93,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 49.9,
        discount: 30,
        priceWithDiscount: 34.93,
        parcels: 2,
        link: '#',
        isFavorite: false
      }
    ],
    navigationHistory: [
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        
        link: '/teste',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: 'Aliquam justo sapien.',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        
        link: '#',
        isFavorite: false
      }
    ]
  };
};