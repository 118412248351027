import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  forgotPasswordLabel: {
    color: theme.colors.blueCompany
  },
  rememberMeLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginBottom: '1.6rem'
  },
  form: {
    marginTop: '2.4rem'
  },
  rememberMeCheckbox: {
    paddingLeft: 0
  },
  errorsText: {
    margin: '-1rem 0 2rem 0'
  }
}));

export default createStyles;
