import React from 'react';
import AuthenticationBox from 'components/AuthenticationBox';
import createStyles from './PageContent.styles';

const PageContent = (props) => {
  const classes = createStyles();

  const { children, isLoginBoxOpen, authBoxPosition, history } = props;

  return (
    <div className={classes.rootPageContent}>
      {children}
      <AuthenticationBox
        history={history}
        isModal
        isLoginBoxOpen={isLoginBoxOpen}
        authBoxPosition={authBoxPosition}
      />
    </div>
  );
};

export default PageContent;
