import MyAccount from './MyAccount.view';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    user: state.session.user
    // category: state.category
  };
};

const mapDispatchToProps = {
  // fetchCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
