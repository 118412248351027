export const toUrlFriendly = (srcString) => {
  if (!srcString) return;

  return srcString
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .split(' ')
    .join('-');
};

// Adiciona o ponto de milhar em um número (ex.: 6000,00 -> 6.000,00)
export const addThousandDot = (str) => {
  let newStr = str;
  if (str.length > 6) {
    newStr = str.slice(0, -6) + '.' + str.slice(-6);
  }
  return newStr;
};
