import React from 'react';
import PropTypes from 'prop-types';
import createStyles from './Banner.styles';

const Banner = props => {
  const classes = createStyles(props);

  if(props.isCategory){
    return (
      <div className={classes.rootImage}>
        {props.layer && <div className={classes.layer}></div>}
        <div className={classes.textImage} backgroundImage={props.backgroundImage}>
          {props.title && <h1>{props.title}</h1>}
          {props.description && <p>{props.description}</p>}
        </div>
        
      </div>
    );
  }
  return (
    <div className={classes.root}>
      {props.layer && <div className={classes.layer}></div>}
      <img alt='Banner Categoria' className={props.opacity && classes.opacity} src={props.backgroundImage} />
      <div className={classes.text}>
        {props.title && <h1 className={classes.title}>{props.title}</h1>}
        {props.description && <p>{props.description}</p>}
      </div>
      
    </div>
  );
};

Banner.propTypes = {
  backgroundImage: PropTypes.string
};

export default Banner;
