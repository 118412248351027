import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  breadcrubs: {
    padding: '0 135px'
  },
  header: {
    display:'flex', 
    flexDirection: 'row',
    margin: '1rem 0',
    alignSelf: 'flex-start', 
    position: 'relative', 
  }, 
  title: {
    fontFamily: 'Roboto', 
    fontWeight: 500, 
    fontSize: 26, 
    color: theme.colors.blueCompany, 
    letterSpacing: '-0.04em', 
    margin: '0 2.5rem 0 0'
  }, 
  container: isMobile => ({
    display: 'flex', 
    flexDirection: 'row',
    padding: 0,
    marginBottom: '8rem',
    position: 'relative',
  }),
  menu: {
    display: 'flex', 
    flexDirection: 'column', 
    padding: 0,
    width: '20rem', 
    alignSelf: 'flex-start', 
    borderRadius: 0,
    boxShadow: 'none'
  },
  menuList: {
    paddingTop: '0px'
  },
  selectedMenu: isMobile => ({
    display: 'flex',
    flexDirection: 'column', 
    padding: 0,
    marginLeft: isMobile ? 0 : '3rem', 
    alignSelf: 'flex-start',
    width: '100%',
  }),
  menuItem: {
    borderRight: '1px solid rgba(0, 0, 0, 0.08)'
  },
  logout: {
    position: 'absolute', 
    width: 180, 
    height: 19, 
    left: 16, 
    top: '114%', 
    fontFamily: 'Roboto', 
    fontSize: 16, 
    lineHeight: 19, 
    display: 'flex', 
    alignItems: 'center', 
    cursor: 'pointer'
  }, 
  responsiveMenu: {
    width: 38, 
    height: 38, 
    
  }, 
  responsiveMenuButton: {
    width: 38, 
    height: 38, 
    minWidth: 38, 
    borderRadius: '100%'
  }
}));

export default createStyles;
