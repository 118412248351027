import { getCategory } from 'api/Category';

// Actions
const FETCH_CATEGORY_START = 'sesi/login/FETCH_CATEGORY_START';
const FETCH_CATEGORY_SUCCESS = 'sesi/login/FETCH_CATEGORY_SUCCESS';
const FETCH_CATEGORY_FAILURE = 'sesi/login/FETCH_CATEGORY_FAILURE';
const CATEGORY_RESET = 'sesi/login/CATEGORY_RESET';

// Reducer
const initialState = {
  banner: [],
  cities: [],
  isLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_CATEGORY_START:
      return { ...state, isLoading: true };
    case FETCH_CATEGORY_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case FETCH_CATEGORY_FAILURE:
      return { ...state, ...action.payload };
    case CATEGORY_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// Action Creators
export function fetchCategoryStart() {
  return { type: FETCH_CATEGORY_START };
}

export function fetchCategorySuccess(data) {
  return { type: FETCH_CATEGORY_SUCCESS, payload: data };
}

export function fetchCategoryFailure(data) {
  return { type: FETCH_CATEGORY_FAILURE, payload: data };
}

export function reset() {
  return { type: CATEGORY_RESET };
}

// side effects, only as applicable
export const fetchCategory = (category) => {
  return async dispatch => {
    dispatch(fetchCategoryStart());

    try {
      const response = await getCategory(category);
      dispatch(fetchCategorySuccess(response));
    } catch (error) {
      dispatch(fetchCategoryFailure(error));
    }
  };
};
