import React, { useEffect, useState, useRef } from 'react';
import createStyles from './Registration.styles';
import { Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import RegistrationForm from './FormPF/RegistrationForm.formik';
import RegistrationFormPJ from './FormPJ/RegisterBoxForm.formik';
import ChangePasswordModal from 'modules/Registration/ChangePasswordModal';
import ChangeEmailModal from 'modules/Registration/ChangeEmailModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonPrimary from 'components/ButtonPrimary';
import Alert from 'components/Alert';


const Registration = props => {

  const {
    fetchUserRegistration,
    user,
    isLoading, 
    isLoadingSession, 
    isEditing, 
    handleEditUserRegistration, 
    status,
    success
   } = props;

  const [isOpen, setOpenModal] = useState(false);
  const [emailChange, setOpenEmailChange] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const rootRef = useRef(null);

  const classes = createStyles(isMobile);

  useEffect(() => {
    const updateWidth = () => {
      if (rootRef.current) {
        setIsMobile(rootRef.current.offsetWidth < 720 ? true : false)
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  useEffect(() => {
    fetchUserRegistration();
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  }

  const handleCloseEmailModal = () => {
    setOpenEmailChange(false);
  }

  return (
    <React.Fragment >
      {isLoadingSession ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
          <div ref={rootRef} className={classes.registrationContent}>

            {status == "success" && 
              <Alert
              severity="success"
              message="Alterações salvas com sucesso!"/>
            }
              
            <div className={classes.titleContainer}>
              <Typography variant='h3' className={classes.title}>
                Cadastro
              </Typography>
              <div className={classes.buttonContainer}>
                <ButtonPrimary
                  className={classes.button}
                  variant='outlined'
                  buttonText="Alterar email"
                  onClick={setOpenEmailChange}
                />
                <ButtonPrimary
                  className={classes.button}
                  variant='outlined'
                  buttonText="Alterar senha"
                  onClick={setOpenModal}
                />
              </div>
            </div>

            <section className={classes.cardContainer}>
              <Card className={classes.registerCard}>
                <CardContent classes={{root: classes.cardContent}}>
                  {user.cpf ? 
                       <RegistrationForm 
                       userData={user} 
                       editUserRegistration={handleEditUserRegistration}
                       isLoading={isEditing}
                       success={success}
                       />
                    :

                      <RegistrationFormPJ
                        userData={user}
                        editUserRegistration={handleEditUserRegistration}
                        isLoading={isEditing}
                      />
                
                  }
                 
                </CardContent>
              </Card>
            </section>


            <ChangePasswordModal open={isOpen} handleClose={handleClose} />
            <ChangeEmailModal open={emailChange} handleClose={handleCloseEmailModal} />
          </div>
        )
      }

    </React.Fragment>
  );
};

export default Registration;
