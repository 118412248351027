import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'App.theme.js';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import createStyles from './CarouselGallery.styles';
import Draggable from 'react-draggable';
import DotIndicators from 'components/DotIndicators';

const CarouselBanner = props => {
  const [visibleSlides] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidingAmount, setSlidingAmount] = useState(0);
  const [disableButton, setDisableButton] = useState('left');
  const [transition, setTransition] = useState('all 500ms ease-in-out');
  const [nextSlide, setNextSlide] = useState(0);

  const slides = props.imageList.length;
  const classes = createStyles(props);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (slides - nextSlide < visibleSlides) {
      setCurrentSlide(slides - visibleSlides);
    } else if (nextSlide < 0) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(nextSlide);
    }

    if (currentSlide === 0 && slides > visibleSlides) {
      setDisableButton('left');
    } else if (currentSlide === slides - visibleSlides  && slides > visibleSlides) {
      setDisableButton('right');
    } else if (slides <= visibleSlides) {
      setDisableButton('left right');
    } else {
      setDisableButton('');
    }

    setSlidingAmount((currentSlide * 100) / slides);
  });
  function handleStart() {
    setTransition('');
  }
  function handleStop(e, data) {
    setTransition('all 500ms ease-in-out');
    let slidePercent = 100 / slides;
    let percentX;
    let trayWidth = (window.innerWidth * slides) / visibleSlides;
    if (data.x > 0) {
      percentX = (100 * data.x) / trayWidth - slidingAmount;
    } else {
      percentX = (100 * data.x) / trayWidth - slidingAmount - slidePercent;
    }

    let newSlide = Math.floor(-percentX / slidePercent);
    setNextSlide(newSlide);
  }

  return (
    <div className={classes.carousel}>
      {!isMobile && (
        <div className={classes.buttonsContainer}>
        <div className={classes.buttonsContainerInner}>
          <button
            className={classes.button}
            onClick={() => setNextSlide(currentSlide - visibleSlides)}
            disabled={disableButton.includes('left') ? true : false}
            >
            <ChevronLeftIcon />
          </button>
          <button
            className={classes.button}
            onClick={() => setNextSlide(currentSlide + visibleSlides)}
            disabled={disableButton.includes('right') ? true : false}
            >
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      )}
      <div className={classes.container}>
        <Draggable
          axis='x'
          handle={`.${classes.tray}`}
          position={{ x: 0, y: 0 }}
          positionOffset={{ x: -slidingAmount + '%', y: 0 }}
          scale={1}
          onStart={handleStart}
          onStop={handleStop}
        >
          <div
            className={classes.tray}
            style={{
              width:
                ((props.imageList.length / visibleSlides) * 100).toString() +
                '%',
              transition: transition
            }}
          >
            {props.imageList.map(banner => {
              return (
                <div className={classes.slide} key={banner.id}>
                  <img className={classes.image} alt='Imagem de fundo' src={banner.image} />
                </div>
              );
            })}
          </div>
        </Draggable>
          <div className={classes.dotIndicators}>
            <DotIndicators 
              slides={slides}
              currentSlide={currentSlide}
              visibleSlides={visibleSlides}
              />
          </div>
      </div>
    </div>
  );
};

export default CarouselBanner;
