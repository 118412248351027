import Search from './Search.view';
import { connect } from 'react-redux';
import { search, getCities } from 'modules/Search/Search.ducks.js';
import { openLoginBox, closeLoginBox } from 'modules/Login/Login.ducks.js';

const mapStateToProps = state => {
  return {
    ...state.search,
    searchFilters: state.search.filters,
    totalSearchResults: state.search.totalSearchResults,
    cities: state.search.cities
  };
};

const mapDispatchToProps = {
  search,
  getCities,
  openLoginBox, closeLoginBox
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
