import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  paginationContainer: {
    listStyle: 'none',
    display: 'flex',
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  paginationListItem: {
    cursor: 'pointer',
    width: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pageLinkClassName: {
    cursor: 'pointer',
    width: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  activePage: {
    backgroundColor: theme.colors.blueCompany,
    color: 'white',
    width: '3rem',
    height: '3rem'
  },
  activePageLink: {
    outline: 'none'
  },
  previousLabel: {
    marginRight: '1.5rem',
    border: `1px solid ${theme.colors.background.bg65}`,
    cursor: 'pointer'
  },
  previousLinkClassName: {
    display: 'flex',
    width: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nextLabel: {
    marginLeft: '1.5rem',
    border: `1px solid ${theme.colors.background.bg65}`,
    cursor: 'pointer'
  },
  nextLinkClassName: {
    display: 'flex',
    width: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  breakClassName: {
    display: 'flex',
    width: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  disabledClassName: {
    border: `1px solid ${theme.colors.background.bg30}`,
    color: theme.colors.background.bg30
  }
}));

export default createStyles;
