export const getCustomAttribute = (product, customAttributeName) => {
  let value = '';
  if (product) {
    const customAttributes = product.custom_attributes;
    if (customAttributes) {
      customAttributes.map((attribute) => {
        if (attribute.attribute_code == customAttributeName) {
          value = attribute.value;
        }
      });
    }
  }

  return value;
};
