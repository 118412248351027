import LoginBox from './LoginBox.jsx';
import { connect } from 'react-redux';
import { login } from 'modules/Login/Login.ducks.js';

const mapStateToProps = (state) => {
  return {
    isLoading: state.session.isLoading,
    isAuthenticated: state.session.isAuthenticated,
    externalErrors: state.session.externalErrors
  };
};

const mapDispatchToProps = {
  login
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginBox);
