import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      backgroundColor: props => props.backgroundColor,
      '@media (min-width: 800px)': {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridTemplateRows: 'auto',
      },
    },

    imageContainer:{
      order: props => props.textFirst? 2 : 1,
      width: '100%',
      height: '100%',
      '@media (max-width: 799px)': {
        order: props => props.mobileTextFirst? 2 : 1,
      },
    },

    image:{
      minHeight: '100%',
      width: '100%',
      objectFit: 'cover',
    },

    textContainer:{
      order: props => props.textFirst? 1 : 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '80%',
      height: '80%',
      padding: '10%',
      '@media (max-width: 799px)': {
        order: props => props.mobileTextFirst? 1 : 2,
      },
      '@media (min-width: 800px)': {
        width: '70%',
        height: '56%',
        padding: '15%',      
      },
    },

    title: {
      marginTop: '0',
      width: '100%',
      textTransform: 'uppercase',
      fontSize: '2.6rem',
      color: theme.colors.blueCompany,

    },
    text:{
      fontSize: '1.6rem',
      color: 'rgba(0, 0, 0, .65)',
      marginBottom: '2.5rem',



    },
  }));

  export default createStyles;