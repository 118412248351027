import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
    drawerMenuList: {
      display: 'flex',
      flexDirection: 'column',
      width: 360,
      backgroundColor: theme.palette.background.paper,
    },
    menuMobile: {
      alignSelf: 'flex-end',
    },
    listTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
      marginLeft: '1.1rem',
      color: theme.colors.blueCompany
    },
    titleIcon: {
      marginRight: '.5rem',
    },
    listItem: {
      paddingLeft: '2.5rem',
    },
    divider: {
      marginBottom: '1.5rem'
    }
  }));

  export default createStyles;