import * as Yup from 'yup';
import { validate as validateCPF } from 'gerador-validador-cpf';

const EditProfileForm = {
  email: Yup.string().email().required('Este campo é obrigatório'),
  gender: Yup.string().required('Este campo é obrigatório'),
  fullName: Yup.string().required('Este campo é obrigatório'),
  cpf: Yup.string()
    .required('Este campo é obrigatório')
    .test('validateCpf', 'CPF inválido', function (value) {
      return validateCPF(value);
    }),
  birthDate: Yup.string().required('Este campo é obrigatório'),
  phone: Yup.string().required('Este campo é obrigatório')
};

export const EditProfileFormValidator = Yup.object().shape(EditProfileForm);
