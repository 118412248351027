import React from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import PaymentIcon from '@material-ui/icons/Payment';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import createStyles from './CustomStepIcon.styles';

const CustomStepIcon = (props) => {
  const classes = createStyles(props);
  const { step, active, completed } = props;

  const stepIcons = [
    <ShoppingCartIcon />,
    <PersonIcon />,
    <PaymentIcon />,
    <CheckIcon />
  ];

  return (
    <div
      className={clsx(classes.stepIconRoot, {
        [classes.stepActive]: active,
        [classes.stepCompleted]: completed
      })}
    >
      {stepIcons[step]}
    </div>
  );
};

CustomStepIcon.propTypes = {
  step: PropTypes.number,
  active: PropTypes.bool,
  completed: PropTypes.bool
};

export default CustomStepIcon;
