import React, { useState, useEffect } from 'react';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from 'components/ButtonPrimary';
import { Typography, Checkbox } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import { makeStyles } from '@material-ui/core/styles';
import { getCEP } from 'shared/util/GetCEP';
import createStyles from './RegisterBoxForm.styles';
import { getCitiesByState, getMunicipalitiesByState } from "api/City";
import ReCAPTCHA from "react-google-recaptcha";

// FORM USER REGISTER
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: 'rgba(0, 0, 0, 0.65)'
  }
});

const LoginForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldTouched,
    isSubmitting,
    isValidating,
    values
  } = props;

  const [captchaValidated, setCaptchaValidated] = useState(false);

  function onChange(value) {
    if(value)
      setCaptchaValidated(true)
    else setCaptchaValidated(false)
  }

  useEffect(() => {
    // console.log("AAAAAA", isSubmitting , isValidating)
    if (!isSubmitting) {
      let scroll = null;
      for (let name in errors) {
        let element = document.getElementsByName(name)[0];
        if (element === undefined) {
          element = {
            type: "hidden"
          }
        }
        if (element.type !== 'hidden') {
          if (scroll == null) {
            scroll = element.offsetTop;
          } else {
            scroll = element.offsetTop < scroll ? element.offsetTop : scroll;
          }
        }
      }
      let offset = document.getElementsByTagName("header")[0].offsetHeight * 1.25;
      window.scroll({
        top: scroll - offset,
        behavior: 'smooth'
      });
    }

  }, [isSubmitting]);

  useEffect(() => {
    if (errors?.externalErrors === "Um usuário com este CPF/CNPJ já está registrado no sistema.") {
      touched.cpf = true;
      errors.cpf = "Um usuário com este CPF/CNPJ já está registrado no sistema."
    }
    if (errors?.externalErrors === "Um usuário com este endereço de e-mail já está registrado no sistema.") {
      touched.email = true;
      errors.email = "Um usuário com este endereço de e-mail já está registrado no sistema."
    }
  }, [errors]);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  //FIXME mover para RegisterBoxForm.validator.js
  const [cepError, setCepError] = useState('');
  const [cepErrorBilling, setCepErrorBilling] = useState('');
  const [selectState, setSelectState] = useState({});
  const [municipalities, setMunicipalities] = useState({});

  const visibilityIcon = isPasswordVisible ? <Visibility /> : <VisibilityOff />;

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (const path of Object.keys(errors)) {
      setFieldTouched(path, true, false);
    }
    //FIXME mover para RegisterBoxForm.validator.js
    if (!values.address) {
      if (values.cep && values.cep.replace(/_|-/g, '').length === 8) {
        setCepError('CEP não encontrado');
      }
      else {
        setCepError('CEP inválido');
      }
    }
    props.handleSubmit();
  }

  const handleIndustryChange = (event) => {
    values.industry = event.target.value;
  };

  const handleChangeGender = (event) => {
    values.gender = event.target.value;
  };

  const handleChangeHomestate = (selectedOption) => {
    // setSelectState(selectedOption)
    values.homestate = selectedOption.value;
  };

  const handleChangeHomecity = (selectedOption) => {
    // setSelectState(selectedOption)
    values.hometown = selectedOption.label;
  };

  const handleChangeMunicipio = (selectedOption) => {
    // setSelectState(selectedOption)
    values.municipality = selectedOption.value;
    values.hometown = selectedOption.label;
  };

  const handleChangeUf = (selectedOption) => {
    values.rgUf = selectedOption.label;
  };

  const handleChangeSituacaoOcupacional = (selectedOption) => {
    values.situacaoOcup = selectedOption.value;
  };

  const handleChangeMaritalStatus = (selectedOption) => {
    values.maritalStatus = selectedOption.magentoValue;
  };

  const handleChangeScholarity = (selectedOption) => {
    values.scholarity = selectedOption.magentoValue;
  };

  const handleSelectedState = async (resp) => {
    // var respCidade = await getCitiesByState(resp)
    // setSelectState(respCidade)

    var respMunicipio = await getMunicipalitiesByState(resp)
    setMunicipalities(respMunicipio)
  }

  useEffect(() => {
    if (values.homestate !== "") {

      var resp = {}
      homestateOptions.map((item) => {
        if (item.value === values.homestate) {
          resp = item;
        }
      });
      handleSelectedState(resp.label)
    }
  }, [values.homestate])

  //FIXME mover para RegisterBoxForm.validator.js
  useEffect(() => {
    let inputCEP = '';

    if (values.cep) {
      inputCEP = values.cep.replace(/_|-/g, '');
    }

    values.address = "";
    values.neighbourhood = "";
    values.city = "";
    values.state = "";
    values.ibge = "";

    if (inputCEP.length === 0) {
      setCepError('');
    }
    else if (inputCEP.length > 0 && inputCEP.length !== 8) {
      setCepError('CEP inválido');
    }
    else if (inputCEP.length === 8) {
      getCEP(inputCEP).then(
        (response) => {
          values.address = response.address;
          values.neighbourhood = response.neighbourhood;
          values.city = response.city;
          values.state = response.state;
          values.ibge = response.ibge;

          values.cepBilling = values.cep
          values.cityBilling = response.city
          values.stateBilling = response.state

          setCepError('');
        },
        () => {
          setCepError('CEP não encontrado');
        });
    }
  }, [values.cep]);

  useEffect(() => {
    values.addressBilling = values.address;
  }, [values.address]);

  useEffect(() => {
    values.neighbourhoodBilling = values.neighbourhood;
  }, [values.neighbourhood]);

  useEffect(() => {
    values.addressNumberBilling = values.addressNumber;
  }, [values.addressNumber]);

  useEffect(() => {
    values.addressComplementBilling = values.addressComplement;
  }, [values.addressComplement]);

  useEffect(() => {
    values.gender = '1';
  }, []);

  const maritalStatusOptions = [
    { value: 'C', label: 'Casado', magentoValue: '5476' },
    { value: 'D', label: 'Desquitado', magentoValue: '5477' },
    { value: 'E', label: 'União Estável', magentoValue: '5478' },
    { value: 'I', label: 'Divorciado', magentoValue: '5479' },
    { value: 'J', label: 'Separado Judicialmente', magentoValue: '5480' },
    { value: 'P', label: 'Separado', magentoValue: '5481' },
    { value: 'S', label: 'Solteiro', magentoValue: '5482' },
    { value: 'U', label: 'União Estável', magentoValue: '5483' },
    { value: 'V', label: 'Viúvo', magentoValue: '5484' },
    { value: 'O', label: 'Outros', magentoValue: '5485' }
  ];

  const situacaoOpcoes = [
    { value: '1', label: 'Empregado' },
    { value: '2', label: 'Desempregado' },
    { value: '3', label: 'Empregador' },
    { value: '4', label: 'Autônomo/Conta Própria' },
    { value: '5', label: 'Profissional Liberal' },
    { value: '6', label: '1° Emprego' },
    { value: '7', label: 'Aposentado' },
    { value: '8', label: 'Microempreendedor Individual - MEI' },
    { value: '9', label: 'Aprendiz com contrato' }
  ];

  const scholarityOptions = [
    { value: '1', label: 'Analfabeto', magentoValue: '5486' },
    {
      value: '2',
      label: 'Até o 5º ano incompleto do ensino fundamental',
      magentoValue: '5487'
    },
    {
      value: '3',
      label: '5º ano completo do ensino fundamental',
      magentoValue: '5488'
    },
    {
      value: '4',
      label: 'Do 6º ao 9º ano do ensino fundamental',
      magentoValue: '5489'
    },
    { value: '5', label: 'Ensino fundamental completo', magentoValue: '5490' },
    { value: '6', label: 'Ensino médio incompleto', magentoValue: '5491' },
    { value: '7', label: 'Ensino médio completo', magentoValue: '5492' },
    { value: '8', label: 'Educação superior incompleto', magentoValue: '5493' },
    { value: '9', label: 'Educação superior completo', magentoValue: '5494' },
    { value: 'A', label: 'Pós Grad. incompleto', magentoValue: '5495' },
    { value: 'B', label: 'Pós Grad. completo', magentoValue: '5496' },
    { value: 'C', label: 'Mestrado incompleto', magentoValue: '5497' },
    { value: 'D', label: 'Mestrado completo', magentoValue: '5498' },
    { value: 'E', label: 'Doutorado incompleto', magentoValue: '5499' },
    { value: 'F', label: 'Doutorado completo', magentoValue: '5500' },
    { value: 'G', label: 'Pós Dout.incompleto', magentoValue: '5501' },
    { value: 'H', label: 'Pós Dout.completo', magentoValue: '5502' }
  ];

  const homestateOptions = [
    { label: 'AC', value: '5449' },
    { label: 'AL', value: '5450' },
    { label: 'AM', value: '5451' },
    { label: 'AP', value: '5452' },
    { label: 'BA', value: '5453' },
    { label: 'CE', value: '5454' },
    { label: 'DF', value: '5455' },
    { label: 'ES', value: '5456' },
    { label: 'GO', value: '5457' },
    { label: 'MA', value: '5458' },
    { label: 'MG', value: '5459' },
    { label: 'MS', value: '5460' },
    { label: 'MT', value: '5461' },
    { label: 'PA', value: '5462' },
    { label: 'PB', value: '5463' },
    { label: 'PE', value: '5464' },
    { label: 'PI', value: '5465' },
    { label: 'PR', value: '5466' },
    { label: 'RJ', value: '5467' },
    { label: 'RN', value: '5468' },
    { label: 'RO', value: '5469' },
    { label: 'RR', value: '5470' },
    { label: 'RS', value: '5471' },
    { label: 'SC', value: '5472' },
    { label: 'SE', value: '5473' },
    { label: 'SP', value: '5474' },
    { label: 'TO', value: '5475' }
  ];

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Typography
        variant='subtitle1'
        align='left'
        className={classes.title} >
        Informações pessoais
      </Typography>
      <Input
        placeholder='Ex.: João'
        label='Nome*'
        type='text'
        name='name'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
        customClasses={{ root: classes.input }}
        error={touched.name ? errors.name : null}
      />
      <Input
        placeholder='Ex.: da Silva'
        label='Sobrenome*'
        type='text'
        name='lastname'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.lastname}
        customClasses={{ root: classes.input }}
        error={touched.lastname ? errors.lastname : null}
      />
      <Input
        placeholder='Ex.: joao.silva@gmail.com'
        label='E-mail*'
        name='email'
        type='text'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        customClasses={{ root: classes.input }}
        error={touched.email ? errors.email : null}
      />
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Gênero*</FormLabel>
        <RadioGroup
          onChange={handleChangeGender}
          // value={gender}
          defaultValue='1'
          aria-label='gender'
          name='customized-radios'
          style={{ flexDirection: 'row', marginBottom: '1.6rem' }}
        >
          <FormControlLabel
            value='1'
            control={<StyledRadio />}
            label='Masculino'
          />
          <FormControlLabel
            value='2'
            control={<StyledRadio />}
            label='Feminino'
          />
          <FormControlLabel
            value='3'
            control={<StyledRadio />}
            label='Outros'
          />
        </RadioGroup>
      </FormControl>
      <FormControl component='fieldset'>
        <FormLabel className={classes.label} component='legend'>
          Você é trabalhador da indústria ou empresa contribuinte, com registro
          na carteira profissional?
        </FormLabel>
        <RadioGroup
          aria-label='industry'
          name='industry'
          value={values.industry}
          onChange={handleIndustryChange}
          style={{ marginBottom: '1.6rem' }}
        >
          <FormControlLabel
            className={classes.label}
            value='industry'
            control={<StyledRadio />}
            label='Indústria'
          />
          <FormControlLabel
            className={classes.label}
            value='industryA'
            control={<StyledRadio />}
            label='Indústria associada ou contribuinte'
          />
          <FormControlLabel
            className={classes.label}
            value='nonindustry'
            control={<StyledRadio />}
            label='Não indústria'
          />
        </RadioGroup>
      </FormControl>

      <Input
        placeholder=''
        label='Data de nascimento*'
        type='text'
        name='birthDate'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.birthDate}
        customClasses={{ root: classes.input }}
        mask={'99/99/9999'}
        error={touched.birthDate ? errors.birthDate : null}
      />

      <Input
        placeholder='Ex.: 123.456.789-00'
        mask={'999.999.999-99'}
        label='CPF*'
        type='text'
        name='cpf'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.cpf}
        customClasses={{ root: classes.input }}
        error={touched.cpf ? errors.cpf : null}
      />

      <Input
        placeholder='Ex.: 123456789'
        label='RG*'
        type='text'
        name='rg'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.rg}
        customClasses={{ root: classes.input }}
        error={touched.rg ? errors.rg : null}
      />
      {/* rgUf, rgEmitter e rgEmissionDate */}
      <Input
        label='UF*'
        placeholder='Ex.: SC, RJ, SP...'
        select
        selectOptions={homestateOptions}
        type='text'
        name='rgUf'
        onChange={handleChangeUf}
        onBlur={handleBlur}
        value={values.selectedRgUf}
        customClasses={{ root: classes.input }}
        error={touched.rgUf ? errors.rgUf : null}
      />

      <Input
        placeholder='Ex.: SSP-CE, SSP-SP'
        label='Orgão expedidor*'
        type='text'
        name='rgEmitter'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.rgEmitter}
        customClasses={{ root: classes.input }}
        error={touched.rgEmitter ? errors.rgEmitter : null}
      />

      <Input
        placeholder=''
        label='Data de Emissão*'
        type='text'
        name='rgEmissionDate'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.rgEmissionDate}
        customClasses={{ root: classes.input }}
        mask={'99/99/9999'}
        error={touched.rgEmissionDate ? errors.rgEmissionDate : null}
      />

      <Typography
        variant='subtitle1'
        align='left'
        className={classes.title}
      >
        Endereço
      </Typography>
      <br />
      <Input
        placeholder='Ex: 11023-456'
        mask={'99999-999'}
        label='CEP*'
        type='text'
        name='cep'
        helperText='Seu endereço aparecerá automaticamente.'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.cep}
        customClasses={{ root: classes.input }}
        /* error={cepError || null}*/
        error={cepError ? cepError : touched.cep ? errors.cep : null}
      />
      <Input
        label='Estado*'
        type='text'
        name='state'
        disabled={true}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.state}
        customClasses={{ root: classes.input }}
        error={touched.state ? errors.state : null}
      />

      <Input
        label='Cidade*'
        type='text'
        name='city'
        disabled={true}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.city}
        customClasses={{ root: classes.input }}
        error={touched.city ? errors.city : null}
      />

      <Input
        label='Bairro*'
        type='text'
        name='neighbourhood'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.neighbourhood}
        customClasses={{ root: classes.input }}
        error={touched.neighbourhood ? errors.neighbourhood : null}
      />

      <Input
        label='Endereço*'
        type='text'
        name='address'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.address}
        customClasses={{ root: classes.input }}
        error={touched.address ? errors.address : null}
      />

      <Input
        label='Número*'
        type='text'
        name='addressNumber'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.addressNumber}
        customClasses={{ root: classes.input }}
        error={touched.addressNumber ? errors.addressNumber : null}
      />

      <Input
        label='Complemento'
        type='text'
        name='addressComplement'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.addressComplement}
        customClasses={{ root: classes.input }}
        error={touched.addressComplement ? errors.addressComplement : null}
      />

      <Input
        placeholder='Ex.: SC, RJ, SP...'
        select
        selectOptions={homestateOptions}
        label='Estado natal*'
        type='text'
        name='homestate'
        onChange={handleChangeHomestate}
        onBlur={handleBlur}
        value={values.selectedHomestate}
        customClasses={{ root: classes.input }}
        error={touched.homestate ? errors.homestate : null}
      />

      {municipalities.length > 0 ?
        <Input
          placeholder=''
          select
          selectOptions={municipalities}
          label='Municipio/Cidade natal*'
          type='text'
          name='municipality'
          onChange={handleChangeMunicipio}
          onBlur={handleBlur}
          value={values.selectedMunicipality}
          customClasses={{ root: classes.input }}
          error={touched.municipality ? errors.municipality : null}
        /> :
        <div disabled>
          <Input
            placeholder=''
            disabled
            selectOptions={municipalities}
            label='Municipio/Cidade natal*'
            type='text'
            name='municipality'
            onChange={handleChangeMunicipio}
            onBlur={handleBlur}
            value={values.selectedMunicipality}
            customClasses={{ root: classes.input }}
            error={touched.municipality ? errors.municipality : null}
          />
        </div>
      }

      <Input
        placeholder='Ex.: Nome da mãe'
        label='Filiação (Nome da mãe)*'
        type='filiation'
        name='mothersName'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.mothersName}
        customClasses={{ root: classes.input }}
        error={touched.mothersName ? errors.mothersName : null}
      />

      <Input
        placeholder='Ex.: Solteiro...'
        select
        selectOptions={maritalStatusOptions}
        label='Estado Civíl*'
        type='text'
        name='maritalStatus'
        onChange={handleChangeMaritalStatus}
        onBlur={handleBlur}
        value={values.selectedMaritalStatus}
        customClasses={{ root: classes.input }}
        error={touched.maritalStatus ? errors.maritalStatus : null}
      />
      <Input
        placeholder='Ex.: Ensino médio completo...'
        select
        selectOptions={scholarityOptions}
        label='Escolaridade*'
        type='text'
        name='scholarity'
        onChange={handleChangeScholarity}
        onBlur={handleBlur}
        value={values.selectedScholarity}
        customClasses={{ root: classes.input }}
        error={touched.scholarity ? errors.scholarity : null}
      />

      <Input
        label='Situação Ocupacional*'
        placeholder='Ex.: Empregado, Desempregado, MEI ...'
        select
        selectOptions={situacaoOpcoes}
        type='text'
        name='situacaoOcup'
        onChange={handleChangeSituacaoOcupacional}
        onBlur={handleBlur}
        value={values.selectedSituacaoOcup}
        customClasses={{ root: classes.input }}
        error={touched.situacaoOcup ? errors.situacaoOcup : null}
      />
      <Typography
        variant='subtitle1'
        align='left'
        className={classes.title}
      >
        Contatos
      </Typography>
      <Input
        placeholder='Ex.: (12) 93456-7890'
        mask={'(99) 99999-9999'}
        label='Telefone celular*'
        type='celphone'
        name='phone1'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.phone1}
        autoComplete={"off"}
        customClasses={{ root: classes.input }}
        error={touched.phone1 ? errors.phone1 : null}
      />
      <Input
        placeholder='Ex.: (12) 93456-7890'
        mask={'(99) 99999-9999'}
        label='Telefone'
        type='celphone'
        name='phone2'
        onChange={handleChange}
        onBlur={handleBlur}
        autoComplete={"off"}
        value={values.phone2}
        customClasses={{ root: classes.input }}
      // error={touched.phone2 ? errors.phone2 : null}
      />
      <Input
        placeholder='*******'
        label='Crie uma senha*'
        type={isPasswordVisible ? 'text' : 'password'}
        name='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        customClasses={{ root: classes.input }}
        error={touched.password ? errors.password : null}
        endIcon={{
          onClick: () => setIsPasswordVisible(!isPasswordVisible),
          icon: visibilityIcon,
          className: classes.emailIcon
        }}
        helperText='A senha deve ter no mínimo oito caracteres, contendo letra maiúscula, minúscula e números'
      />
      <Input
        placeholder='*******'
        label='Repita a senha*'
        type={isPasswordVisible ? 'text' : 'password'}
        name='confirmPassword'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.confirmPassword}
        customClasses={{ root: classes.input }}
        error={
          touched.confirmPassword ? errors.confirmPassword : null
        }
        endIcon={{
          onClick: () => setIsPasswordVisible(!isPasswordVisible),
          icon: visibilityIcon,
          className: classes.emailIcon
        }}
        helperText='A senha deve ser exatamente igual à senha acima'
      />
      <div className={classes.actions}>
        <Typography
          className={classes.checkboxLabel}
          variant='body2'
          color='textSecondary'
        >
          <Checkbox
            size='medium'
            color='primary'
            inputProps={{ 'aria-label': 'quero receber ofertas' }}
            name='receiveNews'
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.receiveNewsThisEntity}
            className={classes.checkbox}
          />
          Quero receber informações desta entidade
        </Typography>
      </div>
      <div className={classes.actions}>
        <Typography
          className={classes.checkboxLabel}
          variant='body2'
          color='textSecondary'
        >
          <Checkbox
            size='medium'
            color='primary'
            inputProps={{ 'aria-label': 'quero receber ofertas' }}
            name='receiveNews'
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.receiveNewsOtherEntities}
            className={classes.checkbox}
          />
          Quero receber informações de outras entidades
        </Typography>
      </div>
      <div className={classes.actions}>
        <Typography
          className={classes.checkboxLabel}
          variant='body2'
          color='textSecondary'
        >
          <Checkbox
            size='medium'
            color='primary'
            inputProps={{
              'aria-label': 'declaro que as informações acima são verdadeiras'
            }}
            name='acceptSendPersonalInfos'
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.acceptSendPersonalInfos}
            className={classes.checkbox}
          />
          Declaro que as informações acima são verdadeiras, sob pena de perder
          quaisquer benefícios caso não consiga comprovar minha situação
          cadastral. Aceito informar meus dados pessoais para Senai/ES e estou
          ciente da Política de Privacidade do site.
        </Typography>
      </div>
      {errors.externalErrors && (
        <FormHelperText className={classes.errorsText} error>
          {errors.externalErrors}
        </FormHelperText>
      )}
      <ReCAPTCHA
          sitekey="6LcIF60oAAAAAMtq47405nBtxmW973OEuy2jiwXL"
          onChange={onChange}
      />

      <Button
        disabled={!values.acceptSendPersonalInfos || !captchaValidated}
        lowercase
        isLoading={isSubmitting}
        type='submit'
        width='100%'
      >
        Cadastrar
      </Button>
    </form>
  );
};

export default LoginForm;

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={
        <RadioButtonCheckedRoundedIcon style={{ color: '#32559D' }} />
      }
      icon={<RadioButtonUncheckedRoundedIcon />}
      {...props}
    />
  );
}
