import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

const SearchFromCourseTitle = (props) => {
  const { title } = useParams();

  return (
    <Redirect
      status={301}
      to={`/busca/${title.replaceAll('-', ' ')}`}
    />
  );
};

export default SearchFromCourseTitle;
