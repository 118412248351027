import EditProfileForm from './EditProfileForm.formik';
import { connect } from 'react-redux';
import { updateUser } from 'modules/Login/Login.ducks.js';

const mapStateToProps = (state) => {
  return {
    isLoading: state.cart.isLoading,
    user: state.session.user
  };
};

const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);
