import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import CreditCardForm from './CreditCardForm';
import { CreditCardFormValidator } from './CreditCardForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';

const CreditCardFormWrapper = (props) => {
  const initialValues = {
    cardNumber: '',
    personName: '',
    cardMonth: '',
    cardYear: '',
    securityCode: '',
    installmentOptions: '1',
    useTerms: false,
    contract: false
  };

  const { sale, handleClose, hasSaleError, externalErrors, handleNext, address, products, cart, isLoading } = props;

  const handleSubmit = async (formValues, actions) => {
    const saleObject = {
      paymentData:{
        ...formValues
      },
      products: products,
      responsible: address,
      cartId: cart && cart.quote_id,
    };
    const response = await sale(saleObject);
    if(response !== undefined){
      handleNext();
    }
    
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    // validationSchema={CreditCardFormValidator}
    // isInitialValid={false}
    >
      {(propsFormik) => (
        <CreditCardForm
          isLoading={isLoading}
          externalErrors={externalErrors}
          products={products}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default CreditCardFormWrapper;
