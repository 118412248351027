import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  carousel: {
    position: 'relative ',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    width: '100%',
    overflow: 'hidden'
  },
  tray: {
    display: 'flex'
    // transition: 'all 500ms ease-in-out',
  },
  slide: {
    width: '100%'
  },
  buttonsContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0%',
    left: '0%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonsContainerInner: {
    width: '100%',
    margin: '0 2%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    width: '3rem',
    height: '10rem',
    backgroundColor: theme.colors.background.bg45,
    border: '1px solid',
    borderColor: theme.colors.white,
    color: theme.colors.white,
    padding: '0',
    zIndex: '1',
    transition: 'all 150ms ease-in-out',

    '&:disabled': {
      opacity: '.3',
      cursor: 'unset'
    },

    '&:not(:disabled):hover': {
      backgroundColor: theme.colors.blueCompany,
      color: theme.colors.white
    },
    '@media (max-width: 600px)': {
      width: '2.5rem',
      height: '6rem'
    }
  }
}));

export default createStyles;
