import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  billetFormRoot: {
    padding: '2rem',
    '& p': {
      marginBottom: '1.4rem'
    }
  },
  totalPrice: {
    color: theme.colors.blueCompany,
    fontWeight: 'bold',
    marginBottom: '1.4rem'
  },
  errorsText: {
    margin: '-1rem 0 2rem 0'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2rem',
    '& p': {
      margin: '0'
    }
  }
}));

export default createStyles;
