import * as Yup from 'yup';

const changePasswordForm = {
  newPassword: Yup.string()
  .required('Este campo é obrigatório')
  .min(8, 'A senha deve conter no mín. 8 caracteres'),
  passwordConfirmation:  Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'As senhas devem ser iguais'), 
  oldPassword: Yup.string()
  .required('Este campo é obrigatório')
};

export const changePasswordFormValidator = Yup.object().shape(changePasswordForm);
