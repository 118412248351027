import courseImage from 'assets/images/courseImage.jpg';
import imgCompetenciaRenda from 'assets/images/mock/competenciaRenda.png';
import imgHealthCare from 'assets/images/mock/healthCare.png';
import imgReforcoEducacaoBasica from 'assets/images/mock/reforcoEducacaoBasica.png';
import banner2 from 'assets/images/mock/banner2.png';
import banner1 from 'assets/images/mock/banner1.png';
import cursosTecnicos from 'assets/images/linkCards/cursosTecnicos.png';

export const getVitrineMock = () => {
  return {
    banners: [
      {
        id: 1,
        image: banner2
      },
      {
        id: 2,
        image: banner1
      }
    ],
    cities: [
      {
        name: 'Vitória'
      },
      {
        name: 'Cariacica'
      },
      {
        name: 'Serra'
      },
      {
        name: 'Linhares'
      },
      {
        name: 'Colatina'
      },
      {
        name: 'Cachoeira de Itapemirim'
      },
      {
        name: 'Aracruz'
      }
    ],
    featuredProducts: [
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 100,
        discount: 0,
        priceWithDiscount: 100,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [],
        price: 100,
        discount: 0,
        priceWithDiscount: 100,
        parcels: 2,
        link: '/teste',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: 'Aliquam justo sapien.',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 100,
        discount: 30,
        priceWithDiscount: 70,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [],
        price: 100,
        discount: 0,
        priceWithDiscount: 100,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title:
          '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [],
        price: 100,
        discount: 0,
        priceWithDiscount: 100,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 100,
        discount: 30,
        priceWithDiscount: 70,
        parcels: 2,
        link: '#',
        isFavorite: false
      },
      {
        image: courseImage,
        areaTag: 'Área de Aprendizagem',
        title: '“Cozinha Prática e Saudável 3”',
        place: 'Jardim da Penha - Vitória',
        duration: 40,
        classes: [
          {
            id: 1,
            name: 'Turma A',
            openChairs: 20
          }
        ],
        price: 100,
        discount: 0,
        priceWithDiscount: 100,
        parcels: 2,
        link: '#',
        isFavorite: false
      }
    ],
    categories: [
      {
        id: 1,
        title: 'Reforço da Educação Básica',
        subtitle: 'Habilitação técnica',
        link: '',
        image: imgReforcoEducacaoBasica,
        bgCardImage: cursosTecnicos,
        description:
          'Donec imperdiet lorem felis, nec imperdiet eros hendrerit id. Mauris facilisis dapibus tortor, vitae congue ex elementum a. Morbi at leo congue, volutpat ante a, blandit justo. Donec quis cursus ex, ac varius ligula. Nam tortor eros, varius consequat neque sed, molestie malesuada eros. Sed egestas, dolor nec aliquet accumsan, velit sem vehicula.',
        products: [
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 25,
            priceWithDiscount: 75,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '/teste',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: 'Aliquam justo sapien.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          }
        ]
      },
      {
        id: 2,
        title: 'Qualificação Profissional',
        subtitle: 'Habilitação técnica',
        bgCardImage: cursosTecnicos,
        link: '',
        image: imgCompetenciaRenda,

        description:
          'Donec imperdiet lorem felis, nec imperdiet eros hendrerit id. Mauris facilisis dapibus tortor, vitae congue ex elementum a. Morbi at leo congue, volutpat ante a, blandit justo. Donec quis cursus ex, ac varius ligula. Nam tortor eros, varius consequat neque sed, molestie malesuada eros. Sed egestas, dolor nec aliquet accumsan, velit sem vehicula.',
        products: [
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 25,
            priceWithDiscount: 75,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '/teste',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: 'Aliquam justo sapien.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          }
        ]
      },
      {
        id: 3,
        title: 'Iniciação Profissional',
        subtitle: 'Habilitação técnica',
        bgCardImage: cursosTecnicos,
        link: '/categoria-e-geracao-renda',
        elementId: '#teste',
        image: imgHealthCare,
        description:
          'Donec imperdiet lorem felis, nec imperdiet eros hendrerit id. Mauris facilisis dapibus tortor, vitae congue ex elementum a. Morbi at leo congue, volutpat ante a, blandit justo. Donec quis cursus ex, ac varius ligula. Nam tortor eros, varius consequat neque sed, molestie malesuada eros. Sed egestas, dolor nec aliquet accumsan, velit sem vehicula.',
        products: [
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 100,
            discount: 25,
            priceWithDiscount: 75,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              'Nulla facilisi. Duis non tortor tempus, vulputate dolor placerat, sagittis eros. Suspendisse mi dui, egestas quis nisl vitae, dapibus viverra urna.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '/teste',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: 'Aliquam justo sapien.',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title:
              '“Cozinha Prática e Saudável 3” Coma sem culpa – “Faça as pazes com a mesa” - título um pouco maior',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          },
          {
            image: courseImage,
            areaTag: 'Área de Aprendizagem',
            title: '“Cozinha Prática e Saudável 3”',
            place: 'Jardim da Penha - Vitória',
            duration: 40,
            classes: [
              {
                id: 1,
                name: 'Turma A',
                openChairs: 20
              }
            ],
            price: 49.9,
            discount: 30,
            priceWithDiscount: 34.93,
            parcels: 2,
            link: '#',
            isFavorite: false
          }
        ]
      }
    ]
  };
};

// export const getVitrineMock = () => {
//   return {
//     banners: [
//       {
//         id: 1,
//         image: 'https://findes.s3-sa-east-1.amazonaws.com/banner1-min.png'
//       },
//       {
//         id: 2,
//         image: 'https://findes.s3-sa-east-1.amazonaws.com/banner2-min.png'
//       }
//     ],
//     featuredProducts: [],
//     categories: [
//       {
//         id: 3,
//         title: 'Graduação',
//         subtitle: null,
//         image:
//           '/pub/media/catalog/tmp/category/WhatsApp_Image_2020-02-18_at_12.52.25.jpeg',
//         description: '<p>Descrição da categoria Graduação</p>',
//         products: [
//           {
//             id: 1,
//             image: '',
//             areaTag: 'Tecnologia',
//             title: 'Produto Teste',
//             place: 'UFSC',
//             duration: 1000.0,
//             clazz: '13/03/2020 - 00h ás 23h',
//             price: 250.53,
//             discount: 10.0,
//             priceWithDiscount: 225.48,
//             parcels: 2,
//             startDate: '2020-03-13 00:00:00',
//             startTime: '00h',
//             endTime: '23h',
//             favorite: false
//           }
//         ]
//       }
//     ]
//   };
// };
