import { makeStyles, withTheme } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    height: '25rem',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 1)), url(${props.backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top'
  }),

  mainContainer:{
    height: '100%',
  },
  title: {
    color: theme.colors.white,
    textAlign: 'bottom',
    overflow: 'hidden',
    marginTop: 'calc(25rem - 14.96rem)', //25rem(root height) - 12.96rem(3xline-height(4.32rem)) + margin-bottom(2rem)
    marginBottom: '2rem'
  }
}));

export default createStyles;
