import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@material-ui/core';
import PaymentMethodCard from './PaymentMethodCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';


import createStyles from './PaymentMethods.styles';

const PaymentMethods = props => {
  const isMobile = useMediaQuery('(max-width:814px)');

  const classes = createStyles(isMobile);

  const { fetchPaymentMethod, handleDeletePaymentMethod } = props;

  const {
    paymentMethods,
    isLoading } = props;


  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const handleDeleteCard = (id) => {
    handleDeletePaymentMethod(id);
  }


  return (
    <React.Fragment>
         {isLoading ? (
            <div className={classes.rootLoading}>
              <CircularProgress />
          </div>

         ) : (

          <div className={classes.container}>
            <Typography variant='h3' className={classes.title}>
              Formas de Pagamento
            </Typography>

            <div className={classes.cardContainer}>
              { 
                paymentMethods.map(method => 
                  <PaymentMethodCard data={method} delete={() => handleDeleteCard(method.id)}/>
                )
              }
              <PaymentMethodCard />
            </div>
            
          </div>


         ) }
          

    </React.Fragment >
  );
};

export default PaymentMethods;
