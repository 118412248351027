import Registration from './Registration.view';
import { connect } from 'react-redux';
import { fetchUserRegistration } from 'modules/Registration/Registration.ducks.js';
import { handleEditUserRegistration } from 'modules/Login/Login.ducks.js';


const mapStateToProps = state => {
  return {
    user: state.session.user,
    isLoadingSession: state.session.isLoading,
    isLoading: state.userRegistration.isLoading, 
    isEditing: state.userRegistration.isEditing, 
    status: state.userRegistration.status,
    success: state.userRegistration.success
  };
};

const mapDispatchToProps = {
  fetchUserRegistration, 
  handleEditUserRegistration
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);

