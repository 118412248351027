import CartItems from './CartItems';
import { addItemToCart, removeItemFromCart, getItemsInCart, deleteItemInCart, getTotalsInCart, saleReset } from 'modules/Cart/Cart.ducks.js';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    isLoading: state.cart.isLoading,
    isLoadingTotals: state.cart.isLoadingTotals,
    items: state.cart.items,
    totals: state.cart.totals,
    isAuthenticated: state.session.isAuthenticated,
    user: state.session.user,
  };
};

const mapDispatchToProps = { addItemToCart, saleReset,removeItemFromCart, getItemsInCart, deleteItemInCart, getTotalsInCart };

export default connect(mapStateToProps, mapDispatchToProps)(CartItems);
