import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import RegistrationForm from './RegistrationForm';
import { registrationFormValidator } from './RegistrationForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';

const RegistrationFormWrapper = props => {

  const {
    editUserRegistration,
    isLoading,
    handleClose,
    userData,
    success
  } = props;

  const initialValues = {
    firstname: userData.customer.firstname, 
    lastname: userData.customer.lastname,
    email: userData.customer.email,
    cpf: userData.cpf, 
    situacaoOpcoes: userData.customer.custom_attributes.filter(item => item.attribute_code === "situacaoOpcoes")[0]?.value, 
    rg: userData.customer.custom_attributes.filter(item => item.attribute_code === "rg")[0].value, 
    rgUf: userData.customer.custom_attributes.filter(item => item.attribute_code === "rg_uf")[0].value, 
    rgEmitter: userData.customer.custom_attributes.filter(item => item.attribute_code === "rg_emitter")[0].value, 
    rgEmissionDate: userData.customer.custom_attributes.filter(item => item.attribute_code === "rg_emission_date")[0].value, 
    maritalStatus: userData.customer.custom_attributes.filter(item => item.attribute_code === "estado_civil")[0], 
    scholarity: userData.customer.custom_attributes.filter(item => item.attribute_code === "escolaridade")[0], 
    hometown: userData.customer.custom_attributes.filter(item => item.attribute_code === "cidade_natal")[0]?.value, 
    homestate: userData.customer.custom_attributes.filter(item => item.attribute_code === "estado_natal")[0], 
    situacaoOcup: userData.customer.custom_attributes.filter(item => item.attribute_code === "situacao_ocup")[0]?.value, 
    municipality: userData.customer.custom_attributes.filter(item => item.attribute_code === "municipality")[0]?.value, 
    birthDate: userData.birthDate, 
    mothersName: userData.mothersName, 
    phone1: userData.phone1, 
    phone2: userData.phone2,
    gender: userData.gender, 
    receiveNews: userData.receiveNews,
    addressBilling: userData.customer.addressBilling ?? null,
    addressShipping: userData.customer.addressShipping ?? null,

    addressBilling : userData.customer.addressBilling?.street[0] ?? null,
    addressNumberBilling : userData.customer.addressBilling?.street[1] ?? null,
    addressComplementBilling : userData.customer.addressBilling?.street[3] ?? null,
    cepBilling : userData.customer.addressBilling?.postcode ?? null,
    neighbourhoodBilling : userData.customer.addressBilling?.street[2] ?? null,
    stateBilling : userData.customer.addressBilling?.region?.region_code ?? null,
    cityBilling : userData.customer.addressBilling?.city ?? null,
    
    addressShipping : userData.customer.addressShipping?.street[0] ?? null,
    addressNumberShipping : userData.customer.addressShipping?.street[1] ?? null,
    addressComplementShipping : userData.customer.addressShipping?.street[3] ?? null,
    cepShipping : userData.customer.addressShipping?.postcode ?? null,
    neighbourhoodShipping : userData.customer.addressShipping?.street[2] ?? null,
    stateShipping : userData.customer.addressShipping?.region?.region_code ?? null,
    cityShipping : userData.customer.addressShipping?.citys ?? null
  };

  const handleSubmit = async (formValues, actions) => {
    try {
      const formValuesWithAdaptedAddresses = {
        ...formValues,
        addressBilling: {
          address: formValues.addressBilling ?? null,
          addressNumber: formValues.addressNumberBilling ?? null,
          addressComplement: formValues.addressComplementBilling ?? null,
          cep: formValues.cepBilling ?? null,
          state: formValues.stateBilling ?? null,
          city: formValues.cityBilling ?? null,
          neighbourhood: formValues.neighbourhoodBilling ?? null,
        },
        addressShipping: {
          address: formValues.addressBilling ?? null,
          addressNumber: formValues.addressNumberBilling ?? null,
          addressComplement: formValues.addressComplementBilling ?? null,
          cep: formValues.cepBilling ?? null,
          state: formValues.stateBilling ?? null,
          city: formValues.cityBilling ?? null,
          neighbourhood: formValues.neighbourhoodBilling ?? null,
        }
      }
      await editUserRegistration(formValuesWithAdaptedAddresses);
      handleClose();
    } catch (err) {
      if (err instanceof InvalidCredentialsError) {
        this.setState({
          externalErrors: { password: 'CPF ou Senha inválidos' }
        });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={registrationFormValidator}
    >
      {propsFormik => (
        <RegistrationForm
          isLoading={isLoading}
          success={success}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default RegistrationFormWrapper;
