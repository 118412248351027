import { getVitrine } from 'api/Vitrine';

// Actions
const FETCH_VITRINE_START = 'sesi/login/FETCH_VITRINE_START';
const FETCH_VITRINE_SUCCESS = 'sesi/login/FETCH_VITRINE_SUCCESS';
const FETCH_VITRINE_FAILURE = 'sesi/login/FETCH_VITRINE_FAILURE';
const VITRINE_RESET = 'sesi/login/VITRINE_RESET';
const SET_PRODUCT_FAVORITE = 'sesi/login/SET_PRODUCT_FAVORITE';

// Reducer
const initialState = {
  banners: [],
  featuredProducts: [],
  categories: [],
  cities: [],
  isLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_VITRINE_START:
      return { ...state, isLoading: true };
    case FETCH_VITRINE_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case FETCH_VITRINE_FAILURE:
      return { ...state, ...action.payload };
    case VITRINE_RESET:
      return { ...state, ...initialState };
    case SET_PRODUCT_FAVORITE:
      state.categories.forEach(category => {
        category.products.forEach(course => {
          if (course.sku === action.payload.sku) {
            course.favorite = action.payload.value;
          }
        })
      })
      state.featuredProducts.forEach(course => {
        if (course.sku === action.payload.sku) {
          course.favorite = action.payload.value;
        }
      })
      let newCaterories = state.categories;
      let newFeaturedProducts = state.featuredProducts;
      return { ...state, categories: newCaterories, featuredProducts: newFeaturedProducts};
    default:
      return state;
  }
}

// Action Creators
export function fetchVitrineStart() {
  return { type: FETCH_VITRINE_START };
}

export function fetchVitrineSuccess(data) {
  return { type: FETCH_VITRINE_SUCCESS, payload: data };
}

export function fetchVitrineFailure(data) {
  return { type: FETCH_VITRINE_FAILURE, payload: data };
}

export function reset() {
  return { type: VITRINE_RESET };
}

// side effects, only as applicable
export const fetchVitrine = () => {
  return async dispatch => {
    dispatch(fetchVitrineStart());

    try {
      const response = await getVitrine();
      dispatch(fetchVitrineSuccess(response));
    } catch (error) {
      dispatch(fetchVitrineFailure(error));
    }
  };
};

export const setFavoriteToCourse = (sku, value) => {
  return { type: SET_PRODUCT_FAVORITE, payload: {sku, value} };
}