import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  root:{
    padding: '1rem',
    '@media (min-width: 400px)':{
      marginTop: '10%',
      marginBottom: '10%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',      
    }
  }
}));

  export default createStyles;