import MyPaymentHistory from './MyPaymentHistory.view';
import { connect } from 'react-redux';
import { fetchMyPayments } from './MyPaymentHistory.ducks'

const mapStateToProps = state => {
  return {
    isLoading: state.historyPayments.isLoading,
    myPayments: state.historyPayments.myPayments,
  };
};

const mapDispatchToProps = {
  fetchMyPayments
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPaymentHistory);
