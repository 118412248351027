import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import TabButton from 'components/TabButton';
import SelectSearch from 'components/SelectSearch';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonPrimary from 'components/ButtonPrimary';

import createStyles from './PurchaseHistory.styles';

const PurchaseHistory = props => {
  const { fetchPurchaseHistory } = props;

  const {
    purchaseHistory,
    isLoading } = props;

  const options = [
    { value: 0, label: 'TODOS' },
    { value: 1, label: 'AGUARDANDO PAGAMENTO' },
    { value: 2, label: 'PAGO' },
    { value: 3, label: 'CANCELADO' },
    { value: 4, label: 'REEMBOLSOS' }
  ]
  
  const breadcrumbs = [
    { label: 'Home', to: '/' },
  ];

  const [stateFilter, setStateFilter] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const rootRef = useRef(null);

  const classes = createStyles(isMobile);

  const downloadNFE = (id) => {
    //TODO
    // download da nota fiscal eletronica
    alert('Download da NF-e ' + id);
  };

  useEffect(() => {
    const updateWidth = () => {
      if (rootRef.current) {
        setIsMobile(rootRef.current.offsetWidth < 720 ? true : false)
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  });

  const formatPrice = price => {
    return (price
      .toFixed(2)
      .toString()
      .replace('.', ',')
    )
  }

  useEffect(() => {
    fetchPurchaseHistory();
  }, []);

  var filteredHistory = purchaseHistory.filter(course => {
    if (stateFilter == 0) {
      return course
    }
    else {
      return course.status == stateFilter
    }
  });

  return (
    <React.Fragment>
      {isLoading ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
          <div ref={rootRef} className={classes.container}>
            <Typography variant='h3' className={classes.title}>
              Histórico de compra
          </Typography>
            {isMobile ?
              <div className={classes.select}>
                <SelectSearch
                  value={options[stateFilter]}
                  icon="filter"
                  iconSize="default"
                  placeholder="Todos"
                  options={options}
                  onSelectChange={option => setStateFilter(option)}
                ></SelectSearch>
              </div>
              : <div className={classes.tabs}>
                {options.map(option => {
                  return (
                    <TabButton
                      onClick={() => setStateFilter(option.value)}
                      selected={stateFilter == option.value}
                      buttonText={option.label}
                      lowercase={true} />
                  )
                })}
              </div>
            }
            {!isMobile &&
              <div className={classes.header}>
                <Typography variant="subtitle2" classes={{ root: classes.identifier }}>Identificador</Typography>
                <Typography variant="subtitle2" classes={{ root: classes.date }}>Data</Typography>
                <Typography variant="subtitle2" classes={{ root: classes.value }}>Valor</Typography>
                <Typography variant="subtitle2" classes={{ root: classes.payment }}>Forma de pagamento</Typography>
                <Typography variant="subtitle2" classes={{ root: classes.nfeHeader }}>Nota fiscal</Typography>
              </div>
            }
            {filteredHistory && filteredHistory.map(purchase => {
              return (
                <ExpansionPanel
                  classes={{ root: classes.expansionPanel }}
                  square={true}
                  elevation={0}

                >
                  <ExpansionPanelSummary
                    classes={{ root: classes.expansionPanelSummary, content: classes.expansionPanelSummaryContent, expandIcon: classes.expandIcon }}
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography classes={{ root: classes.identifier }}>{isMobile && <span>Identificador: </span>}{purchase.id}</Typography>
                    <Typography classes={{ root: classes.date }}>{isMobile && <span>Data: </span>}{purchase.date}</Typography>
                    <Typography classes={{ root: classes.value }}>{isMobile && <span>Valor: </span>}{formatPrice(purchase.totalPrice)}</Typography>
                    <Typography classes={{ root: classes.payment }}>{isMobile && <span>Forma de pagamento: </span>}{purchase.payment}</Typography>

                    <div className={classes.nfe}>
                      <ButtonPrimary
                        variant='outlined'
                        size="small"
                        lowercase={true}
                        buttonText="NF-e"
                        onClick={() => downloadNFE(purchase.id)}
                      />
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
                    <div className={classes.detailSection}>
                      <Typography variant="subtitle2" classes={{ root: classes.detailsTitle }}>Itens</Typography>
                      {purchase.items.map(item => {
                        return (
                          <div className={classes.detailItem}>
                            <Typography variant="body1">{item.id} - {item.name}</Typography>
                            <Typography variant="body1">R${formatPrice(item.price)}</Typography>

                          </div>
                        )
                      })}
                    </div>
                    <div className={classes.detailSection}>
                      <Typography variant="subtitle2" classes={{ root: classes.detailsTitle }}>Descontos</Typography>
                      {purchase.items.map(item => {
                        if (item.discount > 0) {
                          return (
                            <div className={classes.detailItem}>
                              <Typography variant="body1">{item.id} - {item.name}</Typography>
                              <Typography variant="body1">R${formatPrice(item.priceWithDiscount - item.price)}</Typography>

                            </div>
                          )
                        }
                      })}
                    </div>
                    <div className={classes.detailTotal}>
                    <Typography variant="subtitle2">Total: {formatPrice(purchase.totalPrice)}</Typography>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </div>
        )
      }
    </React.Fragment >
  );
};

export default PurchaseHistory;
