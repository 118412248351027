import React from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumbs as BreadcrumbsUI,
  Link,
  Typography,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MainContainer from 'components/MainContainer';

import createStyles from './Breadcrumbs.styles';

const Breadcrumbs = ({ items }) => {
  const classes = createStyles();

  return (
    <MainContainer>
      <BreadcrumbsUI
        className={classes.root}
        separator={<ChevronRightIcon fontSize='small' />}
      >
        {items.map(item => {
          return item.to ? (
            <Link key={item.label} color='inherit' href={item.to}>
              {item.label}
            </Link>
          ) : (
              <Typography variant="body2" key={item.label}>{item.label}</Typography>
            );
        })}
      </BreadcrumbsUI>
    </MainContainer>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array,
};

export default Breadcrumbs;
