import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  stepConnectorRoot: {
    top: 24,
    left: 'calc(-50% + 40px)',
    right: 'calc(50% + 40px)'
  }
}));

export default createStyles;
