import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import monitorReducersEnhancer from 'redux/enhancers/monitorReducer';
import loggerMiddleware from 'redux/middleware/logger';
import rootReducer from 'redux/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
/**
 * key: Tag identificadora do objeto persistido no localStorage
 *
 * storage: Engine de armazenamento (default usa da localStorage)
 *
 * stateReconciler: (autoMergeLevel2) faz um merge do store persistido com o initalState em 2 níveis de profundidade,
 * garantindo que novos atributos em um nível 2 de profundidade sejam incluídos no initialState
 */

const blacklistedFiltersSession = createBlacklistFilter(
  'session',
  ['isLoading', 'errors', 'success', 'ui']
);

const blacklistedFiltersRegistration = createBlacklistFilter(
  'userRegistration',
  ['isLoading', 'isEditing']
);


const persistConfig = {
  key: 'root-senai',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['search'],
  transforms: [
    blacklistedFiltersSession,
    blacklistedFiltersRegistration
  ]
};

export default function configureStore(preloadedState) {
  const middlewares = [loggerMiddleware, thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = (process.env.NODE_ENV === 'production') ? compose(...enhancers) : composeWithDevTools(...enhancers);
  
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    preloadedState,
    composedEnhancers
  );

  const persistor = persistStore(store);

  return { store, persistor };
}
