import PurchaseHistory from './PurchaseHistory.view';
import { connect } from 'react-redux';
import { fetchPurchaseHistory } from 'modules/PurchaseHistory/PurchaseHistory.ducks.js';

const mapStateToProps = state => {
  return {
    purchaseHistory: state.purchaseHistory.purchaseHistory,
    isLoading: state.purchaseHistory.isLoading
  };
};

const mapDispatchToProps = {
  fetchPurchaseHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseHistory);
