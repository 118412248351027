import * as Yup from 'yup';

const newPasswordForm = {
  newPassword: Yup.string()
  .required('Este campo é obrigatório')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?_&])[A-Za-z\d@$!%*#?_&]{8,}$/,
    "A senha deve ter no mínimo: oito caracteres, uma letra maiúscula, uma letra minúscula, números e um caractere especial"
  ).min(8, 'A senha deve conter no mín. 8 caracteres'),
  passwordConfirmation:  Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'As senhas devem ser iguais')
};

export const newPasswordFormValidator = Yup.object().shape(newPasswordForm);
