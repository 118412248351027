import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
  title: {
    color: theme.colors.text.dark,
    margin: '0 0 30px 0',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'center',
    margin: '0',
    width: '100%',
  },
  course: {
    boxSizing: 'border-box',
    padding: '1.5rem 0',
    width: '100%',
  },

  tabs:{
    alignSelf: 'flex-start',
    marginBottom: '3rem',

  },
  select:{
    width: '100%',
    marginBottom: '3rem',

  },
  expansionPanel:{
    boxShadow: 'none',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: theme.colors.divider,
    borderBottomColor: theme.colors.divider,
    boxSizing: 'border-box',
    marginTop: '-1px',
    width: '100%',
  },
  expansionPanelSummary:{
    padding: '0',
  },
  expansionPanelSummaryContent: isMobile => ({
    flexDirection: isMobile ? 'column' : 'row',
    padding: isMobile ? '0rem 1rem' : '0rem',
  }),
  expandIcon:{
    margin: '0',
  },
  header:{
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%',
    padding: '1rem 0rem',
    color: theme.colors.text.dark
  },
  identifier:isMobile => ({
    marginBottom: isMobile ? '1rem' : 'unset',
    display: 'block',
    flex: 3,
    '& span':{
      color: theme.colors.text.dark,
    }
  }),
  date:isMobile => ({
    marginBottom: isMobile ? '1rem' : 'unset',
    display: 'block',
    flex: 3,
    '& span':{
      color: theme.colors.text.dark,
    }
  }),
  value:isMobile => ({
    marginBottom: isMobile ? '1rem' : 'unset',
    display: 'block',
    flex: 2,
    '& span':{
      color: theme.colors.text.dark,
    }
  }),
  payment:isMobile => ({
    marginBottom: isMobile ? '1rem' : 'unset',
    display: 'block',
    flex: 4,
    '& span':{
      color: theme.colors.text.dark,
    }
  }),
  nfe:isMobile => ({
    marginBottom: isMobile ? '1rem' : 'unset',
    display: 'block',
    minWidth: '8.813rem',

  }),
  nfeHeader:{
    display: 'block',
    minWidth: '8rem',
    paddingRight: '5.142rem',
  },
  expansionPanelDetails:{
    flexDirection: 'column',
  },
  detailSection:{
    marginBottom: '2rem',
  },
  detailsTitle:{
    color: theme.colors.text.dark,
  
  },
  detailItem:{
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0',
    color: theme.colors.text.dark,

    '& p':{
      '&:not(:last-child)':{
        marginRight: '2rem',

      }
    }
  },
  detailTotal:{
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem',
    backgroundColor: theme.colors.background.bg01,
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderTopColor: theme.colors.dividerBlue,
    borderBottomColor: theme.colors.dividerBlue,
    color: theme.colors.text.dark,


  },
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

}));

export default createStyles;
