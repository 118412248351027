import { getUserRegistration, registerInterest, confirmationRequest } from 'api/Registration';
import { editUserRegistration } from 'api/Session';
import { changePassword } from 'api/Registration';
import { changeEmail } from 'api/Registration';
import { getCitiesByState } from 'api/City';
import { registerNewsletter as _registerNewsletter } from 'api/Registration';

// Actions
const FETCH_USER_REGISTRATION_START = 'sesi/registration/FETCH_USER_REGISTRATION_START';
const FETCH_USER_REGISTRATION_SUCCESS = 'sesi/registration/FETCH_USER_REGISTRATION_SUCCESS';
const FETCH_USER_REGISTRATION_FAILURE = 'sesi/registration/FETCH_USER_REGISTRATION_FAILURE';
const USER_REGISTRATION_RESET = 'sesi/registration/USER_REGISTRATION_RESET';

const EDIT_USER_REGISTRATION_START = 'sesi/registration/EDIT_USER_REGISTRATION_START';
const EDIT_USER_REGISTRATION_SUCCESS = 'sesi/registration/EDIT_USER_REGISTRATION_SUCCESS';
const EDIT_USER_REGISTRATION_FAILURE = 'sesi/registration/EDIT_USER_REGISTRATION_FAILURE';
const EDIT_USER_REGISTRATION_RESET = 'sesi/registration/EDIT_USER_REGISTRATION_RESET';

const CHANGE_USER_PASSWORD_START = 'sesi/registration/CHANGE_USER_PASSWORD_START';
const CHANGE_USER_PASSWORD_SUCCESS = 'sesi/registration/CHANGE_USER_PASSWORD_SUCCESS';
const CHANGE_USER_PASSWORD_FAILURE = 'sesi/registration/CHANGE_USER_PASSWORD_FAILURE';
const CHANGE_USER_PASSWORD_RESET = 'sesi/registration/CHANGE_USER_PASSWORD_RESET';

const CHANGE_USER_EMAIL_START = 'sesi/registration/CHANGE_USER_EMAIL_START';
const CHANGE_USER_EMAIL_SUCCESS = 'sesi/registration/CHANGE_USER_EMAIL_SUCCESS';
const CHANGE_USER_EMAIL_FAILURE = 'sesi/registration/CHANGE_USER_EMAIL_FAILURE';
const CHANGE_USER_EMAIL_RESET = 'sesi/registration/CHANGE_USER_EMAIL_RESET';

const REGISTER_NEWSLETTER_START = 'sesi/registration/REGISTER_NEWSLETTER_START';
const REGISTER_NEWSLETTER_SUCCESS = 'sesi/registration/REGISTER_NEWSLETTER_SUCCESS';
const REGISTER_NEWSLETTER_FAILURE = 'sesi/registration/REGISTER_NEWSLETTER_FAILURE';
const REGISTER_NEWSLETTER_RESET = 'sesi/registration/REGISTER_NEWSLETTER_RESET';

const REGISTER_INTERST_START = 'sesi/registration/REGISTER_INTERST_START';
const REGISTER_INTERST_SUCCESS = 'sesi/registration/REGISTER_INTERST_SUCCESS';
const REGISTER_INTERST_FAILURE = 'sesi/registration/REGISTER_INTERST_FAILURE';
const REGISTER_INTERST_RESET = 'sesi/registration/REGISTER_INTERST_RESET';

const GET_CITY_BY_STATE_START = 'sesi/registration/GET_CITY_BY_STATE_START';
const GET_CITY_BY_STATE_SUCCESS = 'sesi/registration/GET_CITY_BY_STATE_SUCCESS';
const GET_CITY_BY_STATE_FAILURE = 'sesi/registration/GET_CITY_BY_STATE_FAILURE';

const CONFIRMATION_REGISTER_START = 'sesi/registration/CONFIRMATION_REGISTER_START';
const CONFIRMATION_REGISTER_SUCCESS = 'sesi/registration/CONFIRMATION_REGISTER_SUCCESS';
const CONFIRMATION_REGISTER_FAILURE = 'sesi/registration/CONFIRMATION_REGISTER_FAILURE';
const CONFIRMATION_REGISTER_RESET = 'sesi/registration/CONFIRMATION_REGISTER_RESET';

// Reducer
const initialState = {
  userRegistration: [],
  isLoading: false,
  isEditing: false,
  isConfirmation: false,
  status: '',
  success: false,
  citys:[],
  newsletterIsLoading: false,
  newsletterSuccess: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_USER_REGISTRATION_START:
      return { ...state, isLoading: true, success: false };
    case FETCH_USER_REGISTRATION_SUCCESS:
      return { ...state, ...action.payload, isLoading: false, success: false };
    case FETCH_USER_REGISTRATION_FAILURE:
      return { ...state, ...action.payload };
    case USER_REGISTRATION_RESET:
      return { ...state, ...initialState };
    case EDIT_USER_REGISTRATION_START:
      return { ...state, isEditing: true, success: false };
    case EDIT_USER_REGISTRATION_SUCCESS:
      return { ...state, ...action.payload, isEditing: false, status: action.payload, success: true };
    case EDIT_USER_REGISTRATION_FAILURE:
      return { ...state, ...action.payload, success: false };
    case EDIT_USER_REGISTRATION_RESET:
      return { ...state, ...initialState };
    case CHANGE_USER_EMAIL_START:
      return { ...state, isEditing: true };
    case CHANGE_USER_EMAIL_SUCCESS:
      return { ...state, ...action.payload, isEditing: false };
    case CHANGE_USER_EMAIL_FAILURE:
      return { ...state, ...action.payload };
    case CHANGE_USER_EMAIL_RESET:
      return { ...state, ...initialState };
    case CHANGE_USER_PASSWORD_START:
      return { ...state, isEditing: true };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return { ...state, ...action.payload, isEditing: false };
    case CHANGE_USER_PASSWORD_FAILURE:
      return { ...state, ...action.payload };
    case CHANGE_USER_PASSWORD_RESET:
      return { ...state, ...initialState };
    case REGISTER_INTERST_START:
      return { ...state, isRegisting: true, isLoading: true, success: false };
    case REGISTER_INTERST_SUCCESS:
      return { ...state, isRegisting: false, isLoading: false, success: true };
    case REGISTER_INTERST_FAILURE:
      return { ...state, ...action.payload };
    case REGISTER_INTERST_RESET:
      return { ...state, ...initialState };
    case CONFIRMATION_REGISTER_START:
      return { ...state, isConfirmation: false, isLoading: true, isConfirmation: false };
    case CONFIRMATION_REGISTER_SUCCESS:
      return { ...state, isConfirmation: true, isLoading: true, isConfirmation: true };
    case CONFIRMATION_REGISTER_FAILURE:
      return { ...state, isConfirmation: false, isLoading: false, isConfirmation: false  };
    case CONFIRMATION_REGISTER_RESET:
      return { ...state, isConfirmation: false, isLoading: false, isConfirmation: true  };
    case GET_CITY_BY_STATE_START:
      return { ...state, isLoading: true};
    case GET_CITY_BY_STATE_SUCCESS:
      return { ...state, citys: action.payload, isLoading: true };
    case CONFIRMATION_REGISTER_FAILURE:
      return { ...state, isLoading: false };
    case REGISTER_NEWSLETTER_START:
        return { ...state, newsletterIsLoading: true, newsletterSuccess: false };
    case REGISTER_NEWSLETTER_SUCCESS:
      return { ...state, newsletterIsLoading: false, newsletterSuccess: true };
    case REGISTER_NEWSLETTER_FAILURE:
      return { ...state, newsletterIsLoading: false, newsletterSuccess: false };
    default:
      return state;
  }
}

// Action Creators
export function fetchUserRegistrationStart() {
  return { type: FETCH_USER_REGISTRATION_START };
}

export function fetchUserRegistrationSuccess(data) {
  return { type: FETCH_USER_REGISTRATION_SUCCESS, payload: data };
}

export function fetchUserRegistrationFailure(data) {
  return { type: FETCH_USER_REGISTRATION_FAILURE, payload: data };
}

export function reset() {
  return { type: USER_REGISTRATION_RESET };
}


export function editUserRegistrationStart() {
  return { type: EDIT_USER_REGISTRATION_START };
}

export function editUserRegistrationSuccess(data) {
  return { type: EDIT_USER_REGISTRATION_SUCCESS, payload: data };
}

export function editUserRegistrationFailure(data) {
  return { type: EDIT_USER_REGISTRATION_FAILURE, payload: data };
}

export function editUserRegistrationReset() {
  return { type: EDIT_USER_REGISTRATION_RESET };
}

export function changeEmailStart() {
  return { type: CHANGE_USER_EMAIL_START };
}

export function changeEmailSuccess(data) {
  return { type: CHANGE_USER_EMAIL_SUCCESS, payload: data };
}

export function changeEmailFailure(data) {
  return { type: CHANGE_USER_EMAIL_FAILURE, payload: data };
}

export function changeEmailReset() {
  return { type: CHANGE_USER_EMAIL_RESET };
}

export function changePasswordStart() {
  return { type: CHANGE_USER_PASSWORD_START };
}

export function changePasswordSuccess(data) {
  return { type: CHANGE_USER_PASSWORD_SUCCESS, payload: data };
}

export function changePasswordFailure(data) {
  return { type: CHANGE_USER_PASSWORD_FAILURE, payload: data };
}

export function registerNewsletterStart() {
  return { type: REGISTER_NEWSLETTER_START };
}

export function registerNewsletterSuccess(data) {
  return { type: REGISTER_NEWSLETTER_SUCCESS, payload: data };
}

export function registerNewsletterFailure(data) {
  return { type: REGISTER_NEWSLETTER_FAILURE, payload: data };
}

export function changePasswordReset() {
  return { type: CHANGE_USER_PASSWORD_RESET };
}

export function registerInterestStart() {
  return { type: REGISTER_INTERST_START };
}

export function registerInterestSuccess(data) {
  return { type: REGISTER_INTERST_SUCCESS, payload: data };
}

export function registerInterestFailure(data) {
  return { type: REGISTER_INTERST_FAILURE, payload: data };
}

export function resetInterest() {
  return { type: REGISTER_INTERST_RESET };
}

export function confirmationRegisterStart() {
  return { type: CONFIRMATION_REGISTER_START };
}

export function confirmationRegisterSuccess(data) {
  return { type: CONFIRMATION_REGISTER_SUCCESS, payload: data };
}

export function confirmationRegisterFailure(data) {
  return { type: CONFIRMATION_REGISTER_FAILURE, payload: data };
}

export function resetConfirmationRegistert() {
  return { type: CONFIRMATION_REGISTER_RESET };
}

export function getCitiesByStateStart() {
  return { type: GET_CITY_BY_STATE_START };
}

export function getCitiesByStateSuccess(data) {
  return { type: GET_CITY_BY_STATE_SUCCESS, payload: data };
}

export function getCitiesByStateFailure(data) {
  return { type: GET_CITY_BY_STATE_FAILURE, payload: data };
}

// side effects, only as applicable
export const fetchUserRegistration = () => {
  return async dispatch => {
    dispatch(fetchUserRegistrationStart());

    try {
      const response = await getUserRegistration();
      dispatch(fetchUserRegistrationSuccess(response));
    } catch (error) {
      dispatch(fetchUserRegistrationFailure(error));
    }
  };
};

export const handleEditUserRegistration = (values) => {
  return async dispatch => {
    dispatch(editUserRegistrationStart());

    try {
      const response = await editUserRegistration(values);
      dispatch(editUserRegistrationSuccess(response));
    } catch (error) {
      dispatch(editUserRegistrationFailure(error));
    }
  };
};

export const handleEmailChange = ({ email, confirmationPassword }) => {
  return async dispatch => {
    dispatch(changeEmailStart());

    try {
      const response = await changeEmail({ email, confirmationPassword });
      dispatch(changeEmailSuccess(response));
    } catch (error) {
      dispatch(changeEmailFailure(error));
    }
  };
};


export const handlePasswordChange = ({ newPassword, passwordConfirmation, oldPassword }) => {
  return async dispatch => {
    dispatch(changePasswordStart());

    try {
      const response = await changePassword({ newPassword, passwordConfirmation, oldPassword });
      dispatch(changePasswordSuccess(response));
    } catch (error) {
      dispatch(changePasswordFailure(error));
    }
  };
};

export const registerNewsletter = email => {
  return async dispatch => {
    dispatch(registerNewsletterStart());

    try {
      const response = await _registerNewsletter(email);
      dispatch(registerNewsletterSuccess(response));
    } catch (error) {
      dispatch(registerNewsletterFailure(error));
    }
  };
};

export const registerinterestIn = (interest) => {
  return async dispatch => {
    dispatch(registerInterestStart());
    try {
      const response = await registerInterest(interest);
      dispatch(registerInterestSuccess(response));
      return response
    } catch (error) {
      dispatch(registerInterestFailure(error));
    }
  };
};

export const confirmationRegister = (body) => {
  return async dispatch => {
    dispatch(confirmationRegisterStart());
    try {
      const response = await confirmationRequest(body);
      dispatch(confirmationRegisterSuccess(response));
    } catch (error) {
      dispatch(confirmationRegisterFailure(error));
    }
  };
};

export const citiesByState = (body) => {
  return async dispatch => {
    dispatch(getCitiesByStateStart());
    try {
      const response = await getCitiesByState(body);
      dispatch(getCitiesByStateSuccess(response));
    } catch (error) {
      dispatch(getCitiesByStateFailure(error));
    }
  };
};