import EditAddress from './EditAddress';
import { connect } from 'react-redux';
// import { sale } from 'modules/Cart/Cart.ducks.js';

const mapStateToProps = (state) => {
  return {
    isLoading: state.cart.isLoading,
    user: state.session.user
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
