import React, { useState } from 'react';
import { Formik } from 'formik';
import ChangeEmailForm from './ChangeEmailForm';
import { ChangeEmailFormValidator } from './ChangeEmailForm.validator';
import InvalidCredentialsError from 'shared/error/Exceptions/InvalidCredentialsException';


const ChangePasswordFormWrapper = props => {

  const initialValues = {
    email: '', 
    confirmationPassword: ''
  };

  const {
    handleEmailChange, 
    isLoading, 
    handleClose, 
    errors, 
    afterEmailChange, 
    handleOpenModal
  } = props;

  const handleSubmitForm = async (formValues, actions) => {
    try {
      await handleEmailChange(formValues);
      handleClose();
      if (afterEmailChange) afterEmailChange();
    } catch (err) {
      if (err instanceof InvalidCredentialsError) {
        this.setState({
          externalErrors: { email: 'Email ou senha inválido' }
        });
      }
    }
  };


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangeEmailFormValidator}
      onSubmit={handleSubmitForm}
    >
      {propsFormik => (
        <ChangeEmailForm
          {...propsFormik}
          isLoading={isLoading}
          handleOpenModal={handleOpenModal}
        />
      )}
    </Formik>
  );
};

export default ChangePasswordFormWrapper;
