import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import RegisterInterestForm from './RegisterInterestForm';
import { registerFormValidator } from './RegisterInterestForm.validator';
import { getAuthToken } from '../../../../shared/util/LocalStorage';

const LoginBoxFormWrapper = (props) => {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    subscribeOffers: false
  };

  const {
    register,
    history,
    redirectTo,
    success,
    course,
    isLoading,
    errors,
    setinterestCompleted,
    afterRegister,
    closeInterest,
    registerinterestIn
  } = props;

  const handleSubmit = async (formValues, actions) => {
    try {
      var object = {
        customerToken : getAuthToken(),
        productId : course.id,
        fullname : formValues.name,
        email: formValues.email,
        telephone : formValues.phone,
        newsletter: formValues.subscribeOffers
      }
      const response = await registerinterestIn(object);
      if (response.status === 200) {
        closeInterest();
        setinterestCompleted(true);
        // actions.resetForm();
      }
      // if (afterRegister) {
      //   afterRegister();
      // }
    } catch (err) {}
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={registerFormValidator}
    >
      {(propsFormik) => (
        <RegisterInterestForm
          isLoading={isLoading}
          success={success}
          errors={errors}
          setinterestCompleted={setinterestCompleted}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default LoginBoxFormWrapper;
